import { useEffect, useState, useContext } from 'react'
import { Grid } from '@mui/material'
import {
  AggregateGraphs,
  ToolFilterAccordion,
  ViewDataLoading,
  ToolFilterAggregate,
} from '../../components'
import { UserContext } from '../../context/user'
import {
  HistoryParams,
  IErrorDash,
  IMultipleOrganization,
  IOrganization,
  FilterValues,
} from '../../domain'
import { FilterService, getHistoryReport } from '../../services'

export const Aggregate = () => {
  const [height, setHeight] = useState(190)
  const [hasChanged, setHasChanged] = useState(false)
  const { user, loading, updateLoading } = useContext(UserContext)
  const [filter, setFilter] = useState<FilterValues>({
    parentTransmitters: [],
    transmitters: [],
    interval: [],
    orgs: [],
    type: '',
  })

  const [historyData, setHistoryData] = useState<HistoryParams | undefined>()
  const [dashError, setDashError] = useState<IErrorDash>({
    historyData: false,
  })

  const [parentTransmitters, setParentTransmitters] = useState<IOrganization[]>(
    [],
  )
  const [transmitters, setTransmitters] = useState<IOrganization[]>([])

  useEffect(() => {
    if (filter.parentTransmitters && filter.parentTransmitters.length === 0) {
      setFilter((prevState) => ({ ...prevState, transmitters: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.parentTransmitters])

  useEffect(() => {
    getHistoryReport()
      .then((data) => {
        if (!data) throw Error('Erro na obtenção dos dados')
        if (Object.keys(data).length === 0)
          throw Error('Erro na obtenção dos dados')
        setHistoryData(data)
      })
      .catch(() => {
        setHistoryData(undefined)
        setDashError((prevState: IErrorDash) => ({
          ...prevState,
          historyData: true,
        }))
      })

    setHasChanged(!hasChanged)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    FilterService.getDashboardParentTransmitters()
      .then((data: IMultipleOrganization[]) => {
        const newParentTransmitters: IOrganization[] = data.map(
          (parentTransmitter: IMultipleOrganization) => ({
            value: parentTransmitter.value,
            id: parentTransmitter.ids.join(','),
          }),
        )
        setParentTransmitters(newParentTransmitters)
      })
      .finally(() =>
        updateLoading({ name: 'parentTransmitters', loading: false }),
      )

    FilterService.getDashboardTransmitters()
      .then((data: IOrganization[]) => setTransmitters(data))
      .finally(() => updateLoading({ name: 'transmitters', loading: false }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <Grid container>
      {loading.user === true && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              height: { md: '100vh' },
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <ViewDataLoading />
          </Grid>
        </>
      )}

      {loading.user === false && (
        <>
          <Grid item xs={12}>
            <ToolFilterAccordion
              hasChanged={hasChanged}
              onChangeHeight={setHeight}
            >
              <ToolFilterAggregate
                parentTransmitters={parentTransmitters}
                transmitters={transmitters}
                submitValues={setFilter}
              />
            </ToolFilterAccordion>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: { md: `${height}px` },
              paddingBottom: 3,
              transition: 'margin 251ms cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          >
            <AggregateGraphs
              history={historyData}
              error={dashError}
              filter={filter}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
