import { Grid } from '@mui/material'
import { IErrorDash, FilterValues } from '../../../domain'
import {
  AggregateAverageAvailabilityGraphGraph,
  AggregateAverageResponseTimeGraph,
  AggregateSuccessfullCallsGraphGraph,
  AggregateSuccessfulCallsGraph,
  AggregateApiAverageAvailabilityGraph,
  AggregateAverageResponseTimesGraph,
  AggregateChangesGraph,
  AggregateKeyPerformanceMetricsGraph,
  AggregateRejectedCallsGraph,
} from '../../../components'

interface AggregatePublicGraphsProps {
  history?: any
  error: IErrorDash
  filter: FilterValues
}

export const AggregatePublicGraphs = ({
  filter,
  history,
  error,
}: AggregatePublicGraphsProps) => {
  return (
    <Grid container spacing={2} sx={{ height: '102.096%' }}>
      <Grid item xs={12} md={12} lg={12}>
        <AggregateKeyPerformanceMetricsGraph filter={filter} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AggregateSuccessfulCallsGraph filter={filter} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AggregateRejectedCallsGraph filter={filter} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AggregateAverageResponseTimeGraph filter={filter} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AggregateApiAverageAvailabilityGraph filter={filter} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AggregateSuccessfullCallsGraphGraph
          dataGraph={history?.successfulCalls}
          isError={error.historyData}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AggregateAverageResponseTimesGraph
          dataGraph={history?.averageResponseTimes}
          isError={error.historyData}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AggregateAverageAvailabilityGraphGraph
          dataGraph={history?.averageAvailability}
          isError={error.historyData}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <AggregateChangesGraph />
      </Grid>
    </Grid>
  )
}
