import {
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { SuccessfulCallsPageOne, SuccessfulCallsPageTwo } from './graphs'
import {
  graphListHelper,
  makeOptions,
  orgsOrderDefault,
  percentageDataToOrderBy,
  percentageOrderDefault,
} from './helpers'
import { GraphContainer } from './styles'
import { SuccessfulCallsProps } from './types'
import {
  CardBox,
  CardBoxSubTitle,
  CardBoxTitle,
  IconCsvDownloader,
  SingleAutocomplete,
} from '../../../components'
import { RangeOptions, SelectDefaultValue } from '../../../domain'
import { getMirrorReport, SubmitParams } from '../../../services'

interface pageOneDataProps extends RangeOptions {}

interface pageTwoDataProps extends RangeOptions {}

export const AggregateSuccessfulCallsGraph = ({
  filter,
}: SuccessfulCallsProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isError, setIsError] = useState<Boolean>(false)
  const [currentKey, setCurrentKey] = useState(0)
  const [totalSumInvocations, setTotalSumInvocations] = useState(0)
  const [pageOneData, setPageOneData] = useState<pageOneDataProps[]>(
    percentageDataToOrderBy,
  )
  const [pageTwoData, setPageTwoData] = useState<pageTwoDataProps[]>()
  const [firstChartOrder, setFirstChartOrder] = useState<SelectDefaultValue>(
    percentageOrderDefault(),
  )
  const [secondChartOrder, setSecondChartOrder] = useState<SelectDefaultValue>(
    orgsOrderDefault(),
  )
  const [loading, setLoading] = useState(false)
  const [selectOptions, setSelectOptions] = useState<SelectDefaultValue[]>([])

  const handleTabs = (e: ChangeEvent<{}>, newKey: number): void => {
    setCurrentKey(newKey)
  }

  const makeChartData: { [index: number]: () => void } = {
    0: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'successfulCalls',
          },
          (data) => {
            if (data) {
              setPageOneData(
                percentageDataToOrderBy.map((item) => ({
                  ...item,
                  value: data[item.key],
                })),
              )
              setTotalSumInvocations(data.totalSumInvocations)

              if (
                data.totalSumInvocations === 0 ||
                data.totalSumInvocations === undefined
              ) {
                setIsError(true)
              } else {
                setIsError(false)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
    1: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'successfulCallsByServer',
          },
          (data) => {
            if (data) {
              setPageTwoData(data)

              if (data.filter((item: any) => item.invocations > 0).length > 0) {
                setIsError(false)
              } else {
                setIsError(true)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
  }

  const getChartData = useCallback(
    (dateData: SubmitParams, setChart: (data: any) => void): void => {
      setLoading(true)
      getMirrorReport({ ...dateData })
        .then(
          (data: any) => {
            setChart(data)
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        )
        .catch(() => {
          setLoading(false)
        })
    },
    [],
  )

  const getDataToExportCSV = (): any => {
    if (pageOneData && pageOneData.length > 0 && currentKey === 0) {
      return pageOneData.map(({ range, value }: any): any => ({
        intervalo: range,
        valor: value,
      }))
    }
    if (pageTwoData && pageTwoData.length > 0 && currentKey === 1) {
      return pageTwoData.map(({ serverName, invocations }: any): any => ({
        instituicao: serverName,
        valor: invocations,
      }))
    }
    return []
  }

  const headerCSV: any = {
    0: [
      {
        label: 'Intervalo',
        key: 'intervalo',
      },
      {
        label: 'Quantidade de chamadas',
        key: 'valor',
      },
    ],
    1: [
      {
        label: 'Sociedade',
        key: 'instituicao',
      },
      {
        label: 'Quantidade de chamadas',
        key: 'valor',
      },
    ],
  }

  const handleSelectChange = (value: SelectDefaultValue) => {
    if (currentKey === 0) {
      setFirstChartOrder(value)
    } else {
      setSecondChartOrder(value)
    }
  }

  useEffect(() => {
    makeChartData[currentKey]()
    setSelectOptions(makeOptions(currentKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKey, filter])

  return (
    <Grid item xs={12}>
      <CardBox>
        <CardBoxTitle
          title="Chamadas de APIs Bem-sucedidas"
          action={
            <>
              {!isError && !loading ? (
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="chamadas-api-bem-sucedidas"
                    headers={headerCSV[currentKey]}
                    data={getDataToExportCSV()}
                  />
                </IconButton>
              ) : (
                ''
              )}
            </>
          }
        />
        <CardBoxSubTitle title="Demonstração do número de chamadas bem-sucedidas das APIs de sociedades participantes" />

        <Grid
          container
          sx={{
            padding: 2,
            paddingTop: 0,
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <SingleAutocomplete
              id="orderBy"
              label="Classifique por"
              options={selectOptions}
              value={currentKey === 0 ? firstChartOrder : secondChartOrder}
              onSelect={handleSelectChange}
              loading={loading}
            />
          </Grid>
        </Grid>

        <CardContent sx={{ position: 'relative' }}>
          <GraphContainer>
            <Tabs
              orientation="vertical"
              variant="standard"
              value={currentKey}
              onChange={handleTabs}
              sx={{ width: 300, minWidth: 300 }}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              {graphListHelper.map((item) => (
                <Tab
                  key={`${item.title}-${item.key}`}
                  label={item.title}
                  wrapped
                  value={item.key}
                  sx={{
                    fontSize: 12,
                    '&.Mui-selected': {
                      fontWeight: 'bold',
                    },
                  }}
                />
              ))}
            </Tabs>
            {currentKey === 0 && (
              <SuccessfulCallsPageOne
                data={pageOneData}
                chartOrder={firstChartOrder}
                totalSumInvocations={totalSumInvocations}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 1 && (
              <SuccessfulCallsPageTwo
                data={pageTwoData}
                chartOrder={secondChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
          </GraphContainer>
        </CardContent>

        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {currentKey === 0 ? (
            <Typography fontSize={12}>
              Quantidade de Sociedades participantes
            </Typography>
          ) : (
            <Typography fontSize={12}>
              Apenas sociedades que reportaram Chamadas de APIs Bem-sucedidas no
              período selecionado são exibidas.
            </Typography>
          )}
        </CardActions>
      </CardBox>
    </Grid>
  )
}
