import { Box, List, ListProps, Typography, TypographyProps, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.typography.pxToRem(22),
  lineHeight: theme.typography.pxToRem(26),
  letterSpacing: 0,
  marginTop: 2,
  color: theme.palette.primary.main,
  textAlign: 'center',
}))

export const User = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(26),
  letterSpacing: 0,
  marginRight: 12,
  color: theme.palette.primary.main,
  textAlign: 'center',
}))

export const CustomList = styled(List)<ListProps>(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  zIndex: 10,
  height: '100%',
  [theme.breakpoints.up('md')]: {
    width: '236px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const BoxNav = styled(Box)<BoxProps>(({ theme }) => ({
  width: '236px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    minHeight: '64px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))
