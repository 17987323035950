import _ from 'lodash'
import { format, subDays } from 'date-fns'
import {
  Box,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { Legend } from './styles'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxSubTitle,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import {
  formatToLocaleString,
  monthFormatter,
  parseNumberToFixedTwo,
  parseNumberToLocale,
} from '../../../utils'
import { HistoryChildrenParams } from '../../../domain'

interface AggregateAverageResponseTimesGraphProps {
  dataGraph?: HistoryChildrenParams[]
  isLoading?: boolean
  isError?: boolean
}

const headerCSV: any[] = [
  { label: 'Mês', key: 'month_year' },
  { label: 'Tempo de Resposta', key: 'tempo_resposta' },
]

export const AggregateAverageResponseTimesGraph = ({
  dataGraph,
  isLoading,
  isError,
}: AggregateAverageResponseTimesGraphProps) => {
  const theme = useTheme()
  const [filteredData, setFilteredData] = useState<HistoryChildrenParams[]>([])
  const [hiddenLabel, setHiddenLaben] = useState<boolean>(false)

  const PercentLabel = ({ x, y, width, value }: LabelProps) => {
    const _x = Number(x) + Number(width) / 2
    const _y = Number(y) - 10
    const _value = value && formatToLocaleString(Number(value))
    return (
      <g>
        <text
          x={_x}
          y={_y}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={12}
        >
          {!hiddenLabel && _value}
        </text>
      </g>
    )
  }

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>Período: {payload[0].payload.month_year}</Typography>
        <Typography>
          Tempo de resposta: {formatToLocaleString(payload[0].value)}{' '}
          (milissegundos)
        </Typography>
      </TooltipGraph>
    )
  }

  const CustomYAxisTick = (number: number): string => {
    return parseNumberToLocale(parseNumberToFixedTwo(number))
  }

  const getDataToExportCSV = (): any => {
    return filteredData.map(({ month_year, value }: any): any => ({
      month_year,
      tempo_resposta: parseNumberToFixedTwo(value),
    }))
  }

  useEffect(() => {
    if (dataGraph) {
      const newData = [...dataGraph]
      setFilteredData(
        _.orderBy(
          newData.map((item) => ({
            ...item,
            month_year: monthFormatter(item.month_year),
            id: item.month_year.replace('-', ''),
          })),
          ['id'],
          ['asc'],
        ),
      )
    }
  }, [dataGraph])

  useEffect(() => {
    setHiddenLaben(window.innerWidth <= 899)

    window.addEventListener('resize', () =>
      setHiddenLaben(window.innerWidth <= 899),
    )

    return () =>
      window.removeEventListener('resize', () =>
        setHiddenLaben(window.innerWidth <= 899),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardBox>
      <CardBoxTitle
        title="Tempo Médio de Resposta de Chamada das APIs"
        action={
          <>
            {!isError && dataGraph ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="historico-tempo-medio-resposta"
                    headers={headerCSV}
                    data={getDataToExportCSV()}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />
      <CardBoxSubTitle title="Demonstração de tempo médio de resposta de chamada das APIs de sociedades participantes (em milissegundos)" />

      <CardContent>
        {!dataGraph && !isError ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataGraph || dataGraph.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={filteredData}
                margin={{
                  left: 5,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
                barGap={0}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  dataKey="month_year"
                  type="category"
                  tickLine={false}
                  interval="preserveStartEnd"
                  padding={{ left: 2, right: 2 }}
                />
                <YAxis
                  type="number"
                  tickLine={false}
                  tickMargin={12}
                  tickCount={4}
                  tickFormatter={CustomYAxisTick}
                  padding={{ top: 20 }}
                />
                <Bar
                  isAnimationActive={false}
                  dataKey="value"
                  fill={theme.palette.primary.light}
                >
                  <LabelList
                    dataKey="value"
                    position="right"
                    content={PercentLabel}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
      {dataGraph && (
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Legend>
            <Typography fontSize={12}>
              Última atualização em{' '}
              {format(subDays(new Date(), 1), 'dd/MM/yyyy')}
            </Typography>
          </Legend>
        </CardActions>
      )}
    </CardBox>
  )
}
