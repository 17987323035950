import _ from 'lodash'
import {
  Bar,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ChartContainer, Container, TotalContainer } from './styles'
import { useTheme } from '@mui/material'

import {
  TooltipGraph,
  ViewDataError,
  ViewDataLoading,
} from '../../../../../components'
import { SelectDefaultValue } from '../../../../../domain'
import { formatToLocaleString, parseNumberToLocale } from '../../../../../utils'

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipGraph>
        Qtd de sociedades: {formatToLocaleString(payload[0].value)}
      </TooltipGraph>
    )
  }
  return null
}

interface SuccessfulCallsPageOneProps {
  data: any
  chartOrder: SelectDefaultValue
  totalSumInvocations: number
  mobile: boolean
  loading: boolean
}
export const SuccessfulCallsPageOne = ({
  data,
  chartOrder,
  totalSumInvocations,
  mobile,
  loading,
}: SuccessfulCallsPageOneProps) => {
  const theme = useTheme()

  return (
    <>
      {loading && <ViewDataLoading />}

      {!loading &&
        (!data ||
          data.length === 0 ||
          parseNumberToLocale(totalSumInvocations) === '0' ||
          parseNumberToLocale(totalSumInvocations) === 'NaN') && (
          <ViewDataError />
        )}

      {parseNumberToLocale(totalSumInvocations) !== '0' &&
        parseNumberToLocale(totalSumInvocations) !== 'NaN' &&
        data &&
        data.length > 0 &&
        !loading && (
          <Container>
            <ChartContainer sx={{ overflow: 'hidden' }}>
              <ResponsiveContainer minWidth={200} minHeight={300}>
                <ComposedChart
                  layout="vertical"
                  data={_.orderBy(
                    data,
                    [chartOrder?.value.by],
                    [chartOrder?.value.type],
                  )}
                >
                  <XAxis
                    type="number"
                    hide={true}
                    domain={[0, (dataMax: number) => dataMax * 1.2]}
                  />
                  <YAxis
                    dataKey="range"
                    type="category"
                    width={155}
                    tickLine={false}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey="value"
                    barSize={40}
                    fill={theme.palette.primary.light}
                  >
                    <LabelList dataKey="value" position="right" fill="#000" />
                  </Bar>
                  {!mobile && <Tooltip content={<CustomTooltip />} />}
                </ComposedChart>
              </ResponsiveContainer>
            </ChartContainer>

            <TotalContainer>
              <h3>{`Total Chamadas ${parseNumberToLocale(
                totalSumInvocations,
              )}`}</h3>
              <p>CHAMADAS DA API BEM-SUCEDIDAS</p>
            </TotalContainer>
          </Container>
        )}
    </>
  )
}

export default SuccessfulCallsPageOne
