import _ from 'lodash'
import {
  Box,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import { HistoryChildrenParams } from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxSubTitle,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import {
  formatToLocaleString,
  monthFormatter,
  numberDecimalFormatToHistorySuccessGraph,
  numberDecimalFormatWithoutSymbol,
} from '../../../utils'

interface AggregateSuccessfullCallsGraphProps {
  dataGraph?: HistoryChildrenParams[]
  isLoading?: boolean
  isError?: boolean
}

const headerCSV: any[] = [
  { label: 'Período', key: 'periodo' },
  { label: 'Total de Chamadas', key: 'total_chamadas' },
]

export const AggregateSuccessfullCallsGraphGraph = ({
  dataGraph,
  isLoading,
  isError,
}: AggregateSuccessfullCallsGraphProps) => {
  const theme = useTheme()
  const [filteredData, setFilteredData] = useState<HistoryChildrenParams[]>([])
  const [hiddenLabel, setHiddenLaben] = useState<boolean>(false)

  const PercentLabel = ({ x, y, width, height, value }: LabelProps) => {
    const _x = Number(x) + Number(width) / 2
    const _y = Number(y) - 10
    const _value = value && numberDecimalFormatToHistorySuccessGraph(value, 1)
    return (
      <g>
        <text
          x={_x}
          y={_y}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={12}
        >
          {!hiddenLabel && _value}
        </text>
      </g>
    )
  }

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography textTransform="uppercase">
          {payload[0].payload.month_year}
        </Typography>
        <Typography>
          Total de chamadas: {formatToLocaleString(payload[0].value)}
        </Typography>
      </TooltipGraph>
    )
  }

  const CustomYAxisTick = (props: any): any => {
    const { x, y, payload, fill } = props

    return (
      <g>
        <text x={x} y={y} textAnchor="end" fill={fill}>
          {`${numberDecimalFormatWithoutSymbol(payload.value, 1)}M`}
        </text>
      </g>
    )
  }

  const getDataToExportCSV = (): any => {
    return filteredData.map(({ month_year: monthYear, value }: any): any => ({
      periodo: monthYear,
      total_chamadas: formatToLocaleString(value),
    }))
  }

  useEffect(() => {
    if (dataGraph) {
      const newData = [...dataGraph]
      setFilteredData(
        _.orderBy(
          newData.map((item) => ({
            ...item,
            month_year: monthFormatter(item.month_year),
            id: item.month_year.replace('-', ''),
          })),
          ['id'],
          ['asc'],
        ),
      )
    }
  }, [dataGraph])

  useEffect(() => {
    setHiddenLaben(window.innerWidth <= 899)

    window.addEventListener('resize', () =>
      setHiddenLaben(window.innerWidth <= 899),
    )

    return () =>
      window.removeEventListener('resize', () =>
        setHiddenLaben(window.innerWidth <= 899),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardBox>
      <CardBoxTitle
        title="Chamadas de APIs Bem-sucedidas"
        action={
          <>
            {!isError && dataGraph ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="historico-chamadas-api-bem-sucedidas"
                    headers={headerCSV}
                    data={getDataToExportCSV()}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />
      <CardBoxSubTitle title="Demonstração das chamadas de APIs bem-sucedidas de sociedades participantes" />

      <CardContent>
        {!dataGraph && !isError ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataGraph || dataGraph.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={filteredData}
                margin={{
                  left: 5,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
                barGap={0}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  dataKey="month_year"
                  type="category"
                  tickLine={false}
                  interval="preserveStartEnd"
                  padding={{ left: 2, right: 2 }}
                />
                <YAxis
                  type="number"
                  tickLine={false}
                  allowDecimals={true}
                  tickMargin={12}
                  tickCount={4}
                  tick={<CustomYAxisTick />}
                  padding={{ top: 20 }}
                />
                <Bar
                  isAnimationActive={false}
                  dataKey="value"
                  fill={theme.palette.primary.light}
                >
                  <LabelList
                    dataKey="value"
                    position="right"
                    content={PercentLabel}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
