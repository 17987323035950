import { Box } from '@mui/material'
import { ViewGraphError } from '../../../components'

export const ViewDataEmpty = () => {
  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: { xs: 380, md: 280 },
      }}
    >
      <ViewGraphError
        message="Nenhum dado correspondeu à sua pesquisa, tente usar outras opções de
              filtro."
      />
    </Box>
  )
}
