import { FormControl, FormControlProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledFormControl = styled(FormControl)<FormControlProps>({
  marginBlock: 8,
  marginInline: 0,
  width: '100%',
  border: 'none',
  background: '#ffffff',
  borderRadius: 8,
})
