import { useState, useContext } from 'react'
import { Grid } from '@mui/material'
import { IFilter } from '../../domain'
import {
  ViewDataLoading,
  InfrastractureGraphs,
  ToolFilterDynamic,
  ToolFilterAccordion,
} from '../../components'
import { UserContext } from '../../context/user'

export const Infrastructure = () => {
  const { loading } = useContext(UserContext)
  const [filters, setFilter] = useState<IFilter>({} as IFilter)
  const [height, setHeight] = useState(190)

  const handleFilters = (filters: IFilter) => {
    setFilter(filters)
  }

  return (
    <Grid container>
      {loading.user === true && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              height: { xs: 'calc(100vh - 191px)', md: '100vh' },
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <ViewDataLoading />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <ToolFilterAccordion onChangeHeight={setHeight}>
          <ToolFilterDynamic onChange={handleFilters} />
        </ToolFilterAccordion>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          marginTop: { md: `${height}px` },
          paddingBottom: 3,
          transition: 'margin 251ms cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      >
        <InfrastractureGraphs activatedFilters={filters} />
      </Grid>
    </Grid>
  )
}
