import { Typography, TypographyProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const BoxTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: theme.typography.pxToRem(27),
  lineHeight: theme.typography.pxToRem(31),
  letterSpacing: '0px',
  fontWeight: 'bold',
  padding: 16,
}))

export const BoxSubtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: theme.typography.pxToRem(19),
  lineHeight: theme.typography.pxToRem(22),
  letterSpacing: '0px',
  textAlign: 'center',
  maxWidth: 400,
}))
