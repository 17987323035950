import { Box } from '@mui/material'
import { DotsLoading } from '../../../components'

export const ViewDataLoading = () => {
  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: { xs: 380, md: 280 },
      }}
    >
      <DotsLoading />
    </Box>
  )
}
