import { Box, CardContent, IconButton, Typography } from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import {
  IAvailabilityParticipantsGraph,
  IAvailabilityParticipantsGraphCSV,
  IDataAvailabilityParticipantsGraph,
} from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import { formatToLocaleString } from '../../../utils'

interface DynamicAvailabilityParticipantsGraphProps {
  dataGraph: IAvailabilityParticipantsGraph
  isLoading?: boolean
  isError?: boolean
}

const headerCSV = [
  { label: 'Disponibilidade', key: 'label' },
  { label: 'Sociedades Participantes', key: 'total' },
]

export const DynamicAvailabilityParticipantsGraph = ({
  dataGraph,
  isLoading,
  isError,
}: DynamicAvailabilityParticipantsGraphProps) => {
  const [dataShow, setDataShow] = useState<
    IDataAvailabilityParticipantsGraph[]
  >([])
  const [dataCSV, setDataCSV] = useState<IAvailabilityParticipantsGraphCSV[]>(
    [],
  )

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        {payload[0].value === 0 && (
          <Typography>
            Nenhuma sociedade participante com disponibilidade{' '}
            {payload[0].payload.name}
          </Typography>
        )}
        {payload[0].value === 1 && (
          <Typography>
            {payload[0].value} sociedade participante com disponibilidade{' '}
            {payload[0].payload.name}
          </Typography>
        )}
        {payload[0].value > 1 && (
          <Typography>
            {payload[0].value} sociedades participantes com disponibilidade{' '}
            {payload[0].payload.name}
          </Typography>
        )}
      </TooltipGraph>
    )
  }

  const handleDataShow = (data: IAvailabilityParticipantsGraph) => {
    if (!data || !data.data) {
      setDataShow([])
      return
    }

    const tmp: IDataAvailabilityParticipantsGraph[] = []
    let hasData: boolean = false

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, value] of Object.entries(data.data)) {
      if (value.value > 0) {
        hasData = true
      }

      tmp.push(value)
    }

    if (hasData) {
      setDataShow(tmp)
    } else {
      setDataShow([])
    }
  }

  const handleDataDownload = (data: IAvailabilityParticipantsGraph) => {
    if (!data || !data.data) {
      setDataCSV([])
      return
    }

    const tmp: IAvailabilityParticipantsGraphCSV[] = []

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, value] of Object.entries(data.data)) {
      tmp.push({
        label: value.name.replace(',', '.'),
        total: value.value,
      })
    }

    setDataCSV(tmp)
  }

  useEffect(() => {
    handleDataShow(dataGraph)
    handleDataDownload(dataGraph)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Sociedades participantes por percentil de disponibilidade"
        action={
          <>
            {!isLoading &&
            !isError &&
            dataShow.length > 0 &&
            dataCSV.length > 0 ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="sociedades-participantes-disponibilidade"
                    headers={headerCSV}
                    data={dataCSV}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow || dataShow.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={dataShow}
                margin={{
                  left: -31,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
                layout="vertical"
                barGap={0}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  type="number"
                  padding={{ left: 0, right: 50 }}
                  scale="sqrt"
                  tickFormatter={(value: number) =>
                    formatToLocaleString(value, 0)
                  }
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  tickLine={false}
                  width={145}
                />
                <Bar dataKey="value" isAnimationActive={false}>
                  {dataShow.map((entry, index) => {
                    return <Cell key={`cell-${index}`} fill={entry.color} />
                  })}
                  <LabelList
                    dataKey="value"
                    fill="#000000de"
                    position="right"
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
