import { Box, CardContent, IconButton, Typography } from '@mui/material'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import { ICallsServiceDeskGraph } from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import { formatDate, formatToLocaleString } from '../../../utils'

interface InfrastructureCallsGraphProps {
  dataGraph: ICallsServiceDeskGraph
  isLoading?: boolean
  isError?: boolean
}

interface IInfrastructureCallsGraphShow {
  axisX: string
  access: number
  incident: number
  improvement: number
  information: number
  canceled: number
  total: number
}

const headerCSV = [
  { label: 'Período', key: 'axisX' },
  { label: 'Solicitação de acesso', key: 'access' },
  { label: 'Incidentes', key: 'incident' },
  { label: 'Solicitação de Melhorias', key: 'improvement' },
  { label: 'Solicitação de Informações', key: 'information' },
  { label: 'Solicitação de Certificado', key: 'certification' },
  { label: 'Cancelado', key: 'canceled' },
  { label: 'Total', key: 'total' },
]

const series = [
  {
    key: 'access',
    problem_type: 'Solicitação de acesso',
    name: 'Acesso',
    type: 'bar',
    color: '#2a732a',
  },
  {
    key: 'incident',
    problem_type: 'Incidentes',
    name: 'Incidente',
    type: 'bar',
    color: '#808080',
  },
  {
    key: 'improvement',
    problem_type: 'Solicitação de Melhorias',
    name: 'Melhoria',
    type: 'bar',
    color: '#0ca789',
  },
  {
    key: 'information',
    problem_type: 'Solicitação de Informações',
    name: 'Informação',
    type: 'bar',
    color: '#2d9bf0',
  },
  {
    key: 'certification',
    problem_type: 'Solicitação de certificados',
    name: 'Certificado',
    color: '#8e44ad',
  },
  {
    key: 'canceled',
    problem_type: 'Cancelado',
    name: 'Cancelado',
    type: 'bar',
    color: '#ea2027',
  },
  {
    key: 'total',
    problem_type: '',
    name: 'Total',
    type: 'line',
    color: '#808080',
  },
]

export const legendStatus: any = {
  access: 'chamados abertos para solicitação de acessos.',
  incident:
    'chamados abertos para comunicação de falhas ou degradação de qualidade em algum serviço.',
  improvement: 'chamados abertos para indicação de melhorias.',
  information: 'chamados abertos para solicitação de informações.',
  canceled: 'Chamado cancelado pelo Service Desk.',
  certification: 'chamados de solicitação de certificados ',
}

export const InfrastructureCallsGraph = ({
  dataGraph,
  isLoading,
  isError,
}: InfrastructureCallsGraphProps) => {
  const [dataShow, setDataShow] = useState<IInfrastructureCallsGraphShow[]>([])
  const [activeLegend, setActiveLegend] = useState<string>('')
  const [dataCSV, setDataCSV] = useState<IInfrastructureCallsGraphShow[]>([])

  const handleActiveLegend = ({ dataKey }: any) => {
    setActiveLegend(dataKey)
  }

  const handleDisableLegend = () => {
    setActiveLegend('')
  }

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography textTransform="uppercase" marginBottom={2}>
          {formatDate(payload[0].payload.axisX, 'MMM/yyyy', true)}
        </Typography>
        <Typography>
          Acesso:{' '}
          {payload[0].payload.access >= 1 ? payload[0].payload.access : 0}
        </Typography>
        <Typography>
          Incidente:{' '}
          {payload[0].payload.incident >= 1 ? payload[0].payload.incident : 0}
        </Typography>
        <Typography>
          Melhoria:{' '}
          {payload[0].payload.improvement >= 1
            ? payload[0].payload.improvement
            : 0}
        </Typography>
        <Typography>
          Informação:{' '}
          {payload[0].payload.information >= 1
            ? payload[0].payload.information
            : 0}
        </Typography>
        <Typography>
          Certificado:{' '}
          {payload[0].payload.certification >= 1
            ? payload[0].payload.certification
            : 0}
        </Typography>
        <Typography marginBottom={2}>
          Cancelado:{' '}
          {payload[0].payload.canceled >= 1 ? payload[0].payload.canceled : 0}
        </Typography>
        <Typography>Total: {payload[0].payload.total}</Typography>
      </TooltipGraph>
    )
  }

  const CustomYAxisTick = (props: any): any => {
    const { x, y, payload, fill } = props

    return (
      <g>
        <text x={x} y={y} textAnchor="end" fill={fill}>
          {formatToLocaleString(payload.value, 1).replace(',0', '')}
        </text>
      </g>
    )
  }

  const handleData = (data: ICallsServiceDeskGraph) => {
    if (!data) {
      setDataShow([])
      setDataCSV([])
      return
    }

    const tempData: IInfrastructureCallsGraphShow[] = []

    for (const [key, value] of Object.entries(data)) {
      const dataRow: any | IInfrastructureCallsGraphShow = {
        axisX: key,
        access: 0,
        incident: 0,
        improvement: 0,
        information: 0,
        certification: 0,
        canceled: 0,
        total: 0,
      }

      series.forEach((serie) => {
        if (serie.key === 'total') {
          return
        }

        const element = value.find(
          (el) => el.problem_type === serie.problem_type,
        )

        dataRow[serie.key] = element ? parseInt(element.total as string) : 0
      })

      dataRow.total = parseInt(
        (
          dataRow.access +
          dataRow.canceled +
          dataRow.improvement +
          dataRow.incident +
          dataRow.information +
          dataRow.certification
        ).toFixed(),
      )

      tempData.push(dataRow)
    }

    tempData.sort((a, b) => {
      const dateA = new Date(`01/${a.axisX}`)
      const dateB = new Date(`01/${b.axisX}`)

      if (dateA < dateB) {
        return -1
      } else if (dateA > dateB) {
        return 1
      } else {
        return 0
      }
    })

    setDataShow(tempData)
    setDataCSV(tempData)
  }

  useEffect(() => {
    handleData(dataGraph)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Atendimentos realizados no Service Desk"
        action={
          <>
            {!isLoading && !isError && dataCSV.length > 0 ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="atendimentos-service-desk"
                    headers={headerCSV}
                    data={dataCSV}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow || dataShow.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 650, md: 630 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={dataShow}
                margin={{
                  left: 0,
                  right: 0,
                  top: 6,
                }}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  dataKey="axisX"
                  type="category"
                  tickLine={false}
                  interval="preserveStartEnd"
                  padding={{ left: 2, right: 2 }}
                  tickFormatter={(value) =>
                    `${formatDate(
                      `01/${value}`,
                      'MMM/yyyy',
                      true,
                    )}`.toUpperCase()
                  }
                />
                <YAxis
                  type="number"
                  tickLine={false}
                  tickMargin={12}
                  scale="sqrt"
                  tick={<CustomYAxisTick />}
                />
                {series.map((option) =>
                  option.type === 'line' ? (
                    <Line
                      key={option.key}
                      type="monotone"
                      dataKey={option.key}
                      name={option.name}
                      stroke={option.color}
                      legendType="plainline"
                      dot={false}
                      isAnimationActive={false}
                      strokeOpacity={
                        activeLegend !== ''
                          ? activeLegend === option.key
                            ? 1
                            : 0.3
                          : 1
                      }
                      strokeWidth={
                        activeLegend !== ''
                          ? activeLegend === option.key
                            ? 3
                            : 1
                          : 2
                      }
                    />
                  ) : (
                    <Bar
                      key={option.key}
                      dataKey={option.key}
                      name={option.name}
                      fill={option.color}
                      opacity={
                        activeLegend !== ''
                          ? activeLegend === option.key
                            ? 1
                            : 0.3
                          : 1
                      }
                      isAnimationActive={false}
                    />
                  ),
                )}
                <Legend
                  iconSize={18}
                  verticalAlign="bottom"
                  wrapperStyle={{ marginBottom: -12 }}
                  onMouseEnter={handleActiveLegend}
                  onMouseLeave={handleDisableLegend}
                />
              </ComposedChart>
            </ResponsiveContainer>
            <Box sx={{ mt: 4 }}>
              {series.map((data) => {
                if (!legendStatus[data.key]) return null

                return (
                  <Box key={legendStatus[data.key]} lineHeight={1}>
                    <Typography
                      fontSize={12}
                      fontWeight="bold"
                      component="span"
                    >
                      {data.name}:{' '}
                    </Typography>
                    <Typography fontSize={12} component="span">
                      {legendStatus[data.key]}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
