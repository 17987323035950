import { Box, styled } from "@mui/material"

export const Container = styled(Box)`
  width: 100%;
  height: 380px;
  flex: 3;
`

export const TooltipContainer = styled(Box)`
  background-color: #3c3c3c;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  padding: 1rem;
`

export const NoData = styled(Box)`
  background-color: #ececec;
  border-radius: 5px;
  width: 80%;
  height: 500px;

  display: flex;
  align-items: center;
  justify-content: center;
`
