import { useEffect, useRef, useContext } from 'react'
import { Grid } from '@mui/material'
import { onLoginEvent, ILogin, sendMessageEvent } from '../../../events'
import { Image } from '../../../components'
import { IUser } from '../../../domain'
import { UserService } from '../../../services'
import { UserContext } from '../../../context/user'
import { Title } from './style'
import logoOpin from 'assets/svg/logo_opin.svg'

export const LayoutHeader = () => {
  const effectUser = useRef(false)
  const { chartTitle } = useContext(UserContext)
  const { updateUser, updateLoading } = useContext(UserContext)

  const handleUser = () => {
    updateLoading({ name: 'user', loading: true })
    UserService.get()
      .then((data: IUser) => {
        updateUser(data)
        updateLoading({ name: 'user', loading: false })
        sendMessageEvent({
          type: 'success',
          text: 'Login efetuado com sucesso!',
        })
      })
      .catch(() => {
        updateUser({} as IUser)
        updateLoading({ name: 'user', loading: false })
      })
  }

  useEffect(() => {
    if (effectUser.current === false) {
      handleUser()
      return () => {
        effectUser.current = true
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const cleanEvent = onLoginEvent(({ login }: ILogin) => {
      if (login === false) {
        handleUser()
      }
    })
    return cleanEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      spacing={4}
      sx={{
        maxWidth: 'xl',
        minHeight: '64px',
        alignItems: 'center',
      }}
    >
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          maxWidth: 236,
          minHeight: '64px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Image
          src={logoOpin}
          sx={{
            height: 30,
            margin: { xs: 2, md: 0 },
            marginInlineEnd: { xs: 2, md: 5, lg: 5, xl: 5 },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'start' },
          paddingTop: { xs: '16px !important', md: '32px !important' },
          marginBottom: { xs: 2, md: 0 },
        }}
      >
        <Title>{chartTitle}</Title>
      </Grid>
    </Grid>
  )
}
