import { createTheme, responsiveFontSizes, useTheme } from '@mui/material'

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#0F570D',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#639B48',
        contrastText: '#FFF',
      },
      success: {
        main: '#639B48',
        contrastText: '#FFF',
      },
      info: {
        main: '#3C3C3C',
        contrastText: '#FFF',
      },
      error: {
        main: '#E32424',
        contrastText: '#FFF',
      },
    },
    components: {
      // Name of the component
      MuiPaper: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: 8,
            boxShadow: '0px 12px 15px #00000029',
          },
        },
      },
    },
    shape: {
      borderRadius: 0, // defaults to 4
    },
  }),
)

export { theme, useTheme }
