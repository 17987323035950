import { Box, CardContent, IconButton, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {
  IConsentFunnel,
  IConsentFunnelCSV,
  IDataConsentFunnel,
} from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import { formatToLocaleString } from '../../../utils'

interface DynamicConsentServerFunnelProps {
  dataFunnel: IConsentFunnel
  isLoading?: boolean
  isError?: boolean
}

const headerCSV = [
  { label: 'Etapa', key: 'label' },
  { label: 'Quantidade', key: 'total' },
]

export const DynamicConsentServerFunnel = ({
  dataFunnel,
  isLoading,
  isError,
}: DynamicConsentServerFunnelProps) => {
  const [dataShow, setDataShow] = useState<IConsentFunnel>({} as IConsentFunnel)
  const [dataCSV, setDataCSV] = useState<IConsentFunnelCSV[]>([])
  const [hiddenYAxis, setHiddenYAxis] = useState<boolean>(false)

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>Etapa: {payload[0].payload.label}</Typography>
        <Typography>
          Quantidade: {formatToLocaleString(payload[0].payload.total)}
        </Typography>
      </TooltipGraph>
    )
  }

  const customScale = (value: number, min: number, max: number) => {
    const scaleFactor = 1 / Math.log(max - min + 1)
    return Math.log(value - min + 1) * scaleFactor
  }

  const handleDataShow = (data: IConsentFunnel) => {
    if (
      !data.data ||
      data.data.length === 0 ||
      data.data.find((el) => (el.total as number) > 0) === undefined
    ) {
      setDataShow({} as IConsentFunnel)
      return
    }

    const maxValue = data.data.reduce(function (prev, current) {
      return prev.total > current.total ? prev : current
    })

    const tmp: IDataConsentFunnel[] = []

    data.data.forEach((item: IDataConsentFunnel) => {
      let initial: number = 0
      let end: number = 1
      let valueScale = customScale(
        item.total ? item.total : 0,
        0,
        maxValue.total,
      )

      if (maxValue.total !== item.total) {
        initial = (end - (valueScale as number)) / 2
        end = (valueScale as number) + initial
      }

      tmp.push({
        name: item.name,
        label: item.label,
        total: item.total,
        value: [initial, end],
        desc: valueScale,
      })
    })

    setDataShow({ ...data, data: tmp })
  }

  const handleDataDownload = (data: IConsentFunnel) => {
    if (!data.data) {
      setDataCSV([])
      return
    }

    const tmp: IConsentFunnelCSV[] = []

    data.data.forEach((item: IDataConsentFunnel) => {
      tmp.push({
        label: item.label,
        total: item.total as number,
      })
    })

    setDataCSV(tmp)
  }

  useEffect(() => {
    handleDataShow(dataFunnel)
    handleDataDownload(dataFunnel)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFunnel])

  useEffect(() => {
    setHiddenYAxis(window.innerWidth <= 899)

    window.addEventListener('resize', () =>
      setHiddenYAxis(window.innerWidth <= 899),
    )

    return () =>
      window.removeEventListener('resize', () =>
        setHiddenYAxis(window.innerWidth <= 899),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardBox>
      <CardBoxTitle
        title="Funil de Consentimentos - Etapa Server"
        action={
          <>
            {!isLoading && !isError && dataShow.data && dataCSV.length > 0 ? (
              <>
                <TooltipInfo
                  title="Exibe a quantidade de eventos que ocorrem em Server e foram reportados ao PCM"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="info">
                    <InfoOutlined color="info" sx={{ opacity: 0.5 }} />
                  </IconButton>
                </TooltipInfo>
                <TooltipInfo
                  title="Fazer download do arquivo CSV"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="download">
                    <IconCsvDownloader
                      filename="funil-consentimentos-server"
                      headers={headerCSV}
                      data={dataCSV}
                    />
                  </IconButton>
                </TooltipInfo>
              </>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow || !dataShow.data || dataShow.data.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer>
              <BarChart
                data={dataShow.data}
                layout="vertical"
                margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#ffffff" />
                <XAxis
                  axisLine={false}
                  type="number"
                  domain={[0, 1]}
                  padding={{ left: 10, right: 0 }}
                  hide
                />
                <YAxis
                  dataKey="label"
                  type="category"
                  axisLine={true}
                  tickLine={false}
                  interval={0}
                  width={251}
                  hide={hiddenYAxis}
                />
                <Bar dataKey="value" isAnimationActive={false}>
                  {dataShow.data.map((entry, index) => {
                    return <Cell key={`cell-${index}`} fill={'#639B48'} />
                  })}
                  <LabelList
                    dataKey="total"
                    fill="#000000de"
                    position="center"
                    formatter={(value: string | number | undefined) =>
                      formatToLocaleString(value)
                    }
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
