export const graphListHelper = [
  {
    key: 0,
    title: 'Chamadas Rejeitadas das APIs',
  },
  {
    key: 1,
    title: 'Chamadas rejeitadas das APIs por sociedade participante',
  },
  { key: 2, title: 'Chamadas Fracassadas das APIs' },
  {
    key: 3,
    title: 'Chamadas Fracassadas das APIs Por sociedade Participante',
  },
]
