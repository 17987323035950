import { HistoryParams } from '../domain'
import axiosDashClient from '../libs/axiosDash'

export const getHistoryReport = async (): Promise<HistoryParams | undefined> => {
  try {
    const { data } = await axiosDashClient.get('/historyReport')
    return data
  } catch (error) {
    return undefined
  }
}
