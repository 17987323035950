import { Box, CardContent, IconButton, Typography } from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import { IAvailabilityPortalGraph } from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import { formatNumber, formatToLocaleString, formatDate } from '../../../utils'

interface InfrastructureAvailabilityPortalGraphProps {
  dataGraph: IAvailabilityPortalGraph
  isLoading?: boolean
  isError?: boolean
  isMobile?: boolean
}

interface IInfrastructureAvailabilityPortalGraphShow {
  axisX: string
  up: number
  down: number
}

const headerCSV = [
  { label: 'Período', key: 'axisX' },
  { label: 'No ar (%)', key: 'up' },
  { label: 'Fora do ar (%)', key: 'down' },
]

export const InfrastructureAvailabilityPortalGraph = ({
  dataGraph,
  isLoading,
  isError,
  isMobile,
}: InfrastructureAvailabilityPortalGraphProps) => {
  const [dataShow, setDataShow] = useState<
    IInfrastructureAvailabilityPortalGraphShow[]
  >([])
  const [dataCSV, setDataCSV] = useState<
    IInfrastructureAvailabilityPortalGraphShow[]
  >([])
  const [activeLegend, setActiveLegend] = useState<string>('')

  const handleActiveLegend = ({ dataKey }: any) => {
    setActiveLegend(dataKey)
  }

  const handleDisableLegend = () => {
    setActiveLegend('')
  }

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography textTransform="uppercase">
          {formatDate(payload[0].payload.axisX, 'MMM/yyyy', true)}
        </Typography>
        <Typography>
          No ar: {formatToLocaleString(payload[0].payload.up)}%
        </Typography>
        <Typography>
          Fora do Ar: {formatToLocaleString(payload[0].payload.down)}%
        </Typography>
      </TooltipGraph>
    )
  }

  const handleDataShow = (data: IAvailabilityPortalGraph) => {
    if (!data) {
      setDataShow([])
      return
    }

    const tempData: IInfrastructureAvailabilityPortalGraphShow[] = []

    for (const [key, value] of Object.entries(data)) {
      let up = 0
      let down = 0

      const upObject = value.find((el) => el.status === 'up')
      const downObject = value.find((el) => el.status === 'down')

      if (upObject) {
        if (
          upObject?.statustime &&
          Object.keys(upObject.statustime).length > 0
        ) {
          const { hours: hoursUp, minutes: minutesUp } = upObject.statustime
          up = (hoursUp || 0) * 60 + (minutesUp || 0)
        }
      }

      if (downObject) {
        if (
          downObject?.statustime &&
          Object.keys(downObject.statustime).length > 0
        ) {
          const { hours: hoursDown, minutes: minutesDown } =
            downObject.statustime
          down = (hoursDown || 0) * 60 + (minutesDown || 0)
        }
      }

      tempData.push({
        axisX: key,
        up: formatNumber((up * 100) / (up + down), 2) as number,
        down: formatNumber((down * 100) / (up + down), 2) as number,
      })
    }

    tempData.sort((a, b) => {
      const dateA = new Date(`01/${a.axisX}`)
      const dateB = new Date(`01/${b.axisX}`)

      if (dateA < dateB) {
        return -1
      } else if (dateA > dateB) {
        return 1
      } else {
        return 0
      }
    })

    setDataShow(tempData)
  }

  const handleDataDownload = (data: IAvailabilityPortalGraph) => {
    if (!data) {
      setDataCSV([])
      return
    }

    const tempData: IInfrastructureAvailabilityPortalGraphShow[] = []

    for (const [key, value] of Object.entries(data)) {
      let up = 0
      let down = 0

      const upObject = value.find((el) => el.status === 'up')
      const downObject = value.find((el) => el.status === 'down')

      if (upObject) {
        if (
          upObject?.statustime &&
          Object.keys(upObject.statustime).length > 0
        ) {
          const { hours: hoursUp, minutes: minutesUp } = upObject.statustime
          up = (hoursUp || 0) * 60 + (minutesUp || 0)
        }
      }

      if (downObject) {
        if (
          downObject?.statustime &&
          Object.keys(downObject.statustime).length > 0
        ) {
          const { hours: hoursDown, minutes: minutesDown } =
            downObject.statustime
          down = (hoursDown || 0) * 60 + (minutesDown || 0)
        }
      }

      tempData.push({
        axisX: key,
        up: formatNumber((up * 100) / (up + down), 2) as number,
        down: formatNumber((down * 100) / (up + down), 2) as number,
      })
    }

    tempData.sort((a, b) => {
      const dateA = new Date(`01/${a.axisX}`)
      const dateB = new Date(`01/${b.axisX}`)

      if (dateA < dateB) {
        return -1
      } else if (dateA > dateB) {
        return 1
      } else {
        return 0
      }
    })

    setDataCSV(tempData)
  }

  useEffect(() => {
    handleDataShow(dataGraph)
    handleDataDownload(dataGraph)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Disponibilidade do Portal Open Insurance"
        action={
          <>
            {!isLoading && !isError && dataCSV.length > 0 ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="disponibilidade-portal-open-insurance"
                    headers={headerCSV}
                    data={dataCSV}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow || dataShow.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={dataShow}
                margin={{
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
                barGap={0}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  dataKey="axisX"
                  type="category"
                  tickLine={false}
                  interval="preserveStartEnd"
                  padding={{ left: 2, right: 2 }}
                  tickFormatter={(value) =>
                    `${formatDate(
                      `01/${value}`,
                      'MMM/yyyy',
                      true,
                    )}`.toUpperCase()
                  }
                />
                <YAxis
                  type="number"
                  tickLine={false}
                  tickMargin={12}
                  domain={[0, 100]}
                  unit="%"
                  scale="sqrt"
                  padding={{ top: 23 }}
                  tickFormatter={(el) => formatToLocaleString(el)}
                />
                <Bar
                  stackId="stack"
                  dataKey="down"
                  name="Fora do Ar"
                  fill="#ea2027"
                  opacity={
                    activeLegend !== ''
                      ? activeLegend === 'down'
                        ? 1
                        : 0.3
                      : 1
                  }
                  isAnimationActive={false}
                >
                  {!isMobile && (
                    <LabelList
                      dataKey="down"
                      fill="#000000de"
                      position="top"
                      formatter={(el: number) =>
                        el > 0 ? `${formatToLocaleString(el)}%` : ''
                      }
                    />
                  )}
                </Bar>
                <Bar
                  stackId="stack"
                  dataKey="up"
                  name="No ar"
                  fill="#639B48"
                  opacity={
                    activeLegend !== '' ? (activeLegend === 'up' ? 1 : 0.3) : 1
                  }
                  isAnimationActive={false}
                >
                  {!isMobile && (
                    <LabelList
                      dataKey="up"
                      fill="#000000de"
                      position="top"
                      formatter={(el: string | number) =>
                        `${formatToLocaleString(el)}%`
                      }
                    />
                  )}
                  {activeLegend === '' && !isMobile && (
                    <LabelList
                      dataKey="down"
                      fill="#000000de"
                      position="insideBottom"
                      formatter={(el: number) =>
                        el > 0 ? `${formatToLocaleString(el)}%` : ''
                      }
                    />
                  )}
                </Bar>
                <Legend
                  iconSize={18}
                  verticalAlign="bottom"
                  wrapperStyle={{ marginBottom: -12 }}
                  onMouseEnter={handleActiveLegend}
                  onMouseLeave={handleDisableLegend}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
