import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TooltipGraph = styled(Box)({
  backgroundColor: '#6E6E6E',
  borderRadius: '5px',
  border: '1px solid #ffffff',
  color: '#ffffff',
  padding: '1rem',
  maxWidth: 300,
})
