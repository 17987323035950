import { Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import {
  IConsentGraph,
  IEndpointGraph,
  IErrorGraphs,
  IFilter,
  IReceiverGraph,
  IStatusGraph,
  ITransmitterGraph,
} from '../../../domain'
import {
  DynamicConsentRequestsGraph,
  DynamicEndpointRequestsGraph,
  DynamicReceiverRequestsGraph,
  DynamicStatusResponseRequestsGraph,
  DynamicTransmitterRequestsGraph,
} from '../../../components'
import { UserContext } from '../../../context/user'
import { ReportService } from '../../../services'

interface DynamicPrivateGraphsProps {
  activatedFilters: IFilter
}

export const DynamicPrivateGraphs = ({
  activatedFilters,
}: DynamicPrivateGraphsProps) => {
  const { user, loading, updateLoading } = useContext(UserContext)
  const [lastFilter, setLastFilter] = useState<{
    filter: IFilter
    time: number
  }>({
    filter: activatedFilters,
    time: new Date().getTime(),
  })

  const [endpointGraphData, setEndpointGraphData] = useState<IEndpointGraph>(
    {} as IEndpointGraph,
  )
  const [statusGraphData, setStatusGraphData] = useState<IStatusGraph>(
    {} as IStatusGraph,
  )
  const [receiverGraphData, setReceiverGraphData] = useState<IReceiverGraph>(
    {} as IReceiverGraph,
  )
  const [transmitterGraphData, setTransmitterGraphData] =
    useState<ITransmitterGraph>({} as ITransmitterGraph)
  const [consentGraphData, setConsentGraphData] = useState<IConsentGraph>(
    {} as IConsentGraph,
  )
  const [graphsError, setGraphsError] = useState<IErrorGraphs>({
    transmitterGraph: false,
    consentFunnel: false,
    consentServerFunnel: false,
    receiverGraph: false,
    consentGraph: false,
    endpointsGraph: false,
    statusGraph: false,
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    ReportService.getEndpointChart(lastFilter.filter, signal)
      .then((data: IEndpointGraph) => {
        setEndpointGraphData(data)
        setGraphsError((prevState) => ({
          ...prevState,
          endpointsGraph: false,
        }))
        updateLoading({ name: 'endpointChart', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setEndpointGraphData({} as IEndpointGraph)
          setGraphsError((prevState) => ({
            ...prevState,
            endpointsGraph: true,
          }))
          updateLoading({ name: 'endpointChart', loading: false })
        }
      })

    ReportService.getStatusChart(lastFilter.filter, signal)
      .then((data: IStatusGraph) => {
        setStatusGraphData(data)
        setGraphsError((prevState) => ({ ...prevState, statusGraph: false }))
        updateLoading({ name: 'statusChart', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setStatusGraphData({} as IStatusGraph)
          setGraphsError((prevState) => ({ ...prevState, statusGraph: true }))
          updateLoading({ name: 'statusChart', loading: false })
        }
      })

    ReportService.getTransmitterChart(lastFilter.filter, signal)
      .then((data: ITransmitterGraph) => {
        setTransmitterGraphData(data)
        setGraphsError((prevState) => ({
          ...prevState,
          transmitterGraph: false,
        }))
        updateLoading({ name: 'transmitterChart', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setTransmitterGraphData({} as ITransmitterGraph)
          setGraphsError((prevState) => ({
            ...prevState,
            transmitterGraph: true,
          }))
          updateLoading({ name: 'transmitterChart', loading: false })
        }
      })

    if (user.name) {
      ReportService.getReceiverChart(lastFilter.filter, signal)
        .then((data: IReceiverGraph) => {
          setReceiverGraphData(data)
          setGraphsError((prevState) => ({
            ...prevState,
            receiverGraph: false,
          }))
          updateLoading({ name: 'receiverChart', loading: false })
        })
        .catch((error) => {
          if (error.code && error.code !== 'ERR_CANCELED') {
            setReceiverGraphData({} as IReceiverGraph)
            setGraphsError((prevState) => ({
              ...prevState,
              receiverGraph: true,
            }))
            updateLoading({ name: 'receiverChart', loading: false })
          }
        })

      ReportService.getConsentChart(lastFilter.filter, signal)
        .then((data: IConsentGraph) => {
          setConsentGraphData(data)
          setGraphsError((prevState) => ({
            ...prevState,
            consentGraph: false,
          }))
          updateLoading({ name: 'consentChart', loading: false })
        })
        .catch((error) => {
          if (error.code && error.code !== 'ERR_CANCELED') {
            setConsentGraphData({} as IConsentGraph)
            setGraphsError((prevState) => ({
              ...prevState,
              consentGraph: true,
            }))
            updateLoading({ name: 'consentChart', loading: false })
          }
        })
    }

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFilter])

  useEffect(() => {
    const now = new Date().getTime()
    if (
      activatedFilters.interval &&
      activatedFilters.interval[0] &&
      (JSON.stringify(activatedFilters) !==
        JSON.stringify(lastFilter?.filter) ||
        Math.abs(lastFilter.time - now) > 500)
    ) {
      setLastFilter({
        filter: activatedFilters,
        time: now,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedFilters])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicTransmitterRequestsGraph
          dataGraph={transmitterGraphData}
          isLoading={loading.transmitterChart}
          isError={graphsError.transmitterGraph}
          activatedFilters={activatedFilters}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicReceiverRequestsGraph
          dataGraph={receiverGraphData}
          isLoading={loading.receiverChart}
          isError={graphsError.receiverGraph}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicEndpointRequestsGraph
          dataGraph={endpointGraphData}
          isLoading={loading.endpointChart}
          isError={graphsError.endpointsGraph}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicStatusResponseRequestsGraph
          dataGraph={statusGraphData}
          isLoading={loading.statusChart}
          isError={graphsError.statusGraph}
          filterBy={lastFilter.filter.statuses}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicConsentRequestsGraph
          dataGraph={consentGraphData}
          isLoading={loading.consentChart}
          isError={graphsError.consentGraph}
        />
      </Grid>
    </Grid>
  )
}
