import { Alert, Container, Grid, Theme, Typography } from '@mui/material'
import { useContext } from 'react'
import {
  DynamicPrivateFunnelGraphs,
  DynamicPrivateFunnelInfoGraphs,
  DynamicPrivateGraphs,
  DynamicPrivateInfoGraphs,
  DynamicPublicGraphs,
  DynamicPublicInfoGraphs,
} from '../../../components'
import { IFilter } from '../../../domain'
import { UserContext } from '../../../context/user'

interface DynamicGraphsProps {
  activatedFilters: IFilter
}

export const DynamicGraphs = ({ activatedFilters }: DynamicGraphsProps) => {
  const { user } = useContext(UserContext)
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} mt={3}>
          <Alert
            variant="filled"
            severity="info"
            sx={{
              boxShadow: 0,
              backgroundColor: '#0288d1 !important',
              '.MuiAlert-icon': {
                alignItems: 'center !important',
              },
            }}
          >
            Esta seção apresenta os indicadores obtidos e sumarizados a partir
            dos reportes enviados pelas Sociedades Participantes à Plataforma de
            Coleta de Métricas (PCM).
          </Alert>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                color="primary"
                sx={{
                  fontSize: (theme: Theme) => theme.typography.pxToRem(24),
                  letterSpacing: 0,
                  textAlign: 'center',
                }}
              >
                Indicadores de Métricas Transacionais
              </Typography>
            </Grid>
            {!user.name ? (
              <DynamicPublicInfoGraphs activatedFilters={activatedFilters} />
            ) : (
              <DynamicPrivateInfoGraphs activatedFilters={activatedFilters} />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} mt={2}>
          {!user.name ? (
            <DynamicPublicGraphs activatedFilters={activatedFilters} />
          ) : (
            <DynamicPrivateGraphs activatedFilters={activatedFilters} />
          )}
        </Grid>
        {user.name && (
          <>
            <Grid item xs={12} my={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: (theme: Theme) => theme.typography.pxToRem(24),
                      letterSpacing: 0,
                      textAlign: 'center',
                    }}
                  >
                    Indicadores de Funil de Consentimento
                  </Typography>
                </Grid>
                <DynamicPrivateFunnelInfoGraphs
                  activatedFilters={activatedFilters}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <DynamicPrivateFunnelGraphs activatedFilters={activatedFilters} />
            </Grid>
          </>
        )}
        <Grid item xs={12} mt={3} mb={1}>
          <Alert
            variant="filled"
            severity="warning"
            sx={{
              boxShadow: 0,
              '.MuiAlert-icon': {
                alignItems: 'center !important',
              },
            }}
          >
            Esta seção apresenta dados desde 01/08/2023. Consulte Métricas
            Agregadas para períodos anteriores.
          </Alert>
        </Grid>
      </Grid>
    </Container>
  )
}
