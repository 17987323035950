export const parseNumberToInt = (value: string | number): number => parseInt(value.toString())

export const parseNumberToLocale = (value: string | number, minDigits: number = 0, maxDigits: number = 2): string => {
  if (typeof value === 'number') { return value.toLocaleString('pt-BR') }
  return Number(value).toLocaleString('pt-BR', { minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits })
}

export const formatToLocaleString = (number: number | string = 0, min: number = 2, max: number = 2) => {
  const newNumber = Number(number)

  const formattedNumber = newNumber.toLocaleString('pt-BR', {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  })

  return formattedNumber.replace(/,0+$|(\.[0-9]*[1-9])0+$/, '')
}

export const parseNumberToFixedTwo = (value: string | number = '0'): string => {
  if (!value) return value = '0'

  if (typeof value === 'string') { return parseFloat(value).toFixed(2) }

  return value.toFixed(2)
}

export const formatNumber = (
  num: number,
  digits: number,
  multiplePercentil: boolean = false,
) => {
  if (typeof num !== 'number') {
    return num
  }

  if (multiplePercentil) {
    num = num * 100
  }

  const formattedValue = Number.isInteger(num) ? num : num.toFixed(digits).replace('.00', '')

  return formattedValue
}

export const formatNumberToScale = (
  num: number,
  digits: number = 1,
  min: number = 10000,
) => {
  if (min > num) {
    return Number.isInteger(num) ? num : decimalAdjust(num, digits)
  }

  const abbreviations = ['', 'K', 'M', 'B', 'T']
  let abbreviationIndex = 0

  while (num >= 1000 && abbreviationIndex < abbreviations.length - 1) {
    num /= 1000
    abbreviationIndex++
  }

  const formattedValue = Number.isInteger(num)
    ? num
    : decimalAdjust(num, digits)

  return `${formattedValue}${abbreviations[abbreviationIndex]}` as string
}

export const formatNumberTimeMsToScale = (num: number, digits: number = 2) => {
  const abbreviations = ['ms', 's', 'm']
  let abbreviationIndex = 1

  if (num < 1000) {
    const formattedValue = Number.isInteger(num)
      ? num
      : decimalAdjust(num, digits)

    return `${formatToLocaleString(formattedValue)}${abbreviations[0]}` as string
  }

  if (num < 59999) {
    num /= 1000
    const formattedValue = Number.isInteger(num)
      ? num
      : decimalAdjust(num, digits)
    return `${formatToLocaleString(formattedValue)}${abbreviations[abbreviationIndex]}` as string
  }

  num /= 1000

  while (num >= 60 && abbreviationIndex < abbreviations.length - 1) {
    num /= 60
    abbreviationIndex++
  }
  const formattedValue = Number.isInteger(num)
    ? num
    : decimalAdjust(num, digits)

  return `${formatToLocaleString(formattedValue)}${abbreviations[abbreviationIndex]}` as string
}

const decimalAdjust = (num: number, digits: number) => {
  const og = Math.pow(10, digits)
  return Math.trunc(num * og) / og
}
