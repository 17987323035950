import { CardHeader, CardHeaderProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CardBoxTitle = styled(CardHeader)<CardHeaderProps>(
  ({ theme }) => ({
    paddingBlockStart: 10,
    paddingBlockEnd: 6,
    minHeight: 52,
    '& .MuiCardHeader-title': {
      color: theme.palette.info.main,
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(18),
      letterSpacing: 0,
      overflowWrap: 'anywhere',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    '& .MuiCardHeader-action': {
      alignSelf: 'center',
    },
  }),
)
