import {
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const CardTitle = styled(CardHeader)<CardHeaderProps>(({ theme }) => ({
  paddingBlockStart: 10,
  paddingBlockEnd: 6,
  minHeight: 52,
  height: '100%',
  '& .MuiCardHeader-title': {
    color: theme.palette.info.main,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: 0,
    overflowWrap: 'anywhere',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  '& .MuiCardHeader-action': {
    alignSelf: 'center',
  },
}))

export const CardSubTitle = styled(CardContent)<CardContentProps>({
  paddingBlockStart: '0px !important',
  paddingBlockEnd: '6px !important',
})

export const Result = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  lineHeight: theme.typography.pxToRem(54),
  letterSpacing: 0,
  '& span': {
    lineHeight: theme.typography.pxToRem(34),
  },
  [theme.breakpoints.down('md')]: {
    lineHeight: theme.typography.pxToRem(42),
    '& span': {
      lineHeight: theme.typography.pxToRem(22),
    },
  },
}))
