import { Container, Grid } from '@mui/material'
import { InfrastructurePublicGraphs } from '../../../components'
import { IFilter } from '../../../domain'

interface InfrastructureGraphsProps {
  activatedFilters: IFilter
}

export const InfrastractureGraphs = ({
  activatedFilters,
}: InfrastructureGraphsProps) => {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} md={12} lg={12} sx={{ height: '100%' }}>
          <InfrastructurePublicGraphs activatedFilters={activatedFilters} />
        </Grid>
      </Grid>
    </Container>
  )
}
