import { Alert, Box, Snackbar } from '@mui/material'
import { SyntheticEvent, useEffect, useState } from 'react'
import { IMessage, onMessageEvent } from '../../../events'
import { LayoutFooter, LayoutNav } from '../../../components'
import { BoxFooter, BoxMain, BoxNav, BoxWrapper } from './styles'
import { Outlet } from 'react-router-dom'

export const GridLayout = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<IMessage>({} as IMessage)

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    const cleanEvent = onMessageEvent((message: IMessage) => {
      setMessage(message)
      setOpen(true)
    })
    return cleanEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <BoxWrapper>
        <BoxNav component="nav">
          <LayoutNav />
        </BoxNav>

        <BoxMain component="main">
          <Outlet />

          <BoxFooter component="footer">
            <LayoutFooter />
          </BoxFooter>
        </BoxMain>
      </BoxWrapper>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          severity={message.type}
          sx={{ minWidth: '270px' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </Box>
  )
}
