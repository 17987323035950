import { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { StyledFormControl } from './style'
import { SelectDefaultValue } from '../../../domain'

interface SingleAutocompleteProps {
  id: string
  label: string
  multiple?: boolean
  options: SelectDefaultValue[]
  value: SelectDefaultValue
  loading?: boolean
  onSelect: (data: SelectDefaultValue) => void
}

export const SingleAutocomplete = ({
  id,
  label,
  options,
  value,
  loading = false,
  onSelect,
  ...props
}: SingleAutocompleteProps) => {
  const [valueOption, setValueOption] = useState<SelectDefaultValue>(value)

  useEffect(() => {
    onSelect(valueOption)
  }, [onSelect, valueOption])

  useEffect(() => {
    setValueOption(options[0])
  }, [options])

  useEffect(() => {
    setValueOption(value ?? options[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledFormControl size="small">
      <Autocomplete
        id={id}
        openOnFocus={true}
        value={valueOption}
        filterSelectedOptions={false}
        isOptionEqualToValue={(option, value) => {
          return option.label === value.label
        }}
        popupIcon={<KeyboardArrowDown />}
        options={options}
        disableClearable
        onChange={(_, value) => {
          setValueOption(value as SelectDefaultValue)
        }}
        noOptionsText={loading ? 'Carregando...' : 'Nenhuma opção encontrada!'}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              color="secondary"
              size="small"
              sx={{
                '.MuiInputBase-root': {
                  boxShadow: '0px 3px 6px #00000029',
                  border: '#E0E0E0',
                  borderRadius: '10px',
                  color: '#707070',
                  paddingBlock: '6.5px !important',
                  paddingLeft: '14px !important',
                  minHeight: '51px !important',
                  flexWrap: 'nowrap',
                },
                '.MuiAutocomplete-input': {
                  paddingLeft: '3px !important',
                  minWidth: '0px !important',
                },
                '.Mui-focused > p': {
                  maxWidth: '60%',
                },
                '&:hover': {
                  '&& fieldset': {
                    borderColor: 'secondary.main',
                    color: '#707070',
                  },
                },
              }}
            />
          )
        }}
        ListboxProps={{
          style: {
            maxHeight: 225,
          },
        }}
        {...props}
      />
    </StyledFormControl>
  )
}
