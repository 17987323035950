import _ from 'lodash'
import {
  Bar,
  ComposedChart,
  LabelList,
  LabelProps,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Typography, useTheme } from '@mui/material'
import { Remove } from '@mui/icons-material'
import { ChartContainer, Container, Legend } from './styles'
import {
  ViewDataError,
  ViewDataLoading,
  TooltipGraph,
} from '../../../../../components'
import {
  checkInstitutionsListGraphsStatus,
  formatToLocaleString,
} from '../../../../../utils'
import { SelectDefaultValue } from '../../../../../domain'

interface AverageAvailabilityPageTwoProps {
  data: any
  chartOrder: SelectDefaultValue
  mobile: boolean
  loading: boolean
}

const AverageAvailabilityPageTwo = ({
  data,
  chartOrder,
  mobile,
  loading,
}: AverageAvailabilityPageTwoProps) => {
  const theme = useTheme()
  const filteredData = data?.filter((item: any) => item.uptime > 0)
  const status = checkInstitutionsListGraphsStatus(data, loading, filteredData)

  const PercentLabel = ({ x, y, width, value }: LabelProps) => {
    const _x = Number(x) + Number(width) + 40
    const _y = Number(y) + 11

    return (
      <g>
        <text
          x={_x}
          y={_y}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {formatToLocaleString(value, 2)}%
        </text>
      </g>
    )
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <TooltipGraph>
          <Typography>Sociedade: {payload[0].payload.serverName}</Typography>
          <Typography>
            Média: {formatToLocaleString(payload[0].value)}%
          </Typography>
          <Typography>
            SLA: {formatToLocaleString(payload[1].value)}%
          </Typography>
        </TooltipGraph>
      )
    }
    return null
  }

  return (
    <>
      {loading && <ViewDataLoading />}

      {!loading && (!data || data.length === 0 || status === 'NODATA') && (
        <ViewDataError />
      )}

      {status === 'HASDATA' && (
        <Container sx={{ overflow: 'hidden', height: 380 }}>
          <ChartContainer>
            <ResponsiveContainer
              minWidth={500}
              minHeight={data.length > 4 ? data.length * 70 : 280}
            >
              <ComposedChart
                layout="vertical"
                data={_.orderBy(
                  filteredData.map((item: any) => ({ ...item, line: 95 })),
                  [chartOrder.value.by],
                  [chartOrder.value.type],
                )}
              >
                <XAxis type="number" hide={true} domain={[0, 130]} />
                <YAxis
                  dataKey="serverName"
                  type="category"
                  width={200}
                  tickLine={false}
                  interval={0}
                  fontSize={12}
                />
                <Bar
                  isAnimationActive={false}
                  dataKey="uptime"
                  fill={theme.palette.primary.light}
                  barSize={20}
                >
                  <LabelList
                    dataKey="uptime"
                    position="right"
                    content={PercentLabel}
                  />
                </Bar>
                <Line
                  isAnimationActive={false}
                  type="basis"
                  dot={false}
                  dataKey="line"
                  stroke={'#ffe191'}
                  strokeWidth={3}
                  strokeDasharray="10"
                />
                {!mobile && <Tooltip content={<CustomTooltip />} />}
              </ComposedChart>
            </ResponsiveContainer>
          </ChartContainer>

          <Legend>
            <span>
              <Remove sx={{ color: '#ffe191' }} />
              <Remove sx={{ color: '#ffe191' }} />
            </span>
            <span>
              Disponibilidade média esperada para APIs segundo Instrução
              Normativa n°98: SLA: 95,0% do tempo a cada 24 horas
            </span>
          </Legend>
        </Container>
      )}
    </>
  )
}

export default AverageAvailabilityPageTwo
