import { Box } from '@mui/material'
import { ViewGraphError } from '../../../components'

export const ViewDataError = () => {
  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: { xs: 380, md: 280 },
      }}
    >
      <ViewGraphError message="Não foi possível concluir sua solicitação, tente novamente mais tarde." />
    </Box>
  )
}
