import _ from 'lodash'
import {
  Box,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { Legend } from './styles'
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  LabelProps,
} from 'recharts'
import { useEffect, useState } from 'react'
import { Remove } from '@mui/icons-material'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxSubTitle,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import {
  monthFormatter,
  parseNumberToFixedTwo,
  parseNumberToLocale,
} from '../../../utils'
import { HistoryChildrenParams } from '../../../domain'

interface AggregateAverageAvailabilityGraphProps {
  dataGraph?: HistoryChildrenParams[]
  isLoading?: boolean
  isError?: boolean
}

const headerCSV: any[] = [
  { label: 'Período', key: 'periodo' },
  { label: 'Porcentagem', key: 'porcentagem' },
]

export const AggregateAverageAvailabilityGraphGraph = ({
  dataGraph,
  isLoading,
  isError,
}: AggregateAverageAvailabilityGraphProps) => {
  const theme = useTheme()
  const [filteredData, setFilteredData] = useState<HistoryChildrenParams[]>([])
  const [hiddenLabel, setHiddenLaben] = useState<boolean>(false)

  const PercentLabel = ({ x, y, width, value }: LabelProps) => {
    const _x = Number(x) + Number(width) / 2
    const _y = Number(y) - 10
    const _value =
      value &&
      parseNumberToLocale(Number(parseNumberToFixedTwo(value.toString())))
    return (
      <g>
        <text
          x={_x}
          y={_y}
          fill={theme.palette.common.black}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={12}
        >
          {!hiddenLabel && `${_value}%`}
        </text>
      </g>
    )
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (!active || !payload || !payload[0] || !payload[1]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>Período: {payload[0].payload.month_year}</Typography>
        <Typography>
          Porcentagem:{' '}
          {parseNumberToLocale(
            Number(parseNumberToFixedTwo(payload[0].value.toString())),
          )}
          %
        </Typography>
        <Typography>SLA: {payload[1].value}%</Typography>
      </TooltipGraph>
    )
  }

  const getDataToExportCSV = (): any => {
    return filteredData.map(({ month_year: monthYear, value }: any): any => ({
      periodo: monthYear,
      porcentagem: `${parseNumberToFixedTwo(value)}%`,
    }))
  }

  useEffect(() => {
    if (dataGraph) {
      const newData = [...dataGraph]
      setFilteredData(
        _.orderBy(
          newData.map((item) => ({
            ...item,
            month_year: monthFormatter(item.month_year),
            line: 95,
            id: item.month_year.replace('-', ''),
          })),
          ['id'],
          ['asc'],
        ),
      )
    }
  }, [dataGraph])

  useEffect(() => {
    setHiddenLaben(window.innerWidth <= 899)

    window.addEventListener('resize', () =>
      setHiddenLaben(window.innerWidth <= 899),
    )

    return () =>
      window.removeEventListener('resize', () =>
        setHiddenLaben(window.innerWidth <= 899),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardBox>
      <CardBoxTitle
        title="Disponibilidade Média das APIs"
        action={
          <>
            {!isError && dataGraph ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="historico-disponibilidade-media-api"
                    headers={headerCSV}
                    data={getDataToExportCSV()}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />
      <CardBoxSubTitle title="Demonstração da disponibilidade média das APIs de sociedades participantes" />

      <CardContent>
        {!dataGraph && !isError ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataGraph || dataGraph.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={filteredData}
                margin={{
                  left: 5,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
                barGap={0}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  dataKey="month_year"
                  type="category"
                  tickLine={false}
                  interval="preserveStartEnd"
                  padding={{ left: 2, right: 2 }}
                />
                <YAxis
                  type="number"
                  domain={[0, 100]}
                  tickLine={false}
                  tickMargin={12}
                  tickCount={4}
                  padding={{ top: 20 }}
                />
                <Bar
                  isAnimationActive={false}
                  dataKey="value"
                  fill={theme.palette.primary.light}
                >
                  <LabelList
                    dataKey="value"
                    position="right"
                    content={PercentLabel}
                  />
                </Bar>
                <Line
                  isAnimationActive={false}
                  type="basis"
                  dot={false}
                  dataKey="line"
                  stroke={'#ffe191'}
                  strokeWidth={3}
                  strokeDasharray="10"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
      {dataGraph && (
        <CardActions>
          <Legend>
            <Remove sx={{ color: '#ffe191' }} />
            <Typography fontSize={12}>
              Disponibilidade média esperada para APIs segundo Instrução
              Normativa n°98: SLA: 95,0% do tempo a cada 24 horas
            </Typography>
          </Legend>
        </CardActions>
      )}
    </CardBox>
  )
}
