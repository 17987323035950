import { Box, CardContent, IconButton, Typography } from '@mui/material'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import {
  IAvarageCSV,
  IDataStatusGraph,
  ISerieStatusGraph,
  IStatusGraph,
} from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import {
  formatNumberTimeMsToScale,
  formatDate,
  formatNumber,
} from '../../../utils'

interface DynamicResponseRequestsGraphProps {
  dataGraph: IStatusGraph
  isLoading?: boolean
  isError?: boolean
}

const headerCSV = [
  { label: 'Data', key: 'date' },
  { label: 'Tempo médio de resposta (ms)', key: 'avarage' },
]

export const DynamicResponseRequestsGraph = ({
  dataGraph,
  isLoading,
  isError,
}: DynamicResponseRequestsGraphProps) => {
  const [dataShow, setDataShow] = useState<IStatusGraph>({} as IStatusGraph)
  const [activeLegend, setActiveLegend] = useState<string>('')
  const [dataCSV, setDataCSV] = useState<IAvarageCSV[]>([])

  const handleActiveLegend = ({ dataKey }: any) => {
    setActiveLegend(dataKey)
  }

  const handleDisableLegend = () => {
    setActiveLegend('')
  }

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>{payload[0].payload.axisX}</Typography>
        <Typography>
          Tempo médio de resposta: {formatNumber(payload[0].value, 2)}ms
        </Typography>
      </TooltipGraph>
    )
  }

  const handleDataShow = (data: IStatusGraph) => {
    const isAnyValueNonZero = data?.data?.some((item) => {
      const keys = Object.keys(item).filter((key) => key !== 'axisX')
      return keys.some((key) => item[key] !== 0)
    })

    if (data.series && isAnyValueNonZero) {
      setDataShow(data)
    } else {
      setDataShow({} as IStatusGraph)
    }
  }

  const handleDataDownload = (data: IStatusGraph) => {
    if (!data.series || !data.data) {
      setDataCSV([])
      return
    }

    const tmp: IAvarageCSV[] = data.series
      .filter(
        (serie: ISerieStatusGraph) => serie.name === 'Tempo médio de resposta',
      )
      .flatMap((serie: ISerieStatusGraph) =>
        data.data.map((item: IDataStatusGraph) => ({
          avarage: item[serie.key],
          date: formatDate(item.axisX),
        })),
      )

    setDataCSV(tmp)
  }

  useEffect(() => {
    handleDataShow(dataGraph)
    handleDataDownload(dataGraph)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Tempo médio de resposta (Y) na linha do tempo (X)"
        action={
          <>
            {!isLoading && !isError && dataShow.data && dataCSV.length > 0 ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="tempo-medio-resposta-tempo"
                    headers={headerCSV}
                    data={dataCSV}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow ||
          !dataShow.series ||
          !dataShow.data ||
          dataShow.data.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={dataShow.data}
                margin={{
                  left: 12,
                  right: 0,
                  top: 6,
                }}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  dataKey="axisX"
                  type="category"
                  tickLine={false}
                  interval="preserveStartEnd"
                  padding={{ left: 35, right: 35 }}
                  tickFormatter={(value) => `${formatDate(value)}`}
                />
                <YAxis
                  type="number"
                  tickLine={false}
                  tickMargin={12}
                  scale="sqrt"
                  tickFormatter={(value) =>
                    `${formatNumberTimeMsToScale(value)}`
                  }
                />
                {dataShow.series.map(
                  (option) =>
                    option.type === 'bar' && (
                      <Bar
                        key={option.key}
                        dataKey={option.key}
                        name={option.name}
                        fill={option.color}
                        opacity={
                          activeLegend !== ''
                            ? activeLegend === option.key
                              ? 1
                              : 0.3
                            : 1
                        }
                      />
                    ),
                )}
                <Legend
                  iconSize={18}
                  verticalAlign="bottom"
                  wrapperStyle={{ marginBottom: -12 }}
                  onMouseEnter={handleActiveLegend}
                  onMouseLeave={handleDisableLegend}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
