import { Box, CardContent, IconButton, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import {
  IDataStatusGraph,
  ISerieStatusGraph,
  IStatusCSV,
  IStatusGraph,
} from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import {
  formatToLocaleString,
  formatDate,
  formatNumberToScale,
  formatNumberTimeMsToScale,
  formatNumber,
} from '../../../utils'

interface DynamicStatusResponseRequestsGraphProps {
  dataGraph: IStatusGraph
  isLoading?: boolean
  isError?: boolean
  filterBy: string[]
}

const headerCSV = [
  { label: 'Status', key: 'status' },
  { label: 'Requisições', key: 'requests' },
  { label: 'Data', key: 'date' },
]

const WIDTH_MD = 899

export const DynamicStatusResponseRequestsGraph = ({
  dataGraph,
  isLoading,
  isError,
  filterBy,
}: DynamicStatusResponseRequestsGraphProps) => {
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const [dataShow, setDataShow] = useState<IStatusGraph>({} as IStatusGraph)
  const [activeLegend, setActiveLegend] = useState<string>('')
  const [dataCSV, setDataCSV] = useState<IStatusCSV[]>([])

  const handleActiveLegend = ({ dataKey }: any) => {
    setActiveLegend(dataKey)
  }

  const handleDisableLegend = () => {
    setActiveLegend('')
  }

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>{payload[0].payload.axisX}</Typography>

        {dataShow.series
          .filter(
            (serie: ISerieStatusGraph) =>
              serie.name !== 'Tempo médio de resposta',
          )
          .map((element: ISerieStatusGraph, index: number) => {
            return (
              <Box key={index}>
                <Typography key={index}>
                  {element.name.substr(0, 9)}:{' '}
                  {formatToLocaleString(payload[0].payload[element.key])}
                </Typography>
              </Box>
            )
          })}

        {dataShow.series
          .filter(
            (serie: ISerieStatusGraph) =>
              serie.name === 'Tempo médio de resposta',
          )
          .map((element: ISerieStatusGraph, index: number) => {
            return (
              <Box key={index}>
                <Typography>
                  {element.name}:{' '}
                  {formatNumber(payload[0].payload[element.key], 2)}ms
                </Typography>
              </Box>
            )
          })}
      </TooltipGraph>
    )
  }

  const handleDataShow = (data: IStatusGraph) => {
    const isAnyValueNonZero = data?.data?.some((item) => {
      const keys = Object.keys(item).filter((key) => key !== 'axisX')
      return keys.some((key) => item[key] !== 0)
    })

    if (data.series && isAnyValueNonZero) {
      setDataShow(data)
    } else {
      setDataShow({} as IStatusGraph)
    }
  }

  const handleDataDownload = (data: IStatusGraph) => {
    if (!data.series || !data.data) {
      setDataCSV([])
      return
    }

    const tmp: IStatusCSV[] = data.series
      .filter(
        (serie: ISerieStatusGraph) => serie.name !== 'Tempo médio de resposta',
      )
      .flatMap((serie: ISerieStatusGraph) =>
        data.data.map((item: IDataStatusGraph) => ({
          status: serie.name,
          requests: item[serie.key],
          date: formatDate(item.axisX),
        })),
      )

    setDataCSV(tmp)
  }

  const updateWindowDimensions = useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [setWindowWidth])

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth)

    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterDataByStatus = useCallback(
    (graph: IStatusGraph) => {
      const status = filterBy?.map((filter) => `axisY${Number(filter) + 1}`)
      const { data, series } = { ...graph }

      const _data = data?.map((data) => {
        return Object.fromEntries(
          Object.entries(data).filter(([key]) =>
            key !== 'axisX' && key !== 'axisY1' ? status?.includes(key) : true,
          ),
        )
      })

      const _series = series?.filter(
        ({ key, type }) => status?.includes(key) || type === 'bar',
      )

      return filterBy?.length > 0
        ? Object.assign(
            { ...graph },
            {
              data: _data,
              series: _series,
            },
          )
        : { ...graph }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [filterBy],
  )

  useEffect(() => {
    handleDataShow(filterDataByStatus(dataGraph))
    handleDataDownload(filterDataByStatus(dataGraph))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph, filterBy])

  return (
    <CardBox>
      <CardBoxTitle
        title="Quantidade de requisições reportadas (y1) na linha do tempo (x) mais tempo de resposta (y2)"
        action={
          <>
            {!isLoading && !isError && dataShow.data && dataCSV.length > 0 ? (
              <>
                <TooltipInfo
                  title="Exibe os totais de requisições por status code da requisição na linha do tempo e o tempo médio de resposta em milissegundos"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="info">
                    <InfoOutlined color="info" sx={{ opacity: 0.5 }} />
                  </IconButton>
                </TooltipInfo>
                <TooltipInfo
                  title="Fazer download do arquivo CSV"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="download">
                    <IconCsvDownloader
                      filename="status-requisicoes"
                      headers={headerCSV}
                      data={dataCSV}
                    />
                  </IconButton>
                </TooltipInfo>
              </>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow ||
          !dataShow.series ||
          !dataShow.data ||
          dataShow.data.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={dataShow.data}
                margin={{
                  left: windowWidth > WIDTH_MD ? 30 : 0,
                  right: windowWidth > WIDTH_MD ? 30 : 0,
                  top: 6,
                }}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  dataKey="axisX"
                  type="category"
                  tickLine={false}
                  interval="preserveStartEnd"
                  padding={{ left: 35, right: 35 }}
                />
                <YAxis
                  yAxisId="left"
                  type="number"
                  tickLine={false}
                  tickMargin={12}
                  scale="sqrt"
                  tickFormatter={(value) =>
                    `${formatNumberToScale(value, 1, 1000)}`
                  }
                >
                  <Label
                    value="Requisições"
                    position="insideLeft"
                    angle={-90}
                    offset={-25}
                    // eslint-disable-next-line react/forbid-component-props
                    style={{
                      textAnchor: 'middle',
                      fontWeight: 'bold',
                      fontSize: 12,
                      letterSpacing: '0.9px',
                      color: '#3C3C3C',
                      fill: '#3C3C3C',
                    }}
                  />
                </YAxis>
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  type="number"
                  tickLine={false}
                  tickMargin={12}
                  scale="sqrt"
                  tickFormatter={(value) =>
                    `${formatNumberTimeMsToScale(value)}`
                  }
                >
                  <Label
                    value="Tempo de resposta (ms)"
                    position="insideLeft"
                    angle={90}
                    offset={85}
                    // eslint-disable-next-line react/forbid-component-props
                    style={{
                      textAnchor: 'middle',
                      fontWeight: 'bold',
                      fontSize: 12,
                      letterSpacing: '0.9px',
                      color: '#3C3C3C',
                      fill: '#3C3C3C',
                    }}
                  />
                </YAxis>
                {dataShow.series.map((option) =>
                  option.type === 'line' ? (
                    <Line
                      key={option.key}
                      type="monotone"
                      yAxisId="left"
                      dataKey={option.key}
                      name={option.name}
                      stroke={option.color}
                      legendType="plainline"
                      dot={false}
                      isAnimationActive={false}
                      strokeOpacity={
                        activeLegend !== ''
                          ? activeLegend === option.key
                            ? 1
                            : 0.3
                          : 1
                      }
                      strokeWidth={
                        activeLegend !== ''
                          ? activeLegend === option.key
                            ? 3
                            : 1
                          : 2
                      }
                    />
                  ) : (
                    <Bar
                      key={option.key}
                      yAxisId="right"
                      dataKey={option.key}
                      name={option.name}
                      fill={option.color}
                      opacity={
                        activeLegend !== ''
                          ? activeLegend === option.key
                            ? 1
                            : 0.3
                          : 1
                      }
                    />
                  ),
                )}
                <Legend
                  iconSize={18}
                  verticalAlign="bottom"
                  wrapperStyle={{ marginBottom: -12 }}
                  onMouseEnter={handleActiveLegend}
                  onMouseLeave={handleDisableLegend}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
