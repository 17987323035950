import { Box, styled } from "@mui/material";

export const Legend = styled(Box)`
  align-self: flex-end;
  justify-content: center;
  margin: 0.5rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  & > span:first-of-type {
    display: flex;
    align-items: center;
  }
`;