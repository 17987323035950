import { Grid } from '@mui/material'
import { Image } from '../../../components'
import { Title, HiperLink } from './style'
import logoOpinWhite from 'assets/svg/logo_opin_white.svg'

export const LayoutFooter = () => {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        maxWidth: 'xl',
        marginTop: 8,
        marginBottom: 2,
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          paddingY: '0 !important',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Image
          src={logoOpinWhite}
          sx={{
            height: 35,
          }}
        />
      </Grid>
      <Grid item xs={12} md={1.5} sx={{ padding: '0px !important' }} />
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'right' },
          paddingTop: '20px !important',
        }}
      >
        <HiperLink
          href={process.env.REACT_APP_INSTITUCIONAL_URL}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          PORTAL CIDADÃO
        </HiperLink>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '20px !important',
        }}
      >
        <HiperLink
          href={`${process.env.REACT_APP_INSTITUCIONAL_URL}/participante/como-participar/modelo-de-participacao`}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          PORTAL PARTICIPANTE
        </HiperLink>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'left' },
          paddingTop: '20px !important',
        }}
      >
        <HiperLink
          href={process.env.REACT_APP_CONFLUENCE_URL}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          ÁREA DO DESENVOLVEDOR
        </HiperLink>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '48px !important',
        }}
      >
        <Title sx={{ paddingBottom: { xs: 2, md: 0 } }}>
          @ 2022 - Todos os Direitos Reservados Estrutura Inicial do Open
          Insurance
        </Title>
      </Grid>
    </Grid>
  )
}
