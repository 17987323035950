import format from 'date-fns/format'
import ptBr from 'date-fns/locale/pt-BR'
import parseISO from 'date-fns/parseISO'

export function formatDate(date, formats = 'dd/MM/yyyy', brDate = false) {
  const barsSize = date.split('/').length
  if (brDate || barsSize === 3) {
    date = date.split('/').reverse().join('-')
  }
  return format(parseISO(date), formats, { locale: ptBr })
}
