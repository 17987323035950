import { Link, LinkProps, Typography, TypographyProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: '#C9C9C9',
  fontSize: theme.typography.pxToRem(12),
  lineHeight: theme.typography.pxToRem(17),
  letterSpacing: '0.02px',
  marginTop: 2,
  fontFamily: 'GothamBook !important',
}))

export const HiperLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: theme.typography.pxToRem(16),
  lineHeight: theme.typography.pxToRem(16),
  letterSpacing: '0.02px',
  fontFamily: 'GothamBook !important',
  textAlign: 'center',
}))
