import axiosDashClient from '../libs/axiosDash'

export interface MirrorReportProps {
  lessThanThousand: number,
  betweenThousandAndTenThousand: number,
  betweenTenThousandAndHundredThousand: number,
  betweenHundredThousandAndMillion: number,
  betweenOneMillionAndTenMillion: number,
  biggerThenTenMillion: number,
  totalSumInvocations: number,
  betweenHundredAndThousand: number,
  betweenOneAndTen: number,
  betweenTenAndHundred: number,
  biggerThenHundredThousand: number,
  lessThanOne: number,
  totalSumErrors: number,
  lessThanFiveHundred: number,
  betweenFiveHundredAndSixHundred: number,
  betweenSixHundredAndSevenHundred: number,
  betweenSevenHundredAndEightHundred: number,
  betweenEightHundredAndNineHundred: number,
  betweenNineHundredAndThousand: number,
  greaterThanThousand: number,
  averageTotalResponse: string,
}

export interface AvailabilityProps {
  percent: number
  quantity: string
}

export interface MirrorReportByOrgProps {
  serverId: string,
  serverName: string,
  errors?: number,
  rejections?: number,
  invocations?: number,
  response?: number,
  uptime?: AvailabilityProps,
  outage?: AvailabilityProps,
  upTime?: AvailabilityProps,
}

export interface SubmitParams {
  firstDate?: string
  lastDate?: string
  parentTransmitters?: string[]
  transmitters?: string[]
  type?: 'performanceMetrics' |
  'averageAvailability' |
  'averageAvailabilityByServer' |
  'successfulCalls' |
  'successfulCallsByServer' |
  'errorsCalls' |
  'errorsCallsByServer' |
  'totalRejected' |
  'rejectedByServer' |
  'averageResponse' |
  'averageResponseByServer' |
  'highPriority' |
  'highPriorityByServer' |
  'mediumPriority' |
  'mediumPriorityByServer'
}

export const getMirrorReport = async ({ firstDate, lastDate, parentTransmitters, transmitters, type }: SubmitParams): Promise<MirrorReportProps> => {
  try {
    if (!firstDate || !lastDate || !type) return {} as MirrorReportProps

    const parentServers = parentTransmitters && parentTransmitters.length > 0 ? parentTransmitters.join(',') : '';
    const childServers = transmitters && transmitters.length > 0 ? transmitters.join(',') : '';
    const servers = childServers || parentServers;

    const { data } = await axiosDashClient.get(`/mirrorReport?firstDate=${firstDate}&lastDate=${lastDate}&servers=${servers}&type=${type}`)

    return data
  } catch (error) {
    return {} as MirrorReportProps
  }
}

