import { useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ViewInfoCard } from '../../../components'
import { IClientSummary, IFilter } from '../../../domain'
import { formatToLocaleString } from '../../../utils/parseNumber'
import { UserContext } from '../../../context/user'
import { ReportService } from '../../../services'

interface InfoGraphsProps {
  activatedFilters: IFilter
}

export const DynamicPrivateFunnelInfoGraphs = ({
  activatedFilters,
}: InfoGraphsProps) => {
  const { loading, updateLoading } = useContext(UserContext)
  const [lastFilter, setLastFilter] = useState<{
    filter: IFilter
    time: number
  }>({
    filter: activatedFilters,
    time: new Date().getTime(),
  })

  const [clientSummaryData, setClientSummaryData] = useState<IClientSummary>(
    {} as IClientSummary,
  )

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    ReportService.getClientSummary(lastFilter.filter, signal)
      .then((data: IClientSummary) => {
        setClientSummaryData(data)
        updateLoading({ name: 'clientSummary', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setClientSummaryData({} as IClientSummary)
          updateLoading({ name: 'clientSummary', loading: false })
        }
      })

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFilter])

  useEffect(() => {
    const now = new Date().getTime()
    if (
      activatedFilters.interval &&
      activatedFilters.interval[0] &&
      (JSON.stringify(activatedFilters) !==
        JSON.stringify(lastFilter?.filter) ||
        Math.abs(lastFilter.time - now) > 500)
    ) {
      setLastFilter({
        filter: activatedFilters,
        time: now,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedFilters])

  return (
    <>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="error"
          title="Consentimentos com Falhas na Autenticação"
          infoTooltip="Exibe a quantidade de falhas na autenticação do usuário na participante transmissora"
          result={formatToLocaleString(
            clientSummaryData.totalConsentAuthFailed,
          )}
          isLoading={loading.clientSummary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="error"
          title="Consentimentos Revogados"
          infoTooltip="Exibe a quantidade consentimentos cancelados na sociedade transmissora, após sua conclusão"
          result={formatToLocaleString(clientSummaryData.totalConsentRevoked)}
          isLoading={loading.clientSummary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="error"
          title="Consentimentos Rejeitados"
          infoTooltip="Exibe a quantidade de consentimentos rejeitados durante a jornada de consentimento"
          result={formatToLocaleString(clientSummaryData.totalConsentRejected)}
          isLoading={loading.clientSummary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="info"
          title="Consentimentos Iniciados"
          infoTooltip="Exibe a quantidade total de jornadas de consentimentos iniciadas na participante transmissora"
          result={formatToLocaleString(clientSummaryData.totalConsentCreated)}
          isLoading={loading.clientSummary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="success"
          title="Consentimentos Concluídos"
          infoTooltip="Exibe a quantidade total de consentimentos concluídos e recebidos pela sociedade receptora"
          result={formatToLocaleString(clientSummaryData.totalConsentReceived)}
          isLoading={loading.clientSummary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="success"
          title="Consentimentos Finalizados"
          infoTooltip="Exibe a porcentagem de jornadas de consentimentos concluídos com sucesso"
          result={formatToLocaleString(
            clientSummaryData.percentFinishedConsent,
          )}
          resultType="%"
          isLoading={loading.clientSummary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="info"
          title="Consentimentos com Refresh Token"
          infoTooltip="Exibe a quantidade total de requisições reportadas pelas Sociedades Receptoras e Transmissoras ao Refresh Token"
          result={formatToLocaleString(
            clientSummaryData.totalConsentRefreshTokenUsed,
          )}
          isLoading={loading.clientSummary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="info"
          title="Consentimentos com Recurso Acessado"
          infoTooltip="Exibe a quantidade total de requisições de acesso reportadas pelas Sociedades Receptoras e Transmissoras ao Recurso"
          result={formatToLocaleString(
            clientSummaryData.totalConsentResourceAccessed,
          )}
          isLoading={loading.clientSummary}
        />
      </Grid>
    </>
  )
}
