import { useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ViewInfoCard } from '../../../components'
import { IClientSummary, IFilter, ISummary } from '../../../domain'
import { formatToLocaleString } from '../../../utils/parseNumber'
import { UserContext } from '../../../context/user'
import { ReportService } from '../../../services'

interface InfoGraphsProps {
  activatedFilters: IFilter
}

export const DynamicPrivateInfoGraphs = ({
  activatedFilters,
}: InfoGraphsProps) => {
  const { loading, updateLoading } = useContext(UserContext)
  const [lastFilter, setLastFilter] = useState<{
    filter: IFilter
    time: number
  }>({
    filter: activatedFilters,
    time: new Date().getTime(),
  })

  const [summaryData, setSummaryData] = useState<ISummary>({} as ISummary)
  const [clientSummaryData, setClientSummaryData] = useState<IClientSummary>(
    {} as IClientSummary,
  )

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    ReportService.getSummary(lastFilter.filter, signal)
      .then((data: ISummary) => {
        setSummaryData(data)
        updateLoading({ name: 'summary', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setSummaryData({} as ISummary)
          updateLoading({ name: 'summary', loading: false })
        }
      })

    ReportService.getClientSummary(lastFilter.filter, signal)
      .then((data: IClientSummary) => {
        setClientSummaryData(data)
        updateLoading({ name: 'clientSummary', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setClientSummaryData({} as IClientSummary)
          updateLoading({ name: 'clientSummary', loading: false })
        }
      })

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFilter])

  useEffect(() => {
    const now = new Date().getTime()
    if (
      activatedFilters.interval &&
      activatedFilters.interval[0] &&
      (JSON.stringify(activatedFilters) !==
        JSON.stringify(lastFilter?.filter) ||
        Math.abs(lastFilter.time - now) > 500)
    ) {
      setLastFilter({
        filter: activatedFilters,
        time: now,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedFilters])

  return (
    <>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="info"
          title="Total de Requisições Transacionadas"
          infoTooltip="Exibe a quantidade total de requisições transacionadas entre as sociedades e reportadas ao PCM"
          result={formatToLocaleString(summaryData.requestTotalQtt)}
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="info"
          title="Total de Requisições Recepção"
          infoTooltip="Exibe a quantidade total de requisições realizadas pelas sociedades receptoras e reportadas ao PCM"
          result={formatToLocaleString(summaryData.requestTotalQttClient)}
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="info"
          title="Total de Respostas das Requisições Transmissão"
          infoTooltip="Exibe a quantidade total de requisições respondidas pelas sociedades transmissoras e reportadas ao PCM"
          result={formatToLocaleString(summaryData.requestTotalQttServer)}
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="success"
          title="Requisições com Sucesso"
          result={formatToLocaleString(
            summaryData.successfulRequestsTotalPercentil,
          )}
          resultType="%"
          infoTooltip="Exibe a porcentagem de Requisições transacionadas com sucesso (Status Code 200-299)"
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="error"
          title="Requisições sem Sucesso"
          result={formatToLocaleString(
            summaryData.unsuccessfulRequestsTotalPercentil,
          )}
          resultType="%"
          infoTooltip="Exibe a porcentagem de Requisições transacionadas com falha (sem sucesso) (Status Code ≠ 400-599)"
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="info"
          title="Tempo médio de resposta"
          result={formatToLocaleString(summaryData.requestsAvg)}
          resultType="ms"
          infoTooltip="Exibe o tempo médio das requisições transacionadas e reportadas ao PCM"
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="success"
          title="Abaixo do tempo limite de resposta"
          result={formatToLocaleString(summaryData.requestsLteAvgPercentil)}
          resultType="%"
          infoTooltip="Exibe a porcentagem de requisições com tempo de resposta menor ou igual ao tempo limite, conforme sua prioridade: 1.000ms para alta prioridade, 1.500ms para média prioridade e 4.000ms para baixa prioridade"
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="error"
          title="Acima do tempo limite de resposta"
          result={formatToLocaleString(summaryData.requestsGtAvgPercentil)}
          resultType="%"
          infoTooltip="Exibe a porcentagem de requisições com tempo de resposta maior que o tempo limite, conforme sua prioridade: 1.000ms para alta prioridade, 1.500ms para média prioridade e 4.000ms para baixa prioridade"
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type={
            summaryData.availability24hours &&
            summaryData.availability24hours > 85
              ? 'success'
              : 'error'
          }
          title="Disponibilidade últimas 24 horas"
          result={formatToLocaleString(summaryData.availability24hours)}
          resultType="%"
          infoTooltip="Exibe a disponibilidade dos endpoints selecionados para as sociedades participantes, nas últimas 24 horas. A disponibilidade mínima exigida para esse intervalo é 85%"
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type={
            summaryData.availabilityOneMonth &&
            summaryData.availabilityOneMonth > 95
              ? 'success'
              : 'error'
          }
          title="Disponibilidade último mês"
          result={formatToLocaleString(summaryData.availabilityOneMonth)}
          resultType="%"
          infoTooltip="Exibe a disponibilidade dos endpoints selecionados para as sociedades participantes, no último mês. A disponibilidade mínima exigida para esse intervalo é 95%"
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type={
            summaryData.availabilityThreeMonths &&
            summaryData.availabilityThreeMonths > 99.5
              ? 'success'
              : 'error'
          }
          title="Disponibilidade últimos 3 meses"
          result={formatToLocaleString(summaryData.availabilityThreeMonths)}
          resultType="%"
          infoTooltip="Exibe a disponibilidade dos endpoints selecionados para as sociedades participantes, nos últimos três meses. A disponibilidade mínima exigida para esse intervalo é de 99,5%"
          isLoading={loading.summary}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <ViewInfoCard
          type="info"
          title="Total de Requisições ao endpoint de Consentimento"
          infoTooltip="Exibe o total de requisições ao endpoint de consentis/v1 e consentis/v2"
          result={formatToLocaleString(clientSummaryData.totalConsents)}
          isLoading={loading.clientSummary}
        />
      </Grid>
    </>
  )
}
