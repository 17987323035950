import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  AverageAvailabilityPageOne,
  AverageAvailabilityPageTwo,
} from './graphs'
import {
  firstChartOrderDefault,
  graphListHelper,
  makeOptions,
  percentageDataToOrderBy,
  secondChartOrderDefault,
} from './helpers'
import { GraphContainer } from './styles'
import { AverageAvailabilityProps } from './types'
import {
  CardBox,
  CardBoxSubTitle,
  CardBoxTitle,
  IconCsvDownloader,
  SingleAutocomplete,
} from '../../../components'
import {
  getMirrorReport,
  MirrorReportByOrgProps,
  SubmitParams,
} from '../../../services'
import { parseNumberToFixedTwo } from '../../../utils'
import { RangeOptions, SelectDefaultValue } from '../../../domain'

interface pageOneDataProps extends RangeOptions {}

interface pageTwoDataProps extends MirrorReportByOrgProps {}

export const AggregateApiAverageAvailabilityGraph = ({
  filter,
}: AverageAvailabilityProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isError, setIsError] = useState<Boolean>(false)
  const [chartOne, setChartOne] = useState<pageOneDataProps[]>(
    percentageDataToOrderBy,
  )
  const [chartTwo, setChartTwo] = useState<pageTwoDataProps[]>()
  const [currentKey, setCurrentKey] = useState<number>(0)
  const [firstChartOrder, setFirstChartOrder] = useState<SelectDefaultValue>(
    firstChartOrderDefault(),
  )
  const [secondChartOrder, setSecondChartOrder] = useState<SelectDefaultValue>(
    secondChartOrderDefault(),
  )
  const [loading, setLoading] = useState(false)
  const [selectOptions, setSelectOptions] = useState<SelectDefaultValue[]>([])

  useEffect(() => {
    makeChartData[currentKey]()
    setSelectOptions(makeOptions(currentKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKey, filter])

  const handleTabs = (e: ChangeEvent<{}>, newKey: number): void => {
    setCurrentKey(newKey)
  }

  const makeChartData: { [index: number]: () => void } = {
    0: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'averageAvailability',
          },
          (data) => {
            if (data) {
              setChartOne(
                percentageDataToOrderBy.map((item) => ({
                  ...item,
                  value: data[item.key],
                })),
              )

              if (
                Object.keys(data).length === 0 ||
                Object.values(data).every((value) => value === 0)
              ) {
                setIsError(true)
              } else {
                setIsError(false)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
    1: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'averageAvailabilityByServer',
          },
          (data) => {
            if (data) {
              setChartTwo(
                data.map((org: any) => ({
                  serverName: org.serverName,
                  uptime: org.upTime.percent || 0,
                })),
              )
              setIsError(false)
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
  }

  const getChartData = useCallback(
    (dateData: SubmitParams, setChart: (data: any) => void): void => {
      setLoading(true)
      getMirrorReport({ ...dateData })
        .then(
          (data: any) => {
            setChart(data)
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        )
        .catch(() => {
          setLoading(false)
        })
    },
    [],
  )

  const getDataToExportCSV = (): any => {
    if (chartOne && chartOne.length > 0 && currentKey === 0) {
      return chartOne.map(({ range, value }: pageOneDataProps) => ({
        intervalo: range.replace(/,/g, '.'),
        valor: value,
      }))
    }
    if (chartTwo && chartTwo.length > 0 && currentKey === 1) {
      return chartTwo.map(({ serverName, uptime }: pageTwoDataProps) => ({
        instituicao: serverName,
        valor: uptime ? parseNumberToFixedTwo(uptime.toString()) : 0,
      }))
    }
    return []
  }

  const headerCSV: any = {
    0: [
      {
        label: 'Intervalo',
        key: 'intervalo',
      },
      {
        label: 'Valor',
        key: 'valor',
      },
    ],
    1: [
      {
        label: 'Sociedade',
        key: 'instituicao',
      },
      {
        label: 'Valor',
        key: 'valor',
      },
    ],
  }

  const handleSelectChange = (value: SelectDefaultValue) => {
    switch (currentKey) {
      case 0:
        setFirstChartOrder(value)
        break
      case 1:
        setSecondChartOrder(value)
        break
    }
  }

  return (
    <Grid item xs={12}>
      <CardBox>
        <CardBoxTitle
          title="Disponibilidade Média das APIs"
          action={
            <>
              {!isError && !loading ? (
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="disponibilidade-media-api"
                    headers={headerCSV[currentKey]}
                    data={getDataToExportCSV()}
                  />
                </IconButton>
              ) : (
                ''
              )}
            </>
          }
        />
        <CardBoxSubTitle title="Demonstração da disponibilidade média das APIs de sociedades participantes" />

        <Grid
          container
          sx={{
            padding: 2,
            paddingTop: 0,
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <SingleAutocomplete
              id="orderBy"
              label="Classifique por"
              options={selectOptions}
              value={currentKey === 0 ? firstChartOrder : secondChartOrder}
              loading={loading}
              onSelect={handleSelectChange}
            />
          </Grid>
        </Grid>

        <CardContent sx={{ position: 'relative' }}>
          <GraphContainer>
            <Tabs
              orientation="vertical"
              variant="standard"
              value={currentKey}
              onChange={handleTabs}
              sx={{ width: 300, minWidth: 300 }}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              {graphListHelper.map((item) => (
                <Tab
                  key={`${item.title}-${item.key}`}
                  label={item.title}
                  wrapped
                  value={item.key}
                  sx={{
                    fontSize: 12,
                    '&.Mui-selected': {
                      fontWeight: 'bold',
                    },
                  }}
                />
              ))}
            </Tabs>

            {currentKey === 0 && (
              <AverageAvailabilityPageOne
                data={chartOne}
                chartOrder={firstChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 1 && (
              <AverageAvailabilityPageTwo
                data={chartTwo}
                chartOrder={secondChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
          </GraphContainer>
        </CardContent>

        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {currentKey === 0 ? (
            <Typography fontSize={12}>
              Quantidade de Sociedades participantes
            </Typography>
          ) : (
            <Typography fontSize={12}>
              Apenas sociedades que reportaram Disponibilidade Média das APIs no
              período selecionado são exibidas.
            </Typography>
          )}
        </CardActions>
      </CardBox>
    </Grid>
  )
}
