import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  maxWidth: '100%',
  minWidth: '150px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

export const BoxNav = styled(Box)<BoxProps>(({ theme }) => ({
  minHeight: '64px',
  backgroundColor: '#FFFFFF',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    maxWidth: '300px',
    flexDirection: 'column',
    borderRight: '1px solid #e2e2e2',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    maxWidth: '100%',
  },
}))

export const BoxHeader = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  background: '#ffffff',
  borderBottom: 'solid 1px #e2e2e2',
  height: '70px',
  position: 'fixed',
  zIndex: 0,
  paddingInline: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingInline: theme.spacing(2),
  },
}))

export const Image = styled('img')({})

export const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '100%',
  width: 'calc(100% - 236px)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const BoxFooter = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  background: '#6E6E6E',
  paddingInline: theme.spacing(4),
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingInline: theme.spacing(2),
  },
}))
