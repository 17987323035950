import axiosDashClient from '../libs/axiosDash'

interface HistoryChangeData {
  id: number
  amountRecordsChanged: number
  dateChange: string
  finishDateChange: string
  startDateChange: string
  organisationName: string
  organisationId: string
}

interface HistoryChangeProps {
  hasMore: boolean
  total: number
  data: HistoryChangeData[]
}

export const getHistoryChange = async (
  page: number,
  limit: number,
): Promise<HistoryChangeProps> => {
  try {
    const { data } = await axiosDashClient.get('/historicChanges', {
      params: {
        page,
        limit,
      },
    })
    return data
  } catch (error) {
    console.error('Error fetching history changes:', error)
    return {} as HistoryChangeProps
  }
}
