import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  errorsOrgsOrderDefault,
  errorsPercentageDataToOrderBy,
  errorsPercentageOrderDefault,
  graphListHelper,
  makeOptions,
  orgsOrderDefault,
  percentageDataToOrderBy,
  percentageOrderDefault,
} from './helpers'
import {
  RejectedCallsPageOne,
  RejectedCallsPageTwo,
  ErrorsCallsPageThree,
  ErrorsCallsPageFour,
} from './graphs'
import { GraphContainer } from './styles'
import { RejectedCallsProps } from './types'
import {
  CardBox,
  CardBoxSubTitle,
  CardBoxTitle,
  IconCsvDownloader,
  SingleAutocomplete,
} from '../../../components'
import {
  getMirrorReport,
  MirrorReportByOrgProps,
  SubmitParams,
} from '../../../services'
import { RangeOptions, SelectDefaultValue } from '../../../domain'

interface pageOneDataProps extends RangeOptions {}

interface pageTwoDataProps extends MirrorReportByOrgProps {}

interface pageThreeDataProps extends RangeOptions {}

interface pageFourthDataProps extends MirrorReportByOrgProps {}

export const AggregateRejectedCallsGraph = ({ filter }: RejectedCallsProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isError, setIsError] = useState<Boolean>(false)
  const [pageOneData, setPageOneData] = useState<pageOneDataProps[]>(
    percentageDataToOrderBy,
  )
  const [pageTwoData, setPageTwoData] = useState<pageTwoDataProps[]>()
  const [pageThreeData, setPageThreeData] = useState<pageThreeDataProps[]>(
    errorsPercentageDataToOrderBy,
  )
  const [pageFourData, setPageFourData] = useState<pageFourthDataProps[]>()
  const [currentKey, setCurrentKey] = useState<number>(0)
  const [firstChartOrder, setFirstChartOrder] = useState<SelectDefaultValue>(
    percentageOrderDefault(),
  )
  const [secondChartOrder, setSecondChartOrder] = useState<SelectDefaultValue>(
    orgsOrderDefault(),
  )
  const [thirdChartOrder, setThirdChartOrder] = useState<SelectDefaultValue>(
    errorsPercentageOrderDefault(),
  )
  const [fourthChartOrder, setFourthChartOrder] = useState<SelectDefaultValue>(
    errorsOrgsOrderDefault(),
  )
  const [loading, setLoading] = useState(false)
  const [selectOptions, setSelectOptions] = useState<SelectDefaultValue[]>([])

  useEffect(() => {
    makeChartData[currentKey]()
    setSelectOptions(makeOptions(currentKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKey, filter])

  const handleTabs = (e: ChangeEvent<{}>, newKey: number): void => {
    setCurrentKey(newKey)
  }

  const makeChartData: { [index: number]: () => void } = {
    0: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'totalRejected',
          },
          (data) => {
            if (data) {
              if (
                data.totalSumRejections === 0 ||
                data.totalSumRejections === undefined
              ) {
                setPageOneData(
                  percentageDataToOrderBy.map((item) => ({
                    ...item,
                    value: 0,
                  })),
                )
                setIsError(true)
              } else {
                setPageOneData(
                  percentageDataToOrderBy.map((item) => ({
                    ...item,
                    value: data[item.key],
                  })),
                )
                setIsError(false)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
    1: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'rejectedByServer',
          },
          (data) => {
            if (data) {
              setPageTwoData(data)

              if (data.filter((item: any) => item.rejections > 0).length > 0) {
                setIsError(false)
              } else {
                setIsError(true)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
    2: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'errorsCalls',
          },
          (data) => {
            if (data) {
              if (
                data.totalSumErrors === 0 ||
                data.totalSumErrors === undefined
              ) {
                setPageThreeData(
                  errorsPercentageDataToOrderBy.map((item) => ({
                    ...item,
                    value: 0,
                  })),
                )
                setIsError(true)
              } else {
                setPageThreeData(
                  errorsPercentageDataToOrderBy.map((item) => ({
                    ...item,
                    value: data[item.key],
                  })),
                )
                setIsError(false)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
    3: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'errorsCallsByServer',
          },
          (data) => {
            if (data) {
              setPageFourData(data)

              if (data.filter((item: any) => item.errors > 0).length > 0) {
                setIsError(false)
              } else {
                setIsError(true)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
  }

  const handleSelectChange = (value: SelectDefaultValue) => {
    switch (currentKey) {
      case 0:
        setFirstChartOrder(value)
        break
      case 1:
        setSecondChartOrder(value)
        break
      case 2:
        setThirdChartOrder(value)
        break
      case 3:
        setFourthChartOrder(value)
        break
    }
  }

  const getChartData = useCallback(
    (dateData: SubmitParams, setChart: (data: any) => void): void => {
      setLoading(true)
      getMirrorReport({ ...dateData })
        .then(
          (data: any) => {
            setChart(data)
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        )
        .catch(() => {
          setLoading(false)
        })
    },
    [],
  )

  const getDataToExportCSV = (): any => {
    if (pageOneData && pageOneData.length > 0 && currentKey === 0) {
      return pageOneData.map(({ range, value }: any): any => ({
        intervalo: range,
        valor: value,
      }))
    }
    if (pageTwoData && pageTwoData.length > 0 && currentKey === 1) {
      return pageTwoData.map(({ serverName, rejections }: any): any => ({
        instituicao: serverName,
        valor: rejections,
      }))
    }
    if (pageThreeData && pageThreeData.length > 0 && currentKey === 2) {
      return pageThreeData.map(({ range, value }: any): any => ({
        intervalo: range,
        valor: value,
      }))
    }
    if (pageFourData && pageFourData.length > 0 && currentKey === 3) {
      return pageFourData.map(({ serverName, errors }: any): any => ({
        instituicao: serverName,
        valor: errors,
      }))
    }
    return []
  }

  const headerCSV: any = {
    0: [
      {
        label: 'Intervalo',
        key: 'intervalo',
      },
      {
        label: 'Valor',
        key: 'valor',
      },
    ],
    1: [
      {
        label: 'Sociedade',
        key: 'instituicao',
      },
      {
        label: 'Valor',
        key: 'valor',
      },
    ],
    2: [
      {
        label: 'Intervalo',
        key: 'intervalo',
      },
      {
        label: 'Valor',
        key: 'valor',
      },
    ],
    3: [
      {
        label: 'Sociedade',
        key: 'instituicao',
      },
      {
        label: 'Valor',
        key: 'valor',
      },
    ],
  }

  return (
    <Grid item xs={12}>
      <CardBox>
        <CardBoxTitle
          title="Chamadas Rejeitadas das APIs"
          action={
            <>
              {!isError && !loading ? (
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename={
                      currentKey === 0 || currentKey === 1
                        ? 'chamadas-rejeitadas-api'
                        : 'chamadas-fracassadas-api'
                    }
                    headers={headerCSV[currentKey]}
                    data={getDataToExportCSV()}
                  />
                </IconButton>
              ) : (
                ''
              )}
            </>
          }
        />
        <CardBoxSubTitle
          title={
            currentKey <= 1
              ? 'Demonstração do número de chamadas rejeitadas das APIs de sociedades participantes'
              : 'Demonstração do número de chamadas fracassadas das APIs de sociedades participantes'
          }
        />

        <Grid
          container
          sx={{
            padding: 2,
            paddingTop: 0,
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <SingleAutocomplete
              id="orderBy"
              label="Classifique por"
              options={selectOptions}
              value={
                currentKey === 0
                  ? firstChartOrder
                  : currentKey === 1
                  ? secondChartOrder
                  : currentKey === 2
                  ? thirdChartOrder
                  : fourthChartOrder
              }
              loading={loading}
              onSelect={handleSelectChange}
            />
          </Grid>
        </Grid>

        <CardContent sx={{ position: 'relative' }}>
          <GraphContainer>
            <Tabs
              orientation="vertical"
              variant="standard"
              value={currentKey}
              onChange={handleTabs}
              sx={{ width: 300, minWidth: 300 }}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              {graphListHelper.map((item) => (
                <Tab
                  key={`${item.title}-${item.key}`}
                  label={item.title}
                  wrapped
                  value={item.key}
                  sx={{
                    fontSize: 12,
                    '&.Mui-selected': {
                      fontWeight: 'bold',
                    },
                  }}
                />
              ))}
            </Tabs>

            {currentKey === 0 && (
              <RejectedCallsPageOne
                data={pageOneData}
                chartOrder={firstChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 1 && (
              <RejectedCallsPageTwo
                data={pageTwoData}
                chartOrder={secondChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 2 && (
              <ErrorsCallsPageThree
                data={pageThreeData}
                chartOrder={thirdChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 3 && (
              <ErrorsCallsPageFour
                data={pageFourData}
                chartOrder={fourthChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
          </GraphContainer>
        </CardContent>

        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {currentKey === 0 || currentKey === 2 ? (
            <Typography fontSize={12}>
              Quantidade de sociedades participantes
            </Typography>
          ) : (
            <Typography fontSize={12}>
              Apenas sociedades que reportaram Chamadas Rejeitadas das APIs no
              período selecionado são exibidas.
            </Typography>
          )}
        </CardActions>
      </CardBox>
    </Grid>
  )
}
