import {
  IFilter,
  ICallsServiceDeskGraph,
  IAvarageCallsServiceDeskGraph,
  IStatusCallsServiceDeskGraph,
  IAvailabilityPortalGraph,
  IOrgsDirectoryGraph,
} from '../domain'
import axiosDashClient from '../libs/axiosDash'

interface IParams {
  dates?: string | number
  type?: number
  count?: boolean
}

export class InfrastructureService {
  static async getCallsChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<ICallsServiceDeskGraph> {
    const optionParams: IParams = {
      dates: `${new Date(filters.interval[0]).getTime()},${new Date(
        filters.interval[1],
      ).getTime()}`,
      type: 1,
    }

    return axiosDashClient
      .get('/sisAidGraphics', {
        params: optionParams,
        signal,
      })
      .then((response) => response.data)
  }

  static async getReasonCallsChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<ICallsServiceDeskGraph> {
    const optionParams: IParams = {
      dates: `${new Date(filters.interval[0]).getTime()},${new Date(
        filters.interval[1],
      ).getTime()}`,
      type: 6,
    }

    return axiosDashClient
      .get('/sisAidGraphics', {
        params: optionParams,
        signal,
      })
      .then((response) => response.data)
  }

  static async getAvarageCallsChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IAvarageCallsServiceDeskGraph[]> {
    const optionParams: IParams = {
      dates: `${new Date(filters.interval[0]).getTime()},${new Date(
        filters.interval[1],
      ).getTime()}`,
      type: 3,
    }

    return axiosDashClient
      .get('/sisAidGraphics', {
        params: optionParams,
        signal,
      })
      .then((response) => response.data)
  }

  static async getStatusCallsChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IStatusCallsServiceDeskGraph> {
    const optionParams: IParams = {
      dates: `${new Date(filters.interval[0]).getTime()},${new Date(
        filters.interval[1],
      ).getTime()}`,
      type: 4,
    }

    return axiosDashClient
      .get('/sisAidGraphics', {
        params: optionParams,
        signal,
      })
      .then((response) => response.data)
  }

  static async getAvailabilityPortalChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IAvailabilityPortalGraph> {
    const optionParams: IParams = {
      dates: `${new Date(filters.interval[0]).getTime()},${new Date(
        filters.interval[1],
      ).getTime()}`,
      type: 5,
    }
    return axiosDashClient
      .get('/sisAidGraphics', {
        params: optionParams,
        signal,
      })
      .then((response) => response.data)
  }

  static async getOrgsDirectory(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IOrgsDirectoryGraph> {
    const optionParams: IParams = {
      count: true,
    }
    return axiosDashClient
      .get('/participants', {
        params: optionParams,
        signal,
      })
      .then((response) => response.data)
  }
}
