import { Box, styled } from "@mui/material"

interface ContainerProps {
  mobile: boolean
}
export const Container = styled(Box) <ContainerProps>`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  overflow-x: ${({ mobile }) => mobile ? 'scroll' : 'hidden'};
  flex: 3;

  position: relative;
`
