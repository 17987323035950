import { Box, styled } from "@mui/material"

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  flex: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ChartContainer = styled(Box)`
  width: 100%;
  max-height: 380px;
  overflow-y: scroll;
  flex: 3;

  position: relative;
`
