import { parseNumberToLocale } from '../utils/parseNumber'
import { formatToLocaleString } from './parseNumber'

export const numberDecimalFormat = (num: any, digits: number): string => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  // if (num < 1E6) {
  //   return formatToLocaleString(num)
  // }
  const number = (num / si[i].value).toFixed(digits).replace(rx, '$1')

  return formatToLocaleString(number, digits) + si[i].symbol
}

export const numberDecimalFormatWithoutSymbol = (num: any, digits: number): string => {
  const si = [
    { value: 1 },
    { value: 1E3 },
    { value: 1E6 },
    { value: 1E9 },
    { value: 1E12 },
    { value: 1E15 },
    { value: 1E18 }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return parseNumberToLocale((num / si[i].value).toFixed(digits).replace(rx, '$1'))
}

export const numberDecimalFormatOnlyK = (num: any, digits: number): string => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return parseNumberToLocale((num / si[i].value).toFixed(digits).replace(rx, '$1')) + si[i].symbol
}

export const numberDecimalFormatToHistorySuccessGraph = (num: any, digits: number): string => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }

  let formattedNumber

  if (num < 1E6) {
    formattedNumber = (num / 1E6).toFixed(digits).replace(rx, '$1')
  } else {
    formattedNumber = (num / si[i].value).toFixed(digits).replace(rx, '$1')
  }

  return `${formatToLocaleString(formattedNumber, digits)}${si[i].symbol}`
}
