import _, { isArray } from 'lodash'
import {
  Bar,
  ComposedChart,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Typography, useTheme } from '@mui/material'
import { Container } from './styles'
import {
  checkInstitutionsListGraphsStatus,
  numberDecimalFormatOnlyK,
} from '../../../../../utils'
import {
  TooltipGraph,
  ViewDataLoading,
  ViewDataError,
} from '../../../../../components'
import { ICustomTooltip, SelectDefaultValue } from '../../../../../domain'

const PercentLabel = ({ x, y, width, value }: LabelProps) => {
  const _value = numberDecimalFormatOnlyK(value, 0)
  const _plus =
    _value.length === 2
      ? 20
      : _value.length === 3
      ? 25
      : _value.length === 4
      ? 30
      : _value.length === 5
      ? 35
      : _value.length === 6
      ? 40
      : 15
  const _x = Number(x) + Number(width) + _plus
  const _y = Number(y) + 11

  return (
    <g>
      <text
        x={_x}
        y={_y}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {_value}
      </text>
    </g>
  )
}

const CustomTooltip = ({ active, payload }: ICustomTooltip) => {
  if (active && payload && payload.length) {
    return (
      <TooltipGraph>
        <Typography>Sociedade: {payload[0].payload.serverName}</Typography>
        <Typography>
          Total chamadas: {numberDecimalFormatOnlyK(payload[0].value, 0)}
        </Typography>
      </TooltipGraph>
    )
  }
  return null
}

interface SuccessfulCallsPageTwoProps {
  data: any
  chartOrder: SelectDefaultValue
  mobile: boolean
  loading: boolean
}
export const SuccessfulCallsPageTwo = ({
  data,
  chartOrder,
  mobile,
  loading,
}: SuccessfulCallsPageTwoProps) => {
  const theme = useTheme()
  const filteredData = isArray(data)
    ? data?.filter((item: any) => item.invocations > 0)
    : []
  const status = checkInstitutionsListGraphsStatus(data, loading, filteredData)

  return (
    <>
      {loading && <ViewDataLoading />}

      {!loading && (!data || data.length === 0 || status === 'NODATA') && (
        <ViewDataError />
      )}

      {status === 'HASDATA' && (
        <Container mobile={mobile} sx={{ maxHeight: 380 }}>
          <ResponsiveContainer
            minWidth={500}
            minHeight={data.length > 4 ? data.length * 50 : 280}
          >
            <ComposedChart
              layout="vertical"
              data={_.orderBy(
                filteredData,
                [chartOrder.value.by],
                [chartOrder.value.type],
              )}
            >
              <XAxis
                type="number"
                hide={true}
                domain={[0, (dataMax: number) => dataMax * 1.5]}
                scale="sqrt"
              />
              <YAxis
                dataKey="serverName"
                type="category"
                width={200}
                tickLine={false}
                interval={0}
                fontSize={12}
              />
              <Bar
                isAnimationActive={false}
                dataKey="invocations"
                fill={theme.palette.primary.light}
                barSize={20}
              >
                <LabelList
                  dataKey="invocations"
                  position="right"
                  content={PercentLabel}
                />
              </Bar>
              {!mobile && <Tooltip content={<CustomTooltip />} />}
            </ComposedChart>
          </ResponsiveContainer>
        </Container>
      )}
    </>
  )
}
