import { Alert, Container, Grid } from '@mui/material'
import { IErrorDash, FilterValues } from '../../../domain'
import { AggregatePublicGraphs } from '../../../components'

interface AggregateGraphsProps {
  history?: any
  error: IErrorDash
  filter: FilterValues
}

export const AggregateGraphs = ({
  filter,
  history,
  error,
}: AggregateGraphsProps) => {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} mt={3} mb={1}>
          <Alert
            variant="filled"
            severity="info"
            sx={{
              boxShadow: 0,
              backgroundColor: '#0288d1 !important',
              '.MuiAlert-icon': {
                alignItems: 'center !important',
              },
            }}
          >
            Esta seção exibe os indicadores sumarizados pelas Sociedades
            Participantes, os quais são coletados e apresentados pela Plataforma
            de Coleta de Métricas (PCM).
          </Alert>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ height: '100%' }}>
          <AggregatePublicGraphs
            history={history}
            error={error}
            filter={filter}
          />
        </Grid>
        <Grid item xs={12} mt={3} mb={1}>
          <Alert
            variant="filled"
            severity="warning"
            sx={{
              boxShadow: 0,
              '.MuiAlert-icon': {
                alignItems: 'center !important',
              },
            }}
          >
            A partir de 01/03/2024, o Opin passou adotar uma nova abordagem para
            associar os Conglomerados e suas Organizações Participantes.
            Informações anteriores a essa data, agora são apresentadas como{' '}
            <strong>"Nome da Organização - Relacionamento Anterior"</strong>.
          </Alert>
        </Grid>
      </Grid>
    </Container>
  )
}