import _ from 'lodash'
import {
  Bar,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useTheme } from '@mui/material'
import { ChartContainer, Container, TotalContainer } from './styles'
import {
  TooltipGraph,
  ViewDataError,
  ViewDataLoading,
} from '../../../../../components'
import { ICustomTooltip, SelectDefaultValue } from '../../../../../domain'
import { formatToLocaleString, parseNumberToInt } from '../../../../../utils'

const CustomTooltip = ({ active, payload }: ICustomTooltip) => {
  if (active && payload && payload.length) {
    return <TooltipGraph>Qtd de sociedades: {payload[0].value}</TooltipGraph>
  }
  return null
}
interface AverageResponseTimePageFiveProps {
  data: any
  averageTotalMediumPriority: number
  chartOrder: SelectDefaultValue
  mobile: boolean
  loading: boolean
}
const AverageResponseTimePageFive = ({
  data,
  chartOrder,
  averageTotalMediumPriority,
  mobile,
  loading,
}: AverageResponseTimePageFiveProps) => {
  const theme = useTheme()
  return (
    <>
      {loading && <ViewDataLoading />}

      {!loading &&
        (!data ||
          data.length === 0 ||
          averageTotalMediumPriority === undefined ||
          parseNumberToInt(averageTotalMediumPriority) === 0) && (
          <ViewDataError />
        )}

      {averageTotalMediumPriority !== undefined &&
        parseNumberToInt(averageTotalMediumPriority) !== 0 &&
        data &&
        data.length > 0 &&
        !loading && (
          <Container>
            <ChartContainer sx={{ overflow: 'hidden' }}>
              <ResponsiveContainer minWidth={200} minHeight={300}>
                <ComposedChart
                  layout="vertical"
                  data={_.orderBy(
                    data,
                    [chartOrder.value.by],
                    [chartOrder.value.type],
                  )}
                >
                  <XAxis
                    type="number"
                    hide={true}
                    domain={[0, (dataMax: number) => dataMax * 1.2]}
                  />
                  <YAxis
                    dataKey="range"
                    type="category"
                    width={100}
                    tickLine={false}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey="value"
                    barSize={40}
                    fill={theme.palette.primary.light}
                  >
                    <LabelList dataKey="value" position="right" fill="#000" />
                  </Bar>
                  {!mobile && <Tooltip content={<CustomTooltip />} />}
                </ComposedChart>
              </ResponsiveContainer>
            </ChartContainer>

            <TotalContainer
              textColor="#fff"
              bgColor={theme.palette.primary.main}
            >
              <h3>{`${formatToLocaleString(
                parseNumberToInt(averageTotalMediumPriority),
              )} milissegundos`}</h3>
              <p>TEMPO DE RESPOSTA MÉDIO DA API</p>
            </TotalContainer>
          </Container>
        )}
    </>
  )
}

export default AverageResponseTimePageFive
