import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const BoxFilter = styled(Box)<BoxProps>(({ theme }) => ({
  justifyContent: 'stretch',
  zIndex: 1000,
  top: 0,
  [theme.breakpoints.up('md')]: {
    position: 'fixed',
    width: 'calc(100% - 236px)',
    backgroundColor: 'white',
  },
  [theme.breakpoints.down('md')]: {
    position: 'inherit',
    width: '100%',
  },
}))

export const BoxAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
  width: 'inherit',
  backgroundColor: 'transparent',
  [theme.breakpoints.down('md')]: {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.up('md')]: {
    borderBottom: '1px solid #e2e2e2 !important',
  },
}))

export const BoxAccordionSummary = styled(
  AccordionSummary,
)<AccordionSummaryProps>(({ theme }) => ({
  minHeight: 'auto !important',
  '.MuiAccordionSummary-content': {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '.MuiAccordionDetails-root': {
    padding: 0,
  }
}))

export const BoxAccordionDetails = styled(
  AccordionDetails,
)<AccordionDetailsProps>(({ theme }) => ({
  padding: '0px 0px 16px',
  backgroundColor: 'transparent',
}))

export const BoxTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: '#707070',
  letterSpacing: '0px',
  paddingTop: '2px',
  fontSize: '0.8em',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
