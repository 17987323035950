import { IconButton, Skeleton, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { CardBox, TooltipInfo } from '../../../components'
import { CardSubTitle, CardTitle, Result } from './style'

interface ViewInfoCardProps {
  type: 'info' | 'success' | 'error'
  title: string
  result?: number | string
  resultType?: '%' | 'ms'
  infoTooltip?: string
  isLoading?: boolean
}

const colorType = {
  info: 'info.main',
  success: 'success.main',
  error: 'error.main',
}

const FONTSIZE = 3.375
const FONTSIZE_WIDTH_DEFAULT = 135
const FONTSIZE_WIDTH_UNIT = 0.375
const FONTSIZE_WIDTH_CHARACTER = 27
const FONTSIZE_OFFSET_MD = 0.78
const FONTSIZE_OFFSET_XS = 0.59
let CTX: CanvasRenderingContext2D

export const ViewInfoCard = ({
  type,
  title,
  result,
  resultType,
  infoTooltip,
  isLoading,
}: ViewInfoCardProps) => {
  const [fontSize, setFontSize] = useState<number>(FONTSIZE)

  const measureText = (text: string) => {
    if (!CTX) {
      CTX = document.createElement('canvas').getContext('2d')!
      CTX.font = '3.375rem "Roboto, Helvetica, Arial, sans-serif"'
    }

    return CTX.measureText(text).width
  }

  useEffect(() => {
    if (result) {
      const textWidth = measureText((result as string) + (resultType || ''))
      const textSize =
        textWidth > FONTSIZE_WIDTH_DEFAULT
          ? FONTSIZE -
            ((textWidth - FONTSIZE_WIDTH_DEFAULT) / FONTSIZE_WIDTH_CHARACTER) *
              FONTSIZE_WIDTH_UNIT
          : FONTSIZE
      setFontSize(textSize)
    } else {
      setFontSize(FONTSIZE)
    }
  }, [result, resultType])

  return (
    <CardBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardTitle
        title={title}
        action={
          infoTooltip && (
            <TooltipInfo
              title={infoTooltip}
              arrow
              placement="left-start"
              enterTouchDelay={0}
            >
              <IconButton aria-label="info">
                <InfoOutlined color="info" sx={{ opacity: 0.5 }} />
              </IconButton>
            </TooltipInfo>
          )
        }
      />
      <CardSubTitle>
        {isLoading ? (
          <Skeleton
            sx={{
              marginInline: 'auto',
              marginBlockStart: '-10px',
              marginBlockEnd: '-6px',
              height: { xs: 58, md: 70 },
              width: '100%',
              maxWidth: 115,
            }}
          />
        ) : (
          <Result
            color={colorType[type]}
            sx={{
              fontSize: {
                xs: `${fontSize * FONTSIZE_OFFSET_XS}rem`,
                sm: `${fontSize}rem`,
                md: `${fontSize * FONTSIZE_OFFSET_MD}rem`,
                xl: `${fontSize}rem`,
              },
            }}
          >
            {result || '0'}
            <Typography
              component="span"
              sx={{ fontSize: `${fontSize * FONTSIZE_OFFSET_XS}rem` }}
            >
              {resultType}
            </Typography>
          </Result>
        )}
      </CardSubTitle>
    </CardBox>
  )
}
