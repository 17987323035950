import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import {
  IAvailabilityPortalGraph,
  IAvarageCallsServiceDeskGraph,
  ICallsServiceDeskGraph,
  IErrorGraphs,
  IFilter,
  IOrgsDirectoryGraph,
  IStatusCallsServiceDeskGraph,
} from '../../../domain'
import { UserContext } from '../../../context/user'
import { InfrastructureService } from '../../../services'
import {
  InfrastructureCallsGraph,
  InfrastructureReasonCallsGraph,
  InfrastructureAvarageCallsGraph,
  InfrastructureStatusCallsGraph,
  InfrastructureOrgsDirectoryGraph,
  InfrastructureAvailabilityPortalGraph,
} from '../../../components'

interface InfrastructurePublicGraphsProps {
  activatedFilters: IFilter
}

export const InfrastructurePublicGraphs = ({
  activatedFilters,
}: InfrastructurePublicGraphsProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { loading, updateLoading } = useContext(UserContext)
  const [lastFilter, setLastFilter] = useState<{
    filter: IFilter
    time: number
  }>({
    filter: activatedFilters,
    time: new Date().getTime(),
  })

  const [callsGraphData, setCallsGraphData] = useState<ICallsServiceDeskGraph>(
    {} as ICallsServiceDeskGraph,
  )

  const [reasonCallsGraphData, setReasonCallsGraphData] =
    useState<ICallsServiceDeskGraph>({} as ICallsServiceDeskGraph)

  const [avarageCallsGraphData, setAvarageCallsGraphData] = useState<
    IAvarageCallsServiceDeskGraph[]
  >([])

  const [statusCallsGraphData, setStatusCallsGraphData] =
    useState<IStatusCallsServiceDeskGraph>({} as IStatusCallsServiceDeskGraph)

  const [availabilityPortalGraphData, setAvailabilityPortalGraphData] =
    useState<IAvailabilityPortalGraph>({} as IAvailabilityPortalGraph)

  const [orgsDirectoryGraphData, setOrgsDirectoryGraphData] =
    useState<IOrgsDirectoryGraph>({} as IOrgsDirectoryGraph)

  const [graphsError, setGraphsError] = useState<IErrorGraphs>({
    callsGraph: false,
    reasonCallsGraph: false,
    avarageCallsGraph: false,
    statusCallsGraph: false,
    availabilityPortalGraph: false,
    orgsDirectoryGraph: false,
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    InfrastructureService.getCallsChart(lastFilter.filter, signal)
      .then((data: ICallsServiceDeskGraph) => {
        setCallsGraphData(data)
        setGraphsError((prevState) => ({
          ...prevState,
          callsGraph: false,
        }))
        updateLoading({ name: 'callsGraph', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setCallsGraphData({} as ICallsServiceDeskGraph)
          setGraphsError((prevState) => ({
            ...prevState,
            callsGraph: true,
          }))
          updateLoading({ name: 'callsGraph', loading: false })
        }
      })

    InfrastructureService.getReasonCallsChart(lastFilter.filter, signal)
      .then((data: ICallsServiceDeskGraph) => {
        setReasonCallsGraphData(data)
        setGraphsError((prevState) => ({
          ...prevState,
          reasonCallsGraph: false,
        }))
        updateLoading({ name: 'reasonCallsGraph', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setReasonCallsGraphData({} as ICallsServiceDeskGraph)
          setGraphsError((prevState) => ({
            ...prevState,
            reasonCallsGraph: true,
          }))
          updateLoading({ name: 'reasonCallsGraph', loading: false })
        }
      })

    InfrastructureService.getAvarageCallsChart(lastFilter.filter, signal)
      .then((data: IAvarageCallsServiceDeskGraph[]) => {
        setAvarageCallsGraphData(data)
        setGraphsError((prevState) => ({
          ...prevState,
          avarageCallsGraph: false,
        }))
        updateLoading({ name: 'avarageCallsGraph', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setAvarageCallsGraphData([])
          setGraphsError((prevState) => ({
            ...prevState,
            avarageCallsGraph: true,
          }))
          updateLoading({ name: 'avarageCallsGraph', loading: false })
        }
      })

    InfrastructureService.getStatusCallsChart(lastFilter.filter, signal)
      .then((data: IStatusCallsServiceDeskGraph) => {
        setStatusCallsGraphData(data)
        setGraphsError((prevState) => ({
          ...prevState,
          statusCallsGraph: false,
        }))
        updateLoading({ name: 'statusCallsGraph', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setStatusCallsGraphData({} as IStatusCallsServiceDeskGraph)
          setGraphsError((prevState) => ({
            ...prevState,
            statusCallsGraph: true,
          }))
          updateLoading({ name: 'statusCallsGraph', loading: false })
        }
      })

    InfrastructureService.getAvailabilityPortalChart(lastFilter.filter, signal)
      .then((data: IAvailabilityPortalGraph) => {
        setAvailabilityPortalGraphData(data)
        setGraphsError((prevState) => ({
          ...prevState,
          availabilityPortalGraph: false,
        }))
        updateLoading({ name: 'availabilityPortalGraph', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setAvailabilityPortalGraphData({} as IAvailabilityPortalGraph)
          setGraphsError((prevState) => ({
            ...prevState,
            availabilityPortalGraph: true,
          }))
          updateLoading({ name: 'availabilityPortalGraph', loading: false })
        }
      })

    InfrastructureService.getOrgsDirectory(lastFilter.filter, signal)
      .then((data: IOrgsDirectoryGraph) => {
        setOrgsDirectoryGraphData(data)
        setGraphsError((prevState) => ({
          ...prevState,
          orgsDirectoryGraph: false,
        }))
        updateLoading({ name: 'orgsDirectoryGraph', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setOrgsDirectoryGraphData({} as IOrgsDirectoryGraph)
          setGraphsError((prevState) => ({
            ...prevState,
            orgsDirectoryGraph: true,
          }))
          updateLoading({ name: 'orgsDirectoryGraph', loading: false })
        }
      })

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFilter])

  useEffect(() => {
    const now = new Date().getTime()
    if (
      activatedFilters.interval &&
      activatedFilters.interval[0] &&
      (JSON.stringify(activatedFilters) !==
        JSON.stringify(lastFilter?.filter) ||
        Math.abs(lastFilter.time - now) > 500)
    ) {
      setLastFilter({
        filter: activatedFilters,
        time: now,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedFilters])

  return (
    <Grid container spacing={2} sx={{ height: '102.096%' }}>
      <Grid item xs={12} md={12} lg={12}>
        <InfrastructureCallsGraph
          dataGraph={callsGraphData}
          isLoading={loading.callsGraph}
          isError={graphsError.callsGraph}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <InfrastructureReasonCallsGraph
          dataGraph={reasonCallsGraphData}
          isLoading={loading.reasonCallsGraph}
          isError={graphsError.reasonCallsGraph}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <InfrastructureAvarageCallsGraph
          dataGraph={avarageCallsGraphData}
          isLoading={loading.avarageCallsGraph}
          isError={graphsError.avarageCallsGraph}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <InfrastructureStatusCallsGraph
          dataGraph={statusCallsGraphData}
          isLoading={loading.statusCallsGraph}
          isError={graphsError.statusCallsGraph}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <InfrastructureAvailabilityPortalGraph
          dataGraph={availabilityPortalGraphData}
          isLoading={loading.availabilityPortalGraph}
          isError={graphsError.availabilityPortalGraph}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <InfrastructureOrgsDirectoryGraph
          dataGraph={orgsDirectoryGraphData}
          isLoading={loading.orgsDirectoryGraph}
          isError={graphsError.orgsDirectoryGraph}
        />
      </Grid>
    </Grid>
  )
}
