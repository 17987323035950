export const graphListHelper = [
  {
    key: 0,
    title: 'Disponibilidade Média das APIs',
  },
  {
    key: 1,
    title: 'Disponibilidade Média das APIs por sociedade participante',
  },
]
