export const graphListHelper = [
  {
    key: 0,
    title: 'Chamadas APIs bem-sucedidas'
  },
  {
    key: 1,
    title: 'Chamadas APIs bem-sucedidas por sociedade participante'
  }
]
