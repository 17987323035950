import { FileDownloadOutlined } from '@mui/icons-material'
import { CSVLink } from 'react-csv'

interface IconCsvDownloaderProps {
  filename: string
  headers: Array<any>
  data: Array<any>
}

export const IconCsvDownloader = ({
  filename,
  headers,
  data,
}: IconCsvDownloaderProps) => {
  return (
    <CSVLink
      data={data}
      headers={headers}
      filename={`${filename}.csv`}
      enclosingCharacter={''}
      // eslint-disable-next-line react/forbid-component-props
      style={{ maxHeight: 24 }}
    >
      <FileDownloadOutlined color="info" sx={{ opacity: 0.5 }} />
    </CSVLink>
  )
}
