import * as PubSub from 'pubsub-js'

export const events = {
  login: 'EVENT_LOGIN',
  message: 'EVENT_MESSAGE',
}

export const subscribe = <T>(event: string, on: (data: T) => void) => {
  const token = PubSub.subscribe(event, (_message: string, data: T) => on(data))
  return () => {
    PubSub.unsubscribe(token)
  }
}
