import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
  width: 100%;
  flex: 3;

  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ChartContainer = styled(Box)`
  width: 100%;
  height: 380px;
  flex: 3;

  display: flex;
  gap: 2rem;

  & > div:first-of-type {
    flex: 3;
  }
`;

interface TotalContainerProps {
  textColor?: string;
  bgColor?: string;
}
export const TotalContainer = styled(Box) <TotalContainerProps>`
  border-radius: 100px 30px 30px 100px;
  color: ${({ textColor, theme }) => textColor || theme.palette.common.black};
  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.palette.secondary.light};
  text-align: center;
  padding: 1rem;
  height: fit-content;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > h3 {
    color: ${({ textColor, theme }) => textColor || theme.palette.common.black};
    font-weight: bold;
    width: 100%;
  }

  & > p {
    font-size: 16px !important;
  }

  & > p:last-child {
    margin: 0;
  }

  @media (max-width: 768px) {
    border-radius: 30px;
    margin: auto;
    width: fit-content;
  }
`;
