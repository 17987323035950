import { SelectDefaultValue } from '../../../../domain'
import { orgsOrderOptions, percentageOrderOptions } from './graphOrder'

export const makeOptions = (key: number): SelectDefaultValue[] => {
  if (key === 0 || key === 2 || key === 4) {
    return percentageOrderOptions.map(option => ({
      label: option.title,
      value: option.order
    }))
  }
  return orgsOrderOptions.map(option => ({
    label: option.title,
    value: option.order
  }))
}
