import { IEndpoint, IOrganization, IMultipleOrganization, IStatus } from '../domain'
import axiosClient from '../libs/axios'

export class FilterService {
  static async getStatuses(): Promise<Array<IStatus>> {
    return axiosClient
      .get('/metrics-management/statuses')
      .then((response) => response.data)
  }

  static async getEndpoints(stages: string[]): Promise<Array<IEndpoint>> {
    return axiosClient
      .get('/metrics-management/endpoints', {
        params: {
          stages: stages?.join(','),
        }
      })
      .then((response) => response.data)
  }

  static async getParentTransmitters(): Promise<Array<IMultipleOrganization>> {
    return axiosClient
      .get('/metrics-management/organisations-server', {
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getTransmitters(): Promise<Array<IOrganization>> {
    return axiosClient
      .get('/metrics-management/servers', {
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getParentReceivers(): Promise<Array<IMultipleOrganization>> {
    return axiosClient
      .get('/metrics-management/organisations-client', {
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getReceivers(): Promise<Array<IMultipleOrganization>> {
    return axiosClient
      .get('/metrics-management/clients', {
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getChannels(): Promise<Array<IOrganization>> {
    return axiosClient
      .get('/metrics-management/channels', {
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getStages(): Promise<Array<IStatus>> {
    return axiosClient
      .get('/metrics-management/stages')
      .then((response) => response.data)
  }

  static async getDashboardParentTransmitters(): Promise<Array<IMultipleOrganization>> {
    return axiosClient({ url: '/dashboard-metrics/conglomerates', baseURL: process.env.REACT_APP_METRICS_PRD_API })
      .then((response) => response.data)
  }

  static async getDashboardTransmitters(): Promise<Array<IOrganization>> {
    return axiosClient({ url: '/dashboard-metrics/servers', baseURL: process.env.REACT_APP_METRICS_PRD_API })
      .then((response) => response.data)
  }
}
