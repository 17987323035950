import { Typography, TypographyProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  lineHeight: theme.typography.pxToRem(26),
  letterSpacing: 0,
  marginTop: 2,
  color: theme.palette.primary.main,
}))

export const User = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(26),
  letterSpacing: 0,
  marginRight: 12,
  color: theme.palette.primary.main,
  textAlign: 'center',
}))
