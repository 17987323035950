import { IUser } from '../domain'
import axiosClient from '../libs/axios'

const baseURL = process.env.REACT_APP_METRICS_API

export class UserService {
  static login() {
    window.location.href = `${baseURL}/metrics-management/federated-login`
  }

  static logout() {
    window.location.href = `${baseURL}/metrics-management/logout`
  }

  static async get(): Promise<IUser> {
    return axiosClient
      .get('/metrics-management/me', {
        withCredentials: true,
      })
      .then((response) => response.data)
  }
}
