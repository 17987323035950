import { useEffect, useState, useRef, useContext } from 'react'
import { Grid } from '@mui/material'
import {
  IEndpoint,
  IFilter,
  IOrganization,
  IMultipleOrganization,
  IStage,
  IStatus,
} from '../../domain'
import {
  ToolFilterAccordion,
  ViewDataLoading,
  DynamicGraphs,
  ToolFilterDynamic,
} from '../../components'
import { FilterService } from '../../services'
import { UserContext } from '../../context/user'

export const Dynamic = () => {
  const effectFilters = useRef(false)
  const { user, loading, updateLoading } = useContext(UserContext)
  const [filters, setFilter] = useState<IFilter>({} as IFilter)
  const [endpoints, setEndpoints] = useState<IEndpoint[]>([])
  const [statuses, setStatuses] = useState<IStatus[]>([])
  const [stages, setStages] = useState<IStage[]>([])
  const [parentTransmitters, setParentTransmitters] = useState<IOrganization[]>(
    [],
  )
  const [transmitters, setTransmitters] = useState<IOrganization[]>([])
  const [parentReceivers, setParentReceivers] = useState<IOrganization[]>([])
  const [receivers, setReceivers] = useState<IOrganization[]>([])
  const [channels, setChannels] = useState<IOrganization[]>([])
  const [height, setHeight] = useState(user.name ? 356 : 190)
  const [hasChanged, setHasChanged] = useState(false)

  const handleFilters = (filters: IFilter) => {
    setFilter(filters)
  }

  useEffect(() => {
    if (effectFilters.current === false) {
      updateLoading({ name: 'publicFilters', loading: true })

      FilterService.getStatuses()
        .then((data: IStatus[]) => setStatuses(data))
        .finally(() => updateLoading({ name: 'statuses', loading: false }))
      FilterService.getStages()
        .then((data: IStage[]) => setStages(data))
        .finally(() => updateLoading({ name: 'stages', loading: false }))

      return () => {
        effectFilters.current = true
      }
    }
    setHasChanged(!hasChanged)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    updateLoading({ name: 'endpoints', loading: true })

    FilterService.getEndpoints(filters.stages)
      .then((data: IEndpoint[]) => setEndpoints(data))
      .finally(() => updateLoading({ name: 'endpoints', loading: false }))

    setHasChanged(!hasChanged)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.stages])

  useEffect(() => {
    if (filters.parentTransmitters && filters.parentTransmitters.length === 0) {
      setFilter((prevState) => ({ ...prevState, transmitters: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.parentTransmitters])

  useEffect(() => {
    if (filters.parentReceivers && filters.parentReceivers.length === 0) {
      setFilter((prevState) => ({ ...prevState, receivers: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.parentReceivers])

  useEffect(() => {
    if (filters.receivers && filters.receivers.length === 0) {
      setFilter((prevState) => ({ ...prevState, channels: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.receivers])

  useEffect(() => {
    if (user.name) {
      updateLoading({ name: 'privateFilters', loading: true })

      FilterService.getParentTransmitters()
        .then((data: IMultipleOrganization[]) => {
          const newParentTransmitters: IOrganization[] = data.map(
            (parentTransmitter: IMultipleOrganization) => ({
              value: parentTransmitter.value,
              id: parentTransmitter.ids.join(','),
            }),
          )
          setParentTransmitters(newParentTransmitters)
        })
        .finally(() =>
          updateLoading({ name: 'parentTransmitters', loading: false }),
        )

      FilterService.getTransmitters()
        .then((data: IOrganization[]) => setTransmitters(data))
        .finally(() => updateLoading({ name: 'transmitters', loading: false }))

      FilterService.getParentReceivers()
        .then((data: IMultipleOrganization[]) => {
          const newParentReceivers: IOrganization[] = data.map(
            (parentReceiver: IMultipleOrganization) => ({
              value: parentReceiver.value,
              id: parentReceiver.ids.join(','),
            }),
          )
          setParentReceivers(newParentReceivers)
        })
        .finally(() =>
          updateLoading({ name: 'parentReceivers', loading: false }),
        )

      FilterService.getReceivers()
        .then((data: IMultipleOrganization[]) => {
          const newReceivers: IOrganization[] = data.map(
            (receiver: IMultipleOrganization) => ({
              value: receiver.value,
              id: receiver.ids.join(','),
            }),
          )
          setReceivers(newReceivers)
        })
        .finally(() => updateLoading({ name: 'receivers', loading: false }))

      FilterService.getChannels()
        .then((data: IOrganization[]) => setChannels(data))
        .finally(() => updateLoading({ name: 'channels', loading: false }))
    } else {
      setParentTransmitters([] as IOrganization[])
      setTransmitters([] as IOrganization[])
      setParentReceivers([] as IOrganization[])
      setReceivers([] as IOrganization[])
      setChannels([] as IOrganization[])
      setFilter((prevState: any) => ({
        ...prevState,
        parentTransmitters: [],
        transmitters: [],
        parentReceivers: [],
        receivers: [],
        channels: [],
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <Grid container>
      {loading.user === true && (
        <Grid
          item
          xs={12}
          sx={{
            height: { xs: 'calc(100vh - 191px)', md: '100vh' },
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <ViewDataLoading />
        </Grid>
      )}

      {loading.user === false && (
        <>
          <Grid item xs={12}>
            <ToolFilterAccordion
              hasChanged={hasChanged}
              onChangeHeight={setHeight}
            >
              <ToolFilterDynamic
                parentTransmitters={parentTransmitters}
                parentReceivers={parentReceivers}
                transmitters={transmitters}
                receivers={receivers}
                channels={channels}
                endpoints={endpoints}
                statuses={statuses}
                stages={stages}
                onChange={handleFilters}
              />
            </ToolFilterAccordion>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginTop: { md: `${height + 8}px`, lg: `${height}px` },
              paddingBottom: 3,
              transition: 'margin 251ms cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          >
            <DynamicGraphs activatedFilters={filters} />
          </Grid>
        </>
      )}
    </Grid>
  )
}
