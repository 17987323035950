import { Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import {
  IAvailabilityParticipantsGraph,
  IErrorGraphs,
  IFilter,
  IStatusGraph,
} from '../../../domain'
import {
  DynamicStatusRequestsGraph,
  DynamicResponseRequestsGraph,
  DynamicAvailabilityParticipantsGraph,
} from '../../../components'
import { UserContext } from '../../../context/user'
import { ReportService } from '../../../services'

interface DynamicPublicGraphsProps {
  activatedFilters: IFilter
}

export const DynamicPublicGraphs = ({
  activatedFilters,
}: DynamicPublicGraphsProps) => {
  const { loading, updateLoading } = useContext(UserContext)
  const [lastFilter, setLastFilter] = useState<{
    filter: IFilter
    time: number
  }>({
    filter: activatedFilters,
    time: new Date().getTime(),
  })

  const [statusGraphData, setStatusGraphData] = useState<IStatusGraph>(
    {} as IStatusGraph,
  )

  const [
    availabilityParticipantsGraphData,
    setAvailabilityParticipantsGraphData,
  ] = useState<IAvailabilityParticipantsGraph>(
    {} as IAvailabilityParticipantsGraph,
  )

  const [graphsError, setGraphsError] = useState<IErrorGraphs>({
    availabilityParticipantsChart: false,
    statusGraph: false,
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    ReportService.getStatusChart(lastFilter.filter, signal)
      .then((data: IStatusGraph) => {
        setStatusGraphData(data)
        setGraphsError((prevState) => ({ ...prevState, statusGraph: false }))
        updateLoading({ name: 'statusChart', loading: false })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setStatusGraphData({} as IStatusGraph)
          setGraphsError((prevState) => ({ ...prevState, statusGraph: true }))
          updateLoading({ name: 'statusChart', loading: false })
        }
      })

    ReportService.getAvailabilityParticipantsChart(lastFilter.filter, signal)
      .then((data: IAvailabilityParticipantsGraph) => {
        setAvailabilityParticipantsGraphData(data)
        setGraphsError((prevState) => ({
          ...prevState,
          availabilityParticipantsChart: false,
        }))
        updateLoading({
          name: 'availabilityParticipantsGraph',
          loading: false,
        })
      })
      .catch((error) => {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setAvailabilityParticipantsGraphData(
            {} as IAvailabilityParticipantsGraph,
          )
          setGraphsError((prevState) => ({
            ...prevState,
            availabilityParticipantsChart: true,
          }))
          updateLoading({
            name: 'availabilityParticipantsGraph',
            loading: false,
          })
        }
      })

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFilter])

  useEffect(() => {
    const now = new Date().getTime()
    if (
      activatedFilters.interval &&
      activatedFilters.interval[0] &&
      (JSON.stringify(activatedFilters) !==
        JSON.stringify(lastFilter?.filter) ||
        Math.abs(lastFilter.time - now) > 500)
    ) {
      setLastFilter({
        filter: activatedFilters,
        time: now,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedFilters])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicStatusRequestsGraph
          dataGraph={statusGraphData}
          isLoading={loading.statusChart}
          isError={graphsError.statusGraph}
          filterBy={lastFilter.filter.statuses}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicResponseRequestsGraph
          dataGraph={statusGraphData}
          isLoading={loading.statusChart}
          isError={graphsError.statusGraph}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicAvailabilityParticipantsGraph
          dataGraph={availabilityParticipantsGraphData}
          isLoading={loading.availabilityParticipantsGraph}
          isError={graphsError.availabilityParticipantsChart}
        />
      </Grid>
    </Grid>
  )
}
