import * as PubSub from 'pubsub-js'
import { events, subscribe } from './core'

export interface ILogin {
  login: boolean
}

export const sendLoginEvent = (login: ILogin) => {
  PubSub.publish(events.login, login)
}

export const onLoginEvent = (event: (login: ILogin) => void) => {
  return subscribe(events.login, event)
}
