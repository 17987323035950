import { useEffect, useRef, useContext, useState } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import { onLoginEvent, ILogin, sendMessageEvent } from '../../../events'
import { Image } from '../../../components'
import { IUser } from '../../../domain'
import { UserService } from '../../../services'
import { UserContext } from '../../../context/user'
import { BoxNav, CustomList } from './style'
import logoOpin from 'assets/svg/logo_opin.svg'
import { ExitToApp } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'

export const LayoutNav = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { updateChartLabel, updateChartTitle } = useContext(UserContext)
  const effectUser = useRef(false)
  const [scrollTop, setScrollTop] = useState(0)
  const { user, loading, updateUser, updateLoading } = useContext(UserContext)
  const [open, setOpen] = useState(true)

  const handleLogin = () => {
    updateLoading({ name: 'user', loading: true })
    UserService.login()
  }

  const handleLogout = () => {
    updateLoading({ name: 'user', loading: true })
    UserService.logout()
  }

  const handleUser = () => {
    updateLoading({ name: 'user', loading: true })
    UserService.get()
      .then((data: IUser) => {
        updateUser(data)
        updateLoading({ name: 'user', loading: false })
        sendMessageEvent({
          type: 'success',
          text: 'Login efetuado com sucesso!',
        })
      })
      .catch(() => {
        updateUser({} as IUser)
        updateLoading({ name: 'user', loading: false })
      })
  }

  useEffect(() => {
    if (effectUser.current === false) {
      handleUser()
      return () => {
        effectUser.current = true
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollTop])

  useEffect(() => {
    const cleanEvent = onLoginEvent(({ login }: ILogin) => {
      if (login === false) {
        handleUser()
      }
    })
    return cleanEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNavigate = ({
    label,
    title,
    url,
  }: {
    label: string
    title: string
    url: string
  }) => {
    updateChartLabel(label)
    updateChartTitle(title)
    navigate(url)
  }

  const navigation = [
    {
      label: 'Métricas Agregadas',
      title: 'API Admin (Métricas) e API Comuns (Discovery)',
      url: '/aggregate',
    },
    {
      label: 'Métricas Dinâmicas',
      title: 'API Métricas Transacionais e Funil de Consentimento',
      url: '/metrics',
    },
    {
      label: 'Infraestrutura e Service Desk',
      title: 'Elementos de Infraestrutura e Chamados no Service Desk',
      url: '/infrastructure',
    },
  ]

  useEffect(() => {
    const { label, title } =
      navigation.filter((nav) => nav.url === pathname)[0] || navigation[1]
    updateChartLabel(label)
    updateChartTitle(title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <BoxNav>
      <Box>
        <Box sx={{ display: 'flex', position: { md: 'fixed' } }}>
          <IconButton
            onClick={() => setOpen(!open)}
            sx={{ display: { md: 'none' }, ml: 1 }}
          >
            <MenuIcon />
          </IconButton>
          <Image
            src={logoOpin}
            sx={{
              width: { xs: '60%', sm: '100%' },
              height: 30,
              marginY: { xs: 2, md: 2.5 },
              marginX: { xs: 0, md: 2 },
              marginInlineEnd: { xs: 2, md: 5, lg: 5, xl: 5 },
            }}
          />
        </Box>
      </Box>

      <Box
        position="relative"
        display={{
          xs: open ? 'block' : 'none',
          sm: open ? 'block' : 'none',
          md: 'block',
        }}
      >
        <CustomList
          sx={{
            position: { md: 'fixed' },
            top: { md: 70 },
          }}
          subheader={
            <ListSubheader
              component="div"
              sx={{ mt: { md: 3 }, mb: 2, lineHeight: '1.25rem' }}
            >
              Plataforma de Coleta de Métricas
            </ListSubheader>
          }
        >
          {navigation.map(
            (
              {
                label,
                title,
                url,
              }: { label: string; title: string; url: string },
              index,
            ) => (
              <ListItem key={index} disablePadding color="primary">
                <ListItemButton
                  selected={pathname === url}
                  onClick={() => handleNavigate({ label, title, url })}
                >
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            ),
          )}
          {/* <ListSubheader
            component="div"
            sx={{ mt: { md: 3 }, mb: 2, lineHeight: '1.25rem' }}
          >
            Links
          </ListSubheader>
          <ListItem disablePadding color="primary">
            <ListItemButton>
              <ListItemText primary="Portal Cidadão" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding color="primary">
            <ListItemButton>
              <ListItemText primary="Portal Participante" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding color="primary">
            <ListItemButton>
              <ListItemText primary="Área do Desenvolvedor" />
            </ListItemButton>
          </ListItem> */}
        </CustomList>

        <CustomList>
          <ListItem
            disablePadding
            sx={{
              position: { md: 'fixed' },
              bottom: 0,
              width: { xs: '100%', md: 236 },
              borderTop: '1px solid #e2e2e2',
              borderBottom: '1px solid #e2e2e2',
            }}
          >
            {!user.name ? (
              <ListItemButton onClick={() => handleLogin()}>
                <ListItemText primary="Entrar" />
                {loading.user && (
                  <CircularProgress size={24.5} color="inherit" />
                )}
              </ListItemButton>
            ) : (
              <ListItemButton
                onClick={() => handleLogout()}
                sx={{
                  gap: 1,
                }}
              >
                <ListItemText
                  primary={user.organisationName}
                  primaryTypographyProps={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  secondary={user.name}
                  secondaryTypographyProps={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  sx={{
                    whiteSpace: 'nowrap',
                    width: '100%',
                  }}
                />
                <ExitToApp />
              </ListItemButton>
            )}
          </ListItem>
        </CustomList>
      </Box>
    </BoxNav>
  )
}
