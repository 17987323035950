import { Box, styled } from "@mui/material"


export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  flex: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ChartContainer = styled(Box)`
  width: 100%;
  max-height: 380px;
  overflow-y: scroll;
  flex: 3;

  position: relative;
`

export const Legend = styled(Box)`
  display: flex;
  align-items: center;
  gap: .5rem;

  & > span:first-of-type {
    display: flex;
    align-items: center;
  }
`

export const TooltipContainer = styled(Box)`
  background-color: #3c3c3c;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  padding: 1rem;

  display: flex;
  flex-direction: column;
`
export const NoData = styled(Box)`
  background-color: #ececec;
  border-radius: 5px;
  width: 80%;
  height: 500px;

  display: flex;
  align-items: center;
  justify-content: center;
`
