import { useCallback, useEffect, useState } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { DateRangePicker, MultiChipAutocomplete } from '../../../components'
import { IOrganization, FilterValues } from '../../../domain'

interface FilterAggregateProps {
  parentTransmitters?: IOrganization[] | []
  transmitters?: IOrganization[] | []
  submitValues: (value: FilterValues) => void
}

export const ToolFilterAggregate = ({
  parentTransmitters,
  transmitters,
  submitValues,
}: FilterAggregateProps) => {
  const [clearCount, setClearCount] = useState<number>(0)
  const [filters, setFilters] = useState({
    parentTransmitters: [],
    transmitters: [],
    interval: [],
    orgs: [],
    type: '',
  })

  const handleInputs = (id: string, data: string[] | string) => {
    setFilters((prevState) => ({ ...prevState, [id]: data }))
  }

  const handleClear = () => {
    setFilters({
      parentTransmitters: [],
      transmitters: [],
      interval: [],
      orgs: [],
      type: '',
    })
    setClearCount((prevState) => prevState + 1)
  }

  const onSubmit = useCallback(() => {
    submitValues({
      parentTransmitters: filters.parentTransmitters,
      transmitters: filters.transmitters,
      interval: filters.interval,
      orgs: filters.orgs,
      type: filters.type,
    })
  }, [filters, submitValues])

  useEffect(() => {
    onSubmit()
  }, [filters, onSubmit])

  useEffect(() => {
    onSubmit()
  }, [onSubmit])

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <DateRangePicker
            id="interval"
            label="Intervalo"
            clearCount={clearCount}
            isAggregate
            onSelect={handleInputs}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <MultiChipAutocomplete
            id="parentTransmitters"
            label="Conglomerado transmissor"
            options={parentTransmitters ?? []}
            clearCount={clearCount}
            onSelect={handleInputs}
          />
        </Grid>

        {transmitters && (
          <Grid item xs={12} md={6} lg={3}>
            <MultiChipAutocomplete
              id="transmitters"
              label="Marca transmissora"
              options={
                transmitters.filter((transmitter) =>
                  filters.parentTransmitters.join(',').includes(transmitter.id),
                ) ?? []
              }
              clearCount={clearCount}
              onSelect={handleInputs}
              disabled={
                !transmitters || filters.parentTransmitters.length === 0
              }
              hasOptionControls={true}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'right' },
            maxHeight: '83px',
          }}
        >
          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleClear}
          >
            Limpar filtros
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}
