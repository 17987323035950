import { Box, CardContent, IconButton, Typography } from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import { IOrgsDirectoryGraph } from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'

interface InfrastructureOrgsDirectoryGraphProps {
  dataGraph: IOrgsDirectoryGraph
  isLoading?: boolean
  isError?: boolean
}

const headerCSV = [
  {
    label: 'Sociedades com participação obrigatória',
    key: 'orgsTransmissoras',
  },
  {
    label: 'Sociedades com participação voluntária',
    key: 'others',
  },
  { label: 'Total', key: 'total' },
]

export const InfrastructureOrgsDirectoryGraph = ({
  dataGraph,
  isLoading,
  isError,
}: InfrastructureOrgsDirectoryGraphProps) => {
  const [dataShow, setDataShow] = useState<IOrgsDirectoryGraph[]>([])
  const [dataCSV, setDataCSV] = useState<IOrgsDirectoryGraph[]>([])
  const [activeLegend, setActiveLegend] = useState<string>('')

  const handleActiveLegend = ({ dataKey }: any) => {
    setActiveLegend(dataKey)
  }

  const handleDisableLegend = () => {
    setActiveLegend('')
  }

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>
          {payload[0].payload.total} sociedades seguradoras
        </Typography>
      </TooltipGraph>
    )
  }

  const handleData = (data: IOrgsDirectoryGraph) => {
    if (!data) {
      setDataShow([])
      setDataCSV([])
      return
    }

    const tempData: IOrgsDirectoryGraph[] = []

    tempData.push(data)

    setDataShow(tempData)
    setDataCSV(tempData)
  }

  useEffect(() => {
    // handleData(dataGraph)
    handleData({
      orgsTransmissoras: 62,
      others: 6,
      total: 68,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Número de Sociedades Cadastradas no Diretório"
        action={
          <>
            {!isLoading && !isError && dataCSV.length > 0 ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="sociedades-diretorio"
                    headers={headerCSV}
                    data={dataCSV}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow || dataShow.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={dataShow}
                margin={{
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
                layout="vertical"
                barGap={0}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  type="number"
                  padding={{ left: 0, right: 50 }}
                  domain={[0, dataGraph.total]}
                />
                <YAxis dataKey="name" type="category" tickLine={false} />
                <Bar
                  stackId="stack"
                  dataKey="orgsTransmissoras"
                  name="Sociedades com participação obrigatória"
                  fill="#639b48"
                  isAnimationActive={false}
                  opacity={
                    activeLegend !== ''
                      ? activeLegend === 'orgsTransmissoras'
                        ? 1
                        : 0.3
                      : 1
                  }
                >
                  <LabelList dataKey="orgsTransmissoras" fill="#000000de" />
                </Bar>
                <Bar
                  stackId="stack"
                  dataKey="others"
                  name="Sociedades com participação voluntária"
                  fill="#3c3c3c"
                  isAnimationActive={false}
                  opacity={
                    activeLegend !== ''
                      ? activeLegend === 'others'
                        ? 1
                        : 0.3
                      : 1
                  }
                >
                  <LabelList dataKey="others" fill="#ffffff" />
                </Bar>
                <Legend
                  iconSize={18}
                  verticalAlign="bottom"
                  wrapperStyle={{ marginBottom: -12 }}
                  onMouseEnter={handleActiveLegend}
                  onMouseLeave={handleDisableLegend}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
