import { Box, CardContent, IconButton, Typography } from '@mui/material'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { InfoOutlined } from '@mui/icons-material'
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  LineProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {
  IDataEndpointGraph,
  IEndpointCSV,
  IEndpointGraph,
  IItemEndpointGraph,
  ISerieEndpointGraph,
} from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import {
  formatNumber,
  formatNumberToScale,
  formatToLocaleString,
} from '../../../utils'

interface DynamicEndpointRequestsGraphProps {
  dataGraph: IEndpointGraph
  isLoading?: boolean
  isError?: boolean
}

const headerCSV = [
  { label: 'Endpoint', key: 'endpoint' },
  { label: 'Requisições', key: 'requests' },
  { label: 'Sucesso', key: 'success' },
  { label: 'Data', key: 'date' },
]

export const DynamicEndpointRequestsGraph = ({
  dataGraph,
  isLoading,
  isError,
}: DynamicEndpointRequestsGraphProps) => {
  const [activeLine, setActiveLine] = useState(null)
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const [dataShow, setDataShow] = useState<IEndpointGraph>({} as IEndpointGraph)
  const [activeLegend, setActiveLegend] = useState<string>('')
  const [dataCSV, setDataCSV] = useState<IEndpointCSV[]>([])

  const handleActiveLegend = ({ dataKey }: any) => {
    setActiveLegend(dataKey)
  }

  const handleDisableLegend = () => {
    setActiveLegend('')
  }

  const handleDotEnter = (data: any) => {
    setActiveLine(data)
  }

  const handleDataShow = (data: IEndpointGraph) => {
    const isAnyValueNonZero = data?.data?.some((item) => {
      const keys = Object.keys(item).filter((key) => key !== 'axisX')
      return keys.some((key) => item[key] !== 0)
    })

    if (!data.data || !isAnyValueNonZero) {
      setDataShow({} as IEndpointGraph)
      return
    }

    const formattedData = data.data.map((endpoint) => {
      const { axisX, ...rest } = endpoint
      const filteredData = Object.entries(rest).reduce(
        (acc: any, [key, value]: any) => {
          acc[key] = value.calls
          return acc
        },
        {},
      )

      return {
        axisX,
        ...filteredData,
      }
    })

    setDataShow({ ...data, data: formattedData })
  }

  const handleDataDownload = (data: IEndpointGraph) => {
    if (!data.series || !data.data) {
      setDataCSV([])
      return
    }

    const tmp: IEndpointCSV[] = []

    data.series.forEach((key: ISerieEndpointGraph) => {
      data.data.forEach((item: IDataEndpointGraph) => {
        const endpointData = item[key.key] as IItemEndpointGraph
        if (!endpointData) {
          return
        }

        tmp.push({
          endpoint: key.name,
          requests: endpointData.calls,
          success: `${formatNumber(endpointData.succeedPercentil, 2)}%`,
          date: item.axisX as string,
        })
      })
    })

    setDataCSV(tmp)
  }

  const CustomTooltip = ({ active, payload }: any) => {
    const activeTooltip = payload?.find(
      (item: LineProps) => item.dataKey === activeLine,
    )

    if (!active || !activeTooltip) return null

    const activeData = dataGraph.data.find(
      (item: IDataEndpointGraph) => item.axisX === activeTooltip.payload.axisX,
    )

    if (!activeData) return null

    return (
      <TooltipGraph>
        <Typography>{activeTooltip.name}</Typography>
        <Typography>
          {formatToLocaleString(
            (activeData[activeTooltip.dataKey] as IItemEndpointGraph)
              .succeedPercentil,
          )}
          % de sucesso
        </Typography>
      </TooltipGraph>
    )
  }

  const updateWindowDimensions = useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [setWindowWidth])

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth)

    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleDataShow(dataGraph)
    handleDataDownload(dataGraph)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Quantidade de requisições por endpoint por sociedade participante na linha do tempo"
        action={
          <>
            {!isLoading && !isError && dataCSV.length > 0 ? (
              <>
                <TooltipInfo
                  title="Exibe o total de requisições dos sete endpoints mais acessados na linha do tempo, permite retornar mais endpoints, desde que o selecionados na pesquisa."
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="info">
                    <InfoOutlined color="info" sx={{ opacity: 0.5 }} />
                  </IconButton>
                </TooltipInfo>
                <TooltipInfo
                  title="Fazer download do arquivo CSV"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="download">
                    <IconCsvDownloader
                      filename="endpoints-requisicoes"
                      headers={headerCSV}
                      data={dataCSV}
                    />
                  </IconButton>
                </TooltipInfo>
              </>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow ||
          !dataShow.series ||
          !dataShow.data ||
          dataShow.data.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer>
              <LineChart
                data={dataShow.data}
                margin={{
                  left: windowWidth > 899 ? 30 : 0,
                  right: 0,
                  top: 6,
                }}
              >
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  dataKey="axisX"
                  type="category"
                  tickLine={false}
                  interval="preserveStartEnd"
                  padding={{ left: 40, right: 40 }}
                />
                <YAxis
                  tickMargin={12}
                  type="number"
                  tickLine={false}
                  tickFormatter={(value) =>
                    `${formatNumberToScale(value, 1, 1000)}`
                  }
                >
                  <Label
                    value="Requisições"
                    position="insideLeft"
                    angle={-90}
                    offset={-25}
                    // eslint-disable-next-line react/forbid-component-props
                    style={{
                      textAnchor: 'middle',
                      fontWeight: 'bold',
                      fontSize: 12,
                      letterSpacing: '0.9px',
                      color: '#3C3C3C',
                      fill: '#3C3C3C',
                    }}
                  />
                </YAxis>
                <Tooltip content={<CustomTooltip />} />

                {dataShow.series.map((option) => (
                  <Line
                    key={option.key}
                    type="monotone"
                    dataKey={option.key}
                    stroke="#6e6e6e"
                    name={option.name}
                    isAnimationActive={false}
                    activeDot={{
                      r: 6,
                      onMouseEnter: () => handleDotEnter(option.key),
                    }}
                    onMouseMove={() => handleDotEnter(option.key)}
                    strokeOpacity={
                      activeLegend !== ''
                        ? activeLegend === option.key
                          ? 1
                          : 0.3
                        : 1
                    }
                    strokeWidth={
                      activeLegend !== ''
                        ? activeLegend === option.key
                          ? 3
                          : 1
                        : 2
                    }
                  />
                ))}

                <Legend
                  iconSize={18}
                  verticalAlign="bottom"
                  onMouseEnter={handleActiveLegend}
                  onMouseLeave={handleDisableLegend}
                  wrapperStyle={{
                    marginBottom: -12,
                    height: '25%',
                    overflow: 'auto',
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
