import * as PubSub from 'pubsub-js'
import { events, subscribe } from './core'

export interface IMessage {
  type: 'success' | 'error'
  text: string
}

export const sendMessageEvent = (message: IMessage) => {
  PubSub.publish(events.message, message)
}

export const onMessageEvent = (event: (message: IMessage) => void) => {
  return subscribe(events.message, event)
}
