const formatterWithOnlyMonth: { [index: string]: string } = {
  '01': 'Jan',
  '02': 'Fev',
  '03': 'Mar',
  '04': 'Abr',
  '05': 'Mai',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Ago',
  '09': 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez'
}

export const transformToMonthName = (key: string): string => `${formatterWithOnlyMonth[key.replace(/(\d+)\/(\d+)/, '$1')]}-${key.replace(/(\d+)\/(\d+)/, '$2')}`

const monthsCode: { [index: number]: (year: string | number) => string } = {
  1: (year) => `JAN/${year}`,
  2: (year) => `FEV/${year}`,
  3: (year) => `MAR/${year}`,
  4: (year) => `ABR/${year}`,
  5: (year) => `MAI/${year}`,
  6: (year) => `JUN/${year}`,
  7: (year) => `JUL/${year}`,
  8: (year) => `AGO/${year}`,
  9: (year) => `SET/${year}`,
  10: (year) => `OUT/${year}`,
  11: (year) => `NOV/${year}`,
  12: (year) => `DEZ/${year}`
}

export const monthFormatter = (value: string): string => monthsCode[
  Number(value.replace(/(\d+)-(\d)/gi, '$2'))
](value.split('-')[0].replace(/(\d{1,2})(\d{1,2})/gi, '$2'))
