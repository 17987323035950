import { Box } from '@mui/material'
import { Image } from '../../../components'
import { BoxSubtitle, BoxTitle } from './style'
import warningIcon from 'assets/svg/warning.svg'

interface ViewGraphErrorProps {
  message: string
}

export const ViewGraphError = ({ message }: ViewGraphErrorProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Image src={warningIcon} sx={{ height: 90 }} />
      <BoxTitle>Oops…</BoxTitle>
      <BoxSubtitle>{message}</BoxSubtitle>
    </Box>
  )
}
