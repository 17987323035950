import { OrderOptions, RangeOptions, SelectDefaultValue } from '../../../../domain'

export const percentageDataToOrderBy: RangeOptions[] = [
  { id: 1, value: 0, key: 'lessThanThousand', range: '<1.000' },
  { id: 2, value: 0, key: 'betweenThousandAndTenThousand', range: '1.000 - 10.000' },
  { id: 3, value: 0, key: 'betweenTenThousandAndHundredThousand', range: '10.000 - 100.000' },
  { id: 4, value: 0, key: 'betweenHundredThousandAndMillion', range: '100.000 - 1.000.000' },
  { id: 5, value: 0, key: 'betweenOneMillionAndTenMillion', range: '1.000.000 - 10.000.000' },
  { id: 6, value: 0, key: 'biggerThenTenMillion', range: '>10.000.000' }
]

export const percentageOrderOptions: OrderOptions[] = [
  { id: 1, title: 'Menor para o Maior (Range)', order: { by: 'id', type: 'asc' } },
  { id: 2, title: 'Maior para o Menor (Range)', order: { by: 'id', type: 'desc' } },
  { id: 3, title: 'Menor para o Maior (Qtd)', order: { by: 'value', type: 'asc' } },
  { id: 4, title: 'Maior para o Menor (Qtd)', order: { by: 'value', type: 'desc' } }
]

export const orgsOrderOptions: OrderOptions[] = [
  { id: 1, title: 'Menor para o Maior', order: { by: 'invocations', type: 'asc' } },
  { id: 2, title: 'Maior para o Menor', order: { by: 'invocations', type: 'desc' } },
  { id: 3, title: 'Ordem alfabética', order: { by: 'serverName', type: 'asc' } }
]

export const percentageOrderDefault = (): SelectDefaultValue => ({
  label: percentageOrderOptions[0].title,
  value: percentageOrderOptions[0].order
})

export const orgsOrderDefault = (): SelectDefaultValue => ({
  label: orgsOrderOptions[orgsOrderOptions.length - 1].title,
  value: orgsOrderOptions[orgsOrderOptions.length - 1].order
})
