import { Button, ButtonProps, CircularProgress } from '@mui/material'

type LoadingButtonProps = {
  isLoading?: boolean
} & ButtonProps

export const LoadingButton = ({
  onClick,
  isLoading,
  disabled = false,
  children,
  ...rest
}: LoadingButtonProps) => (
  <Button onClick={onClick} disabled={isLoading || disabled} {...rest}>
    {isLoading ? <CircularProgress size={24.5} color="inherit" /> : children}
  </Button>
)
