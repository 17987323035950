import { useEffect, useState } from 'react'
import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { EPriority } from '../../../domain'
import { GroupHeader, GroupItems, StyledFormControl } from './style'

interface SelectInput {
  id: string
  value: string
  priority?: string
}

interface MultiChipAutocompleteProps {
  id: string
  label: string
  options: SelectInput[]
  clearCount: number
  onSelect: (id: string, data: string[] | string) => void
  loading: boolean
}

export const MultiChipFilter = ({
  id,
  label,
  options,
  clearCount,
  onSelect,
  loading,
}: MultiChipAutocompleteProps) => {
  const [valueOption, setValueOption] = useState<SelectInput[]>([])
  const [valueMulti, setValueMulti] = useState<string[]>([])

  useEffect(() => {
    onSelect(id, valueMulti)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueMulti])

  useEffect(() => {
    setValueOption([])
    setValueMulti([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCount])

  return (
    <StyledFormControl size="small">
      <Autocomplete
        id={id}
        multiple
        openOnFocus={true}
        disableCloseOnSelect={true}
        value={valueOption}
        limitTags={5}
        filterSelectedOptions={false}
        getOptionLabel={(option) => option.value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        popupIcon={<KeyboardArrowDown />}
        noOptionsText={loading ? 'Carregando...' : 'Nenhuma opção encontrada!'}
        groupBy={(option) => option.priority ?? option.id}
        options={
          options && options.length > 0
            ? options.sort((a: SelectInput, b: SelectInput) =>
                b.priority && a.priority
                  ? -a.priority.localeCompare(b.priority)
                  : -b.value.localeCompare(a.value),
              )
            : []
        }
        onChange={(event, selectedValues) => {
          setValueOption(selectedValues)
          setValueMulti(selectedValues.map((item) => item.id))
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.value}
          </li>
        )}
        renderGroup={(params) => {
          return (
            <li key={params.key}>
              {params.group &&
              EPriority[params.group as keyof typeof EPriority] ? (
                <GroupHeader
                  onClick={() => {
                    const groupSelected: SelectInput[] = options.filter(
                      (item) => item.priority === params.group,
                    )
                    setValueOption(groupSelected)
                    setValueMulti(groupSelected.map((item) => item.id))
                  }}
                >
                  {EPriority[params.group as keyof typeof EPriority]}
                </GroupHeader>
              ) : (
                ''
              )}
              <GroupItems key={`children-${params.key}`}>
                {params.children}
              </GroupItems>
            </li>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              color="secondary"
              size="small"
              sx={{
                '.MuiInputBase-root': {
                  boxShadow: '0px 3px 6px #00000029',
                  border: '#E0E0E0',
                  borderRadius: '10px',
                  color: '#707070',
                  paddingBlock: '6.5px !important',
                  paddingLeft: '14px !important',
                  minHeight: '51px !important',
                  flexWrap: 'wrap',
                },
                '.MuiAutocomplete-input': {
                  paddingLeft: '3px !important',
                  width: '30% !important',
                  padding: '0px !important',
                  height: '0px',
                },
                '.MuiInputLabel-shrink': {
                  top: '0px !important',
                },
                '.MuiInputLabel-root': {
                  top: '5px',
                },
                '.Mui-focused .MuiAutocomplete-inputFocused': {
                  display: 'block',
                  height: 'auto',
                },
                '.Mui-focused > p': {
                  maxWidth: '60%',
                },
                '&:hover': {
                  '&& fieldset': {
                    borderColor: 'secondary.main',
                    color: '#707070',
                  },
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )
        }}
        renderTags={(value: SelectInput[], getTagProps) =>
          value.map((option: SelectInput, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              color="secondary"
              variant="filled"
              label={option.value}
              {...getTagProps({ index })}
            />
          ))
        }
        ListboxProps={{
          style: {
            maxHeight: 225,
          },
        }}
      />
    </StyledFormControl>
  )
}
