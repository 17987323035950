import { Box, CardContent, IconButton, Typography } from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import { IAvarageCallsServiceDeskGraph, ICustomTooltip } from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import { formatDate, formatToLocaleString } from '../../../utils'

interface InfrastructureAvarageCallsGraphProps {
  dataGraph: IAvarageCallsServiceDeskGraph[]
  isLoading?: boolean
  isError?: boolean
  isMobile?: boolean
}

const headerCSV = [
  { label: 'Período', key: 'mes' },
  { label: 'Média diária', key: 'atendimentos_dia' },
]

export const InfrastructureAvarageCallsGraph = ({
  dataGraph,
  isLoading,
  isError,
  isMobile,
}: InfrastructureAvarageCallsGraphProps) => {
  const [dataShow, setDataShow] = useState<IAvarageCallsServiceDeskGraph[]>([])
  const [dataCSV, setDataCSV] = useState<IAvarageCallsServiceDeskGraph[]>([])
  const [maxDomainYAsis, setMaxDomainYAsis] = useState<number>(0)

  const CustomTooltip = ({ payload }: ICustomTooltip) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography textTransform="uppercase">
          {formatDate(`01/${payload[0].payload.mes}`, 'MMM/yyyy', true)}
        </Typography>
        <Typography>
          Média diária:{' '}
          {formatToLocaleString(payload[0].payload.atendimentos_dia)}
        </Typography>
      </TooltipGraph>
    )
  }

  const handleDataShow = (data: IAvarageCallsServiceDeskGraph[]) => {
    if (!data || data.length === 0) {
      setDataShow([])
      setDataCSV([])
      return
    }

    const tempData: IAvarageCallsServiceDeskGraph[] = []

    const maxValue = data.reduce(function (prev, current) {
      if (prev.atendimentos_dia && current.atendimentos_dia) {
        return Number.parseInt(prev.atendimentos_dia.toString()) >
          Number.parseInt(current.atendimentos_dia.toString())
          ? prev
          : current
      } else {
        return prev
      }
    })

    setMaxDomainYAsis(Number.parseInt(maxValue.atendimentos_dia.toString()))

    data.forEach((el: IAvarageCallsServiceDeskGraph) => {
      tempData.push({
        mes: el.mes,
        atendimentos_dia: Number.parseFloat(
          el.atendimentos_dia.toString(),
        ).toFixed(2),
      })
    })

    tempData.sort((a, b) => {
      const dateA = new Date(`01/${a.mes}`)
      const dateB = new Date(`01/${b.mes}`)

      if (dateA < dateB) {
        return -1
      } else if (dateA > dateB) {
        return 1
      } else {
        return 0
      }
    })

    setDataShow(tempData)
    setDataCSV(tempData)
  }

  useEffect(() => {
    handleDataShow(dataGraph)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Média de atendimentos por dia no Service Desk"
        action={
          <>
            {!isLoading && !isError && dataCSV.length > 0 ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="media-diaria-chamados-service-desk"
                    headers={headerCSV}
                    data={dataCSV}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow || dataShow.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={dataShow}
                margin={{
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
                barGap={0}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  dataKey="mes"
                  type="category"
                  tickLine={false}
                  interval="preserveStartEnd"
                  padding={{ left: 2, right: 2 }}
                  tickFormatter={(value) =>
                    `${formatDate(
                      `01/${value}`,
                      'MMM/yyyy',
                      true,
                    )}`.toUpperCase()
                  }
                />
                <YAxis
                  type="number"
                  tickLine={false}
                  tickMargin={12}
                  domain={[0, maxDomainYAsis]}
                  padding={{ top: 26 }}
                  tickFormatter={(value) => `${formatToLocaleString(value)}`}
                />
                <Bar
                  dataKey="atendimentos_dia"
                  name="atendimentos_dia"
                  fill="#639B48"
                  isAnimationActive={false}
                >
                  {!isMobile && (
                    <LabelList
                      dataKey="atendimentos_dia"
                      fill="#000000de"
                      position="top"
                      formatter={(value: string | number | undefined) =>
                        `${formatToLocaleString(value)}`
                      }
                    />
                  )}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
