import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { theme } from './themes/default'
import { AppRouter } from './Router'
import { UserProvider } from '../context/user'

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <UserProvider>
      <AppRouter />
    </UserProvider>
  </ThemeProvider>
)

export { App }
