import { Box, CardContent, IconButton, Typography } from '@mui/material'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import { ICustomTooltip, IStatusCallsServiceDeskGraph } from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import { formatDate } from '../../../utils'

interface InfrastructureStatusGraphProps {
  dataGraph: IStatusCallsServiceDeskGraph
  isLoading?: boolean
  isError?: boolean
  isMobile?: boolean
}

interface IInfrastructureStatusCallsGraphShow {
  axisX: string
  opened: number
  closed: number
  remaining: number
}

const headerCSV = [
  { label: 'Período', key: 'axisX' },
  { label: 'Chamados abertos', key: 'opened' },
  { label: 'Chamados fechados', key: 'closed' },
  { label: 'Estoque de chamados', key: 'remaining' },
]

const series = [
  {
    key: 'opened',
    description: 'Chamados abertos',
    name: 'Abertos',
    type: 'bar',
    color: '#639B48',
  },
  {
    key: 'closed',
    description: 'Chamados encerrados',
    name: 'Fechados',
    type: 'bar',
    color: '#2a732a',
  },
  {
    key: 'remaining',
    description: 'Estoque de chamados',
    name: 'Estoque',
    type: 'line',
    color: '#666666',
  },
]

const legendStatus: any = {
  opened:
    'Retorna a quantidade de chamados abertos para o Service Desk no intervalo de tempo',
  closed:
    'Retorna a quantidade de chamados encerrados (concluídos) pelo Service Desk no intervalo de tempo',
  remaining:
    'Quantidade de chamados não encerrados ou cancelados até o fim do período indicado',
}

export const InfrastructureStatusCallsGraph = ({
  dataGraph,
  isLoading,
  isError,
  isMobile,
}: InfrastructureStatusGraphProps) => {
  const [dataShow, setDataShow] = useState<
    IInfrastructureStatusCallsGraphShow[]
  >([])
  const [activeLegend, setActiveLegend] = useState<string>('')
  const [dataCSV, setDataCSV] = useState<IInfrastructureStatusCallsGraphShow[]>(
    [],
  )

  const handleActiveLegend = ({ dataKey }: any) => {
    setActiveLegend(dataKey)
  }

  const handleDisableLegend = () => {
    setActiveLegend('')
  }

  const CustomTooltip = ({ payload }: ICustomTooltip) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography textTransform="uppercase">
          {formatDate(payload[0].payload.axisX, 'MMM/yyyy', true)}
        </Typography>
        <Typography>
          Chamados abertos no período: {payload[0].payload.opened}
        </Typography>
        <Typography>
          Chamados fechados no período: {payload[0].payload.closed}
        </Typography>
        <Typography>
          Estoque de chamados até o fim do período:{' '}
          {payload[0].payload.remaining}
        </Typography>
      </TooltipGraph>
    )
  }

  const handleDataShow = (data: IStatusCallsServiceDeskGraph) => {
    if (!data) {
      setDataCSV([])
      setDataShow([])
      return
    }

    const tempData: IInfrastructureStatusCallsGraphShow[] = []

    for (const [key, value] of Object.entries(data)) {
      const dataRow: any | IInfrastructureStatusCallsGraphShow = {
        axisX: key,
        opened: 0,
        closed: 0,
        remaining: 0,
      }

      series.forEach((serie) => {
        const element = value.find((el) => el.statusvaluecaption === serie.name)

        if (element) {
          dataRow[serie.key] = parseInt((element.total as string) ?? 0)
        }
      })

      tempData.push(dataRow)
    }

    tempData.sort((a, b) => {
      const dateA = new Date(`01/${a.axisX}`)
      const dateB = new Date(`01/${b.axisX}`)

      if (dateA < dateB) {
        return -1
      } else if (dateA > dateB) {
        return 1
      } else {
        return 0
      }
    })

    setDataShow(tempData)
    setDataCSV(tempData)
  }

  useEffect(() => {
    handleDataShow(dataGraph)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Relação entre Chamados Abertos e Fechados no Service Desk"
        action={
          <>
            {!isLoading && !isError && dataCSV.length > 0 ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="atendimentos-service-desk"
                    headers={headerCSV}
                    data={dataCSV}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow || dataShow.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
              height: 420,
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={dataShow}
                stackOffset="expand"
                margin={{
                  top: 6,
                }}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  dataKey="axisX"
                  domain={['auto', 'auto']}
                  tickMargin={12}
                  type="category"
                  strokeWidth={1}
                  tickFormatter={(value) =>
                    `${formatDate(
                      `01/${value}`,
                      'MMM/yyyy',
                      true,
                    )}`.toUpperCase()
                  }
                />
                <YAxis
                  type="number"
                  domain={['auto', 'auto']}
                  interval={0}
                  padding={{ top: 23 }}
                  allowDataOverflow={true}
                />

                {series.map((option) =>
                  option.type === 'line' ? (
                    <Line
                      key={option.key}
                      type="monotone"
                      dataKey={option.key}
                      name={option.name}
                      stroke={option.color}
                      legendType="plainline"
                      dot={false}
                      isAnimationActive={false}
                      strokeOpacity={
                        activeLegend !== ''
                          ? activeLegend === option.key
                            ? 1
                            : 0.3
                          : 1
                      }
                      strokeWidth={
                        activeLegend !== ''
                          ? activeLegend === option.key
                            ? 3
                            : 1
                          : 2
                      }
                    />
                  ) : (
                    <Bar
                      key={option.key}
                      dataKey={option.key}
                      name={option.name}
                      fill={option.color}
                      opacity={
                        activeLegend !== ''
                          ? activeLegend === option.key
                            ? 1
                            : 0.3
                          : 1
                      }
                    />
                  ),
                )}
                <Legend
                  iconSize={18}
                  verticalAlign="bottom"
                  wrapperStyle={{ marginBottom: -12 }}
                  onMouseEnter={handleActiveLegend}
                  onMouseLeave={handleDisableLegend}
                />
              </ComposedChart>
            </ResponsiveContainer>
            <Box sx={{ mt: 4 }}>
              {series.map((data) => {
                if (!legendStatus[data.key]) return null

                return (
                  <Box key={legendStatus[data.key]} lineHeight={1}>
                    <Typography
                      fontSize={12}
                      fontWeight="bold"
                      component="span"
                    >
                      {data.description}:{' '}
                    </Typography>
                    <Typography fontSize={12} component="span">
                      {legendStatus[data.key]}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
