import _, { isArray } from 'lodash'
import {
  Bar,
  ComposedChart,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Typography, useTheme } from '@mui/material'
import { SelectDefaultValue } from 'domain/'
import { checkInstitutionsListGraphsStatus } from 'utils/checkDataToShowGraph'
import { ChartContainer, Container } from './styles'
import { ViewDataError, ViewDataLoading, TooltipGraph } from 'components'
import { numberDecimalFormat } from 'utils/numberDecimalFormat'
import { formatToLocaleString } from 'utils/parseNumber'

interface ErrorsCallsPageFourProps {
  data: any
  chartOrder: SelectDefaultValue
  mobile: boolean
  loading: boolean
}

const ErrorsCallsPageFour = ({
  data,
  chartOrder,
  mobile,
  loading,
}: ErrorsCallsPageFourProps) => {
  const theme = useTheme()
  const filteredData = isArray(data)
    ? data?.filter((item: any) => item.errors > 0)
    : []
  const status = checkInstitutionsListGraphsStatus(data, loading, filteredData)

  const PercentLabel = ({ x, y, width, value }: LabelProps) => {
    const _value = numberDecimalFormat(value, 0)
    const _plus =
      _value.length === 2
        ? 20
        : _value.length === 3
        ? 25
        : _value.length === 4
        ? 30
        : 15
    const _x = Number(x) + Number(width) + _plus
    const _y = Number(y) + 11

    return (
      <g>
        <text
          x={_x}
          y={_y}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {_value}
        </text>
      </g>
    )
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <TooltipGraph>
          <Typography>Sociedade: {payload[0].payload.serverName}</Typography>
          <Typography>
            Total chamadas: {formatToLocaleString(payload[0].value)}
          </Typography>
        </TooltipGraph>
      )
    }
    return null
  }

  return (
    <>
      {loading && <ViewDataLoading />}

      {!loading && (!data || data.length === 0 || status === 'NODATA') && (
        <ViewDataError />
      )}

      {status === 'HASDATA' && (
        <Container sx={{ overflow: 'hidden', height: 380 }}>
          <ChartContainer>
            <ResponsiveContainer
              minWidth={500}
              minHeight={
                filteredData.length > 4 ? filteredData.length * 70 : 280
              }
            >
              <ComposedChart
                layout="vertical"
                data={_.orderBy(
                  filteredData.map((item: any) => ({ ...item, line: 95 })),
                  [chartOrder.value.by],
                  [chartOrder.value.type],
                )}
              >
                <XAxis
                  type="number"
                  hide={true}
                  domain={[0, (dataMax: number) => dataMax * 1.5]}
                  scale="sqrt"
                />
                <YAxis
                  dataKey="serverName"
                  type="category"
                  width={200}
                  tickLine={false}
                  interval={0}
                  fontSize={12}
                />
                <Bar
                  isAnimationActive={false}
                  dataKey="errors"
                  fill={theme.palette.primary.light}
                  barSize={20}
                >
                  <LabelList
                    dataKey="errors"
                    position="right"
                    content={PercentLabel}
                  />
                </Bar>
                {!mobile && <Tooltip content={<CustomTooltip />} />}
              </ComposedChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Container>
      )}
    </>
  )
}

export default ErrorsCallsPageFour
