import { FormControl, FormControlProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledFormControl = styled(FormControl)<FormControlProps>({
  marginBlock: 8,
  marginInline: 0,
  width: '100%',
  border: 'none',
  background: '#ffffff',
})

export const GroupHeader = styled('div')(({ theme }) => ({
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  cursor: 'pointer',
}))

export const GroupItems = styled('ul')({
  padding: 0,
  overflowWrap: 'anywhere',
})
