import { useEffect, useState } from 'react'
import {
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { StyledFormControl } from './style'

interface SelectInput {
  id: string
  value: string
}

interface MultiSelectProps {
  id: string
  label: string
  options: SelectInput[]
  clearCount: number
  onSelect: (id: string, data: string[] | string) => void
}

export const MultiSelect = ({
  id,
  label,
  options,
  clearCount,
  onSelect,
}: MultiSelectProps) => {
  const [valueMulti, setValueMulti] = useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof valueMulti>) => {
    const {
      target: { value },
    } = event

    setValueMulti(
      typeof value === 'string'
        ? value.split(',')
        : value.includes('all')
        ? []
        : value,
    )
  }

  const handleSelectedOptions = (selected: string[]) => {
    const selectedOptions = options.filter((obj) => {
      return selected.includes(obj.id)
    })

    const renderedSelectedOptions = selectedOptions.map((obj) => obj.value)

    return renderedSelectedOptions.join(', ')
  }

  useEffect(() => {
    onSelect(id, valueMulti)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueMulti])

  useEffect(() => {
    setValueMulti([])
  }, [clearCount])

  return (
    <StyledFormControl size="small">
      <InputLabel color="secondary">{label}</InputLabel>
      <Select
        id={id}
        color="secondary"
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => handleSelectedOptions(selected)}
        onChange={handleChange}
        IconComponent={KeyboardArrowDown}
        value={valueMulti}
        multiple
        sx={{
          boxShadow: '0px 3px 6px #00000029',
          border: '#E0E0E0',
          borderRadius: '10px',
          color: '#707070',
          letterspacing: '0px',
          '&:hover': {
            '&& fieldset': {
              borderColor: 'secondary.main',
              color: '#707070',
            },
          },
        }}
        MenuProps={{
          style: {
            maxHeight: 250,
            maxWidth: 'fit-content',
          },
        }}
      >
        <MenuItem
          key="all"
          value="all"
          sx={{
            paddingInlineEnd: 0,
            '&.Mui-focusVisible': { backgroundColor: 'transparent !important' },
          }}
        >
          <Typography variant="inherit" noWrap>
            Todos
          </Typography>
        </MenuItem>
        {options && options.length > 0
          ? options.map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                sx={{ paddingInlineEnd: 0 }}
              >
                <Typography variant="inherit" title={option.value} noWrap>
                  {option.value}
                </Typography>
              </MenuItem>
            ))
          : ''}
      </Select>
    </StyledFormControl>
  )
}
