import { useContext, useEffect, useState } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import {
  IOrganization,
  IFilter,
  IEndpoint,
  IStage,
  IStatus,
} from '../../../domain'
import { DateRangePicker, MultiChipAutocomplete } from '../../../components'
import { UserContext } from '../../../context/user'

interface FilterDynamicProps {
  parentTransmitters?: IOrganization[] | []
  parentReceivers?: IOrganization[] | []
  transmitters?: IOrganization[] | []
  receivers?: IOrganization[] | []
  channels?: IOrganization[] | []
  endpoints?: IEndpoint[] | []
  statuses?: IStatus[] | []
  stages?: IStage[] | []
  onChange: (value: IFilter) => void
}

export const ToolFilterDynamic = ({
  parentTransmitters,
  parentReceivers,
  transmitters,
  receivers,
  channels,
  endpoints,
  statuses,
  stages,
  onChange,
}: FilterDynamicProps) => {
  const { user, updateLoading } = useContext(UserContext)
  const [clearCount, setClearCount] = useState<number>(0)
  const [hasEndPointsSelected, setHasEndPointsSelected] = useState(false)
  const [filters, setFilters] = useState<IFilter>({
    parentTransmitters: [],
    parentReceivers: [],
    transmitters: [],
    receivers: [],
    channels: [],
    endpoints: [],
    statuses: [],
    stages: [],
    interval: [],
  })

  const handleInputs = (id: string, data: string[] | string) => {
    updateLoading({ name: 'data', loading: true })
    setFilters((prevState) => ({ ...prevState, [id]: data }))
  }

  const handleClear = () => {
    updateLoading({ name: 'data', loading: true })
    setFilters({
      parentTransmitters: [],
      parentReceivers: [],
      transmitters: [],
      receivers: [],
      channels: [],
      endpoints: [],
      statuses: [],
      stages: [],
      interval: [],
    })
    setClearCount((prevState) => prevState + 1)
  }

  useEffect(() => {
    onChange(filters)

    const hasEndpoints = filters.endpoints.some((filter) => !!filter)
    const hasStages = filters.stages.some((filter) => !!filter)

    setHasEndPointsSelected(hasEndpoints && !hasStages)

    return () => {
      setFilters((prevState) => prevState)
      setHasEndPointsSelected(true)
    }
  }, [endpoints, filters, hasEndPointsSelected, onChange, stages])

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} sx={{ padding: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={4} lg={user.name ? 4 : 3}>
          <DateRangePicker
            id="interval"
            label="Intervalo"
            clearCount={clearCount}
            onSelect={handleInputs}
          />
        </Grid>

        {user.name && (
          <>
            {parentTransmitters && (
              <Grid item xs={12} md={4} lg={user.name ? 4 : 3}>
                <MultiChipAutocomplete
                  id="parentTransmitters"
                  label="Conglomerado transmissor"
                  options={parentTransmitters ?? []}
                  clearCount={clearCount}
                  onSelect={handleInputs}
                />
              </Grid>
            )}

            {parentReceivers && (
              <Grid item xs={12} md={4} lg={user.name ? 4 : 3}>
                <MultiChipAutocomplete
                  id="parentReceivers"
                  label="Conglomerado receptor"
                  options={parentReceivers ?? []}
                  clearCount={clearCount}
                  onSelect={handleInputs}
                />
              </Grid>
            )}

            {transmitters && (
              <Grid item xs={12} md={4} lg={user.name ? 4 : 3}>
                <MultiChipAutocomplete
                  id="transmitters"
                  label="Marca transmissora"
                  options={
                    transmitters.filter((transmitter) =>
                      filters.parentTransmitters
                        .join(',')
                        .includes(transmitter.id),
                    ) ?? []
                  }
                  clearCount={clearCount}
                  onSelect={handleInputs}
                  disabled={
                    !transmitters || filters.parentTransmitters.length === 0
                  }
                  hasOptionControls={true}
                />
              </Grid>
            )}

            {receivers && (
              <Grid item xs={12} md={4} lg={user.name ? 4 : 3}>
                <MultiChipAutocomplete
                  id="receivers"
                  label="Marca receptora"
                  options={
                    receivers.filter((receiver) => {
                      const receiverIds = receiver.id.split(',')
                      return receiverIds.some((receiverId) =>
                        filters.parentReceivers.some((parentReceiver) =>
                          parentReceiver.includes(receiverId),
                        ),
                      )
                    }) ?? []
                  }
                  clearCount={clearCount}
                  onSelect={handleInputs}
                  disabled={!receivers || filters.parentReceivers.length === 0}
                  hasOptionControls={true}
                />
              </Grid>
            )}

            {channels && (
              <Grid item xs={12} md={4} lg={user.name ? 4 : 3}>
                <MultiChipAutocomplete
                  id="channels"
                  label="Canais da Marca receptora"
                  options={
                    channels.filter((channel) =>
                      filters.receivers.join(',').includes(channel.id),
                    ) ?? []
                  }
                  clearCount={clearCount}
                  onSelect={handleInputs}
                  disabled={!channels || filters.receivers.length === 0}
                  hasOptionControls={true}
                />
              </Grid>
            )}
          </>
        )}

        {stages && (
          <Grid item xs={12} md={4} lg={user.name ? 4 : 3}>
            <MultiChipAutocomplete
              id="stages"
              label="Fase de Implementação"
              options={stages.filter((stage) => {
                const selected = endpoints
                  ?.filter((endpoint) =>
                    filters.endpoints.includes(endpoint.id),
                  )
                  .map((endpoint) => {
                    const regex = /\d+/g
                    return endpoint.value.split('-')[0].match(regex)
                  })

                const regex = new RegExp(`${selected?.flat().join('|')}`)

                return regex.test(stage.value)
              })}
              clearCount={clearCount}
              onSelect={handleInputs}
              helperText={hasEndPointsSelected ? 'Selecione a fase' : ''}
            />
          </Grid>
        )}

        {endpoints && (
          <Grid item xs={12} md={4} lg={user.name ? 4 : 3}>
            <MultiChipAutocomplete
              id="endpoints"
              label="Endpoint"
              options={endpoints}
              clearCount={clearCount}
              onSelect={handleInputs}
            />
          </Grid>
        )}

        {statuses && (
          <Grid item xs={12} md={4} lg={user.name ? 4 : 3}>
            <MultiChipAutocomplete
              id="statuses"
              label="Status Code"
              options={statuses}
              clearCount={clearCount}
              onSelect={handleInputs}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'right' },
            maxHeight: '83px',
          }}
        >
          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleClear}
          >
            Limpar filtros
          </Typography>
        </Grid>
      </Grid>
      <Grid></Grid>
    </Container>
  )
}
