import { useCallback, useEffect, useState } from 'react'
import {
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { GraphContent, GraphDetails } from './styles'
import {
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipInfo,
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
} from '../../../components'
import { getMirrorReport, SubmitParams } from '../../../services'
import { formatToLocaleString, numberDecimalFormat } from '../../../utils'
import { FilterValues } from '../../../domain'

interface AggregateKeyPerformancedataProps {
  filter: FilterValues | undefined
}

interface AggregateKeyPerformancedataData {
  averageAvailability: number
  averageResponseTime: number
  rejectedCalls: number
  successfulApiCallsAbsolut: number
  successfulApiCallsPorcent: number
  rejectedCallsAbsolut: number
}

export const AggregateKeyPerformanceMetricsGraph = ({
  filter,
}: AggregateKeyPerformancedataProps) => {
  const theme = useTheme()
  const [data, setData] = useState<
    AggregateKeyPerformancedataData | any | undefined
  >()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState({
    status: false,
    message: '',
  })

  const headerCSV = [
    { label: 'Status', key: 'tipo' },
    { label: 'Requisições', key: 'valor' },
  ]

  const makeCanvas = useCallback(() => {
    const canvas: any = document.getElementById('key-performance-data-canvas')

    if (canvas) {
      const ctx = canvas.getContext('2d')
      const cX = Math.floor(canvas.width / 2)
      const cY = Math.floor(canvas.height / 2)
      const radius = Math.min(cX, cY) * 0.75
      let totalArc = 0.53
      const data = [33.33, 33.33, 33.33]
      const colors = [
        theme.palette.primary.light,
        theme.palette.primary.main,
        theme.palette.grey[800],
      ]

      const drawWedge = (percent: number, color: string): void => {
        const arcRadians = ((percent / 100) * 360 * Math.PI) / 180
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(cX, cY)
        ctx.arc(cX, cY, radius, totalArc, totalArc + arcRadians, false)
        ctx.closePath()
        ctx.fillStyle = color
        ctx.fill()
        ctx.restore()
        totalArc += arcRadians
      }

      for (let i = 0; i < data.length; i++) {
        drawWedge(data[i], colors[i])
      }

      // Círculo branco do meio
      ctx.beginPath()
      ctx.fillStyle = theme.palette.common.white
      ctx.arc(cX, cY, radius * 0.75, 0, 2 * Math.PI, false)
      ctx.fill()
      ctx.closePath()

      // Linha 1
      ctx.beginPath()
      ctx.strokeStyle = theme.palette.common.white
      ctx.moveTo(cX, cY)
      ctx.lineWidth = 2
      ctx.lineTo(cX, 0)
      ctx.stroke()
      ctx.closePath()

      // Linha 2
      ctx.beginPath()
      ctx.strokeStyle = theme.palette.common.white
      ctx.moveTo(cX, cY)
      ctx.lineWidth = 2
      ctx.lineTo(0, canvas.height / 1.27)
      ctx.stroke()
      ctx.closePath()

      // Linha 3
      ctx.beginPath()
      ctx.strokeStyle = theme.palette.common.white
      ctx.moveTo(cX, cY)
      ctx.lineWidth = 2
      ctx.lineTo(canvas.width * 1.37, canvas.height)
      ctx.stroke()
      ctx.closePath()

      // Texto FALHAS
      ctx.save()
      ctx.translate(cX / 2.2, cY / 1.5)
      ctx.rotate((Math.PI / 180) * -60)
      ctx.strokeStyle = theme.palette.common.white
      ctx.lineWidth = 1
      ctx.fillStyle = theme.palette.common.white
      ctx.font = '18px sans-serif'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.strokeText('FALHAS', 0, 0)
      ctx.fillText('FALHAS', 0, 0)
      ctx.restore()

      // Texto RESPOSTA
      ctx.save()
      ctx.translate(cX * 1.56, cY / 1.4)
      ctx.rotate((Math.PI / 180) * 62)
      ctx.strokeStyle = theme.palette.secondary.contrastText
      ctx.lineWidth = 1
      ctx.fillStyle = theme.palette.secondary.contrastText
      ctx.font = '18px sans-serif'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.strokeText('RESPOSTA', 0, 0)
      ctx.fillText('RESPOSTA', 0, 0)
      ctx.restore()

      // Texto SUCESSO
      ctx.save()
      ctx.strokeStyle = theme.palette.common.white
      ctx.lineWidth = 1
      ctx.fillStyle = theme.palette.common.white
      ctx.font = '18px sans-serif'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.strokeText('SUCESSO', cX, cY * 1.65)
      ctx.fillText('SUCESSO', cX, cY * 1.65)
      ctx.restore()
    }
  }, [
    theme.palette.common.white,
    theme.palette.grey,
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.secondary.contrastText,
  ])

  useEffect(() => {
    makeCanvas()
  }, [data, makeCanvas])

  const getDashData = useCallback((dateData: SubmitParams): void => {
    getMirrorReport({ ...dateData, type: 'performanceMetrics' })
      .then((data: any) => {
        setData(data)
        setLoading(false)
      })
      .catch((err: any) => setError({ status: true, message: err.message }))
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (filter && filter.interval.length > 0) {
      const { interval, parentTransmitters, transmitters } = filter

      getDashData({
        firstDate: interval[0],
        lastDate: interval[1],
        parentTransmitters,
        transmitters,
      })
    }
  }, [getDashData, filter])

  const getDataToExportCSV = (): any => {
    if (data) {
      return Object.keys(data).map((key) => {
        if (key === 'rejectedCalls') {
          return { tipo: 'Chamadas de falhas das APIs (%)', valor: data[key] }
        }
        if (key === 'rejectedCallsAbsolut') {
          return {
            tipo: 'Chamadas de falhas das APIs (absoluto)',
            valor: data[key],
          }
        }
        if (key === 'averageAvailability') {
          return {
            tipo: 'Disponibilidade média das APIs (%)',
            valor: data[key],
          }
        }
        if (key === 'averageResponseTime') {
          return {
            tipo: 'Tempo médio de resposta das APIs (milissegundos)',
            valor: data[key],
          }
        }
        if (key === 'successfulApiCallsPorcent') {
          return { tipo: 'Chamadas de sucesso das APIs (%)', valor: data[key] }
        }
        return {
          tipo: 'Chamadas de sucesso das APIs (absoluto)',
          valor: data[key],
        }
      })
    }

    return ''
  }

  return (
    <Grid item xs={12}>
      <CardBox
        sx={{
          minHeight: 60,
        }}
      >
        <CardBoxTitle
          title="Panorama do Open Insurance"
          action={
            <>
              {!loading && Object.keys(data).length > 0 ? (
                <TooltipInfo
                  title="Fazer download do arquivo CSV"
                  arrow
                  placement="right-end"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="download">
                    <IconCsvDownloader
                      filename="principais-metricas-performance"
                      headers={headerCSV}
                      data={getDataToExportCSV()}
                    />
                  </IconButton>
                </TooltipInfo>
              ) : (
                ''
              )}
            </>
          }
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
          }}
        />

        {!data && loading ? (
          <ViewDataLoading />
        ) : error.status ? (
          <ViewDataError />
        ) : !data?.rejectedCalls &&
          !data?.rejectedCallsAbsolut &&
          !data?.averageAvailability &&
          !data?.successfulApiCallsPorcent &&
          !data?.successfulApiCallsAbsolut ? (
          <ViewDataEmpty />
        ) : (
          <CardContent
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <GraphDetails
              color={theme.palette.primary.contrastText}
              background={theme.palette.primary.main}
              right="63%"
            >
              <Typography variant="h1">
                {formatToLocaleString(data?.rejectedCalls ?? 0)}%
              </Typography>
              <Typography fontSize={12}>Chamadas de falhas das APIs</Typography>
              <Typography fontSize={10}>(%)</Typography>
            </GraphDetails>

            <GraphDetails
              color={theme.palette.primary.contrastText}
              background={theme.palette.primary.main}
              right="68%"
              top="40%"
            >
              <Typography variant="h1">
                {numberDecimalFormat(data?.rejectedCallsAbsolut ?? 0, 0)}
              </Typography>
              <Typography fontSize={12}>Chamadas de falhas das APIs</Typography>
              <Typography fontSize={10}>(absoluto)</Typography>
            </GraphDetails>

            <GraphDetails
              color={theme.palette.secondary.contrastText}
              background={theme.palette.grey[800]}
              left="63%"
            >
              <Typography variant="h1">
                {formatToLocaleString(data?.averageAvailability ?? 0, 0)}%
              </Typography>
              <Typography fontSize={12}>
                Disponibilidade média das APIs
              </Typography>
              <Typography fontSize={10}>
                (%)<sup>1</sup>
              </Typography>
            </GraphDetails>

            <GraphDetails
              color={theme.palette.secondary.contrastText}
              background={theme.palette.grey[800]}
              left="68%"
              top="40%"
            >
              <Typography variant="h1">
                {formatToLocaleString(data?.averageResponseTime ?? 0, 0, 0)}
              </Typography>
              <Typography fontSize={12}>
                Tempo médio de resposta das APIs
              </Typography>
              <Typography fontSize={10}>
                (milissegundos)<sup>2</sup>
              </Typography>
            </GraphDetails>

            <GraphDetails
              color={theme.palette.primary.contrastText}
              background={theme.palette.primary.light}
              left="63%"
              bottom="15px"
            >
              <Typography variant="h1">
                {formatToLocaleString(data?.successfulApiCallsPorcent ?? 0)}%
              </Typography>
              <Typography fontSize={12}>
                Chamadas de sucesso das APIs
              </Typography>
              <Typography fontSize={10}>(%)</Typography>
            </GraphDetails>

            <GraphDetails
              color={theme.palette.primary.contrastText}
              background={theme.palette.primary.light}
              right="63%"
              bottom="15px"
            >
              <Typography variant="h1">
                {numberDecimalFormat(data?.successfulApiCallsAbsolut ?? 0, 0)}
              </Typography>
              <Typography fontSize={12}>
                Chamadas de sucesso das APIs
              </Typography>
              <Typography fontSize={10}>(absoluto)</Typography>
            </GraphDetails>

            <GraphContent>
              <Typography variant="h1" color="primary">
                Principais métricas de performance
              </Typography>

              <canvas
                id="key-performance-data-canvas"
                width="500"
                height="500"
              ></canvas>
            </GraphContent>
          </CardContent>
        )}
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'flex-start',
          }}
        >
          <Typography
            fontSize={12}
            sx={{
              width: { xs: '100%', sm: '50%' },
            }}
          >
            1. A disponibilidade média das APIs deve satisfazer os seguintes
            requisitos mínimos: (i) 95,0% do tempo a cada 24 horas; e (ii) 99,5%
            do tempo a cada 3 meses.
          </Typography>
          <Typography
            fontSize={12}
            sx={{
              width: { xs: '100%', sm: '50%' },
              marginLeft: { xs: '0px !important', sm: 8 },
              marginTop: { xs: 2, sm: 0 },
            }}
          >
            2. O desempenho na resposta das APIs deverá manter o percentil 95
            dos seguintes tempos máximos de resposta: (i) 1.000ms para APIs de
            alta prioridade; (ii) 1.500ms para APIs de média prioridade; e (iii)
            4.000ms para APIs administrativas.
          </Typography>
        </CardActions>
      </CardBox>
    </Grid>
  )
}
