import _ from 'lodash'
import { useEffect, useState } from 'react'
import {
  Bar,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useTheme } from '@mui/material'
import { Container } from './styles'
import {
  ViewDataError,
  ViewDataLoading,
  TooltipGraph,
} from '../../../../../components'
import { ICustomTooltip, SelectDefaultValue } from '../../../../../domain'

interface AverageAvailabilityPageOneProps {
  data: any
  chartOrder: SelectDefaultValue
  mobile: boolean
  loading: boolean
}

const AverageAvailabilityPageOne = ({
  data,
  chartOrder,
  mobile,
  loading,
}: AverageAvailabilityPageOneProps) => {
  const theme = useTheme()
  const [isError, setError] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)

  const CustomTooltip = ({ active, payload }: ICustomTooltip) => {
    if (active && payload && payload.length) {
      return <TooltipGraph>Qtd de sociedades: {payload[0].value}</TooltipGraph>
    }
    return null
  }

  useEffect(() => {
    setError(
      data?.every((element: any, index: any) => {
        if (element.value === undefined) return true
        else return false
      }),
    )
    setIsEmpty(data?.filter((item: any) => item.value > 0).length === 0)
  }, [data])

  return (
    <>
      {loading && <ViewDataLoading />}

      {!loading && (!data || data.length === 0 || isError || isEmpty) && (
        <ViewDataError />
      )}

      {data && data.length > 0 && !loading && !isError && !isEmpty && (
        <Container sx={{ overflow: 'hidden' }}>
          <ResponsiveContainer height={380}>
            <ComposedChart
              layout="vertical"
              width={500}
              data={_.orderBy(
                data,
                [chartOrder.value.by],
                [chartOrder.value.type],
              )}
            >
              <XAxis
                type="number"
                hide={true}
                domain={[0, (dataMax: number) => dataMax * 1.2]}
              />
              <YAxis
                dataKey="range"
                type="category"
                width={100}
                tickLine={false}
              />
              <Bar
                isAnimationActive={false}
                dataKey="value"
                barSize={40}
                fill={theme.palette.primary.light}
              >
                <LabelList dataKey="value" position="right" fill="#000" />
              </Bar>
              {!mobile && <Tooltip content={<CustomTooltip />} />}
            </ComposedChart>
          </ResponsiveContainer>
        </Container>
      )}
    </>
  )
}

export default AverageAvailabilityPageOne
