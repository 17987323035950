import axios from 'axios'
import { sendLoginEvent, sendMessageEvent } from '../events'

const baseURL = process.env.REACT_APP_METRICS_API

const axiosClient = axios.create({
  baseURL,
})

const whiteList = ['/metrics-management/me']

axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.code === 'ERR_BAD_REQUEST' &&
      error.response &&
      error.response.status === 401 &&
      !whiteList.includes(error.config.url)
    ) {
      sendLoginEvent({ login: false })

      if (error.response.data.message) {
        sendMessageEvent({ type: 'error', text: error.response.data.message })
      }
    }
    return Promise.reject(error)
  },
)

export default axiosClient
