import { createContext, ReactNode, useState } from 'react'
import { ILoading, ILoadingEvent, IUser } from '../domain'

type UserProviderProps = {
  children: ReactNode
}

export interface IUserContext {
  user: IUser
  loading: ILoading
  updateUser: (user: IUser) => void
  updateLoading: (loading: ILoadingEvent) => void
  chartLabel: string
  updateChartLabel: (chartTitle: string) => void
  chartTitle: string
  updateChartTitle: (chartTitle: string) => void
}

export const UserContext = createContext({} as IUserContext)

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<IUser>({} as IUser)
  const [loading, setLoading] = useState<ILoading>({
    user: true,
    clientSummary: true,
    consentChart: true,
    consentFunnel: true,
    consentServerFunnel: true,
    endpointChart: true,
    receiverChart: true,
    statusChart: true,
    summary: true,
    transmitterChart: true,
    callsGraph: true,
    reasonCallsGraph: true,
    avarageCallsGraph: true,
    statusCallsGraph: true,
    availabilityPortalGraph: true,
    availabilityParticipantsGraph: true,
    orgsDirectoryGraph: true,
    parentTransmitters: true,
    parentReceivers: true,
    transmitters: true,
    receivers: true,
    stages: true,
    endpoints: true,
    statuses: true,
    channels: true,
  })

  const [chartLabel, setChartLabel] = useState<string>('Métricas Agregadas')

  const [chartTitle, setChartTitle] = useState<string>(
    'API Admin (Métricas) e API Comuns (Discovery)',
  )

  const updateChartLabel = (title: string) => {
    setChartLabel(title)
  }

  const updateChartTitle = (title: string) => {
    setChartTitle(title)
  }

  const updateUser = (user: IUser) => {
    setUser(user)
  }

  const updateLoading = (loading: ILoadingEvent) => {
    if (loading.name === 'discoveryGraphs') {
      setLoading((prevState) => ({
        ...prevState,
        callsGraph: true,
        reasonCallsGraph: true,
        avarageCallsGraph: true,
        statusCallsGraph: true,
        availabilityPortalGraph: true,
        orgsDirectoryGraph: true,
      }))
    }

    if (loading.name === 'summaries') {
      setLoading((prevState) => ({
        ...prevState,
        clientSummary: true,
        summary: true,
      }))
    }

    if (loading.name === 'privateCharts') {
      setLoading((prevState) => ({
        ...prevState,
        consentChart: true,
        consentFunnel: true,
        consentServerFunnel: true,
        endpointChart: true,
        receiverChart: true,
        statusChart: true,
        transmitterChart: true,
      }))
    }

    if (loading.name === 'publicCharts') {
      setLoading((prevState) => ({
        ...prevState,
        statusChart: true,
        availabilityParticipantsGraph: true,
      }))
    }

    if (loading.name === 'data') {
      setLoading((prevState) => ({
        ...prevState,
        clientSummary: true,
        summary: true,
        statusChart: true,
        availabilityParticipantsGraph: true,
        consentChart: true,
        consentFunnel: true,
        consentServerFunnel: true,
        endpointChart: true,
        receiverChart: true,
        transmitterChart: true,
        callsGraph: true,
        reasonCallsGraph: true,
        avarageCallsGraph: true,
        statusCallsGraph: true,
        availabilityPortalGraph: true,
        orgsDirectoryGraph: true,
      }))
    }

    if (loading.name === 'filters') {
      setLoading((prevState) => ({
        ...prevState,
        parentTransmitters: true,
        parentReceivers: true,
        transmitters: true,
        receivers: true,
        stages: true,
        endpoints: true,
        statuses: true,
        channels: true,
      }))
    }

    if (loading.name === 'publicFilters') {
      setLoading((prevState) => ({
        ...prevState,
        stages: true,
        endpoints: true,
        statuses: true,
      }))
    }

    if (loading.name === 'privateFilters') {
      setLoading((prevState) => ({
        ...prevState,
        parentTransmitters: true,
        parentReceivers: true,
        transmitters: true,
        receivers: true,
        channels: true,
      }))
    }

    if (
      loading.name !== 'discoveryGraphs' &&
      loading.name !== 'privateCharts' &&
      loading.name !== 'publicCharts' &&
      loading.name !== 'filters' &&
      loading.name !== 'publicFilters' &&
      loading.name !== 'privateFilters' &&
      loading.name !== 'summaries' &&
      loading.name !== 'data'
    ) {
      setLoading((prevState) => ({
        ...prevState,
        [loading.name]: loading.loading,
      }))
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        updateUser,
        updateLoading,
        chartLabel,
        updateChartLabel,
        chartTitle,
        updateChartTitle,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
