import { Box, CardContent, IconButton, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Cell,
  Tooltip,
} from 'recharts'
import {
  IDataReceiverGraph,
  IReceiverCSV,
  IReceiverGraph,
} from '../../../domain'
import {
  CardBox,
  CardBoxTitle,
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  TooltipInfo,
  IconCsvDownloader,
  TooltipGraph,
} from '../../../components'
import { formatNumberToScale, formatToLocaleString } from '../../../utils'
import './index.css'

interface DynamicReceiverRequestsGraphProps {
  dataGraph: IReceiverGraph
  isLoading: boolean
  isError?: boolean
}

const headerCSV = [
  { label: 'Receptora', key: 'client' },
  { label: 'Requisições', key: 'total' },
  { label: 'Sucesso', key: 'success' },
  { label: 'Erro', key: 'error' },
]

export const DynamicReceiverRequestsGraph = ({
  dataGraph,
  isLoading,
  isError,
}: DynamicReceiverRequestsGraphProps) => {
  const [dataCSV, setDataCSV] = useState<IReceiverCSV[]>([])

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>{payload[0].payload.name}</Typography>
        <Typography>
          Sucesso: {formatToLocaleString(payload[0].payload.success)}
        </Typography>
        <Typography>
          Erro: {formatToLocaleString(payload[0].payload.error)}
        </Typography>
        <Typography>
          Total: {formatToLocaleString(payload[0].payload.total)}
        </Typography>
      </TooltipGraph>
    )
  }

  const handleLabel = (props: any) => {
    return (
      <text
        x={12}
        y={props.y}
        dy={18}
        fill={props.stroke}
        fontSize={16}
        color="#3C3C3C"
        textAnchor="start"
      >
        {props.name}
      </text>
    )
  }

  const handleDataDownload = (data: IReceiverGraph) => {
    if (!data.data) {
      setDataCSV([])
      return
    }

    const tmp: IReceiverCSV[] = data.data.map((item: IDataReceiverGraph) => ({
      client: item.name,
      total: formatToLocaleString(item.total),
      success: formatToLocaleString(item.success),
      error: formatToLocaleString(item.error),
    }))

    setDataCSV(tmp)
  }

  useEffect(() => {
    handleDataDownload(dataGraph)
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Quantidade de requisições reportadas por sociedade participante Recepção (Client)"
        action={
          <>
            {!isLoading &&
            !isError &&
            dataGraph.data &&
            dataGraph.data.length > 0 &&
            dataCSV.length > 0 ? (
              <>
                <TooltipInfo
                  title="Exibe a quantidade de requisições por marca receptora participante do Open Insurance Brasil"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="info">
                    <InfoOutlined color="info" sx={{ opacity: 0.5 }} />
                  </IconButton>
                </TooltipInfo>
                <TooltipInfo
                  title="Fazer download do arquivo CSV"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="download">
                    <IconCsvDownloader
                      filename="requisicoes-por-seguradora-receptora"
                      headers={headerCSV}
                      data={dataCSV}
                    />
                  </IconButton>
                </TooltipInfo>
              </>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataGraph || !dataGraph.data || dataGraph.data.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box component="section">
            <Box
              component="section"
              sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                width: '100%',
                height: { xs: 357, md: 257 },
                marginBottom: '-14px',
                overflow: 'hidden scroll',
              }}
            >
              <ResponsiveContainer minHeight={dataGraph.data.length * 35}>
                <BarChart
                  data={dataGraph.data}
                  margin={{
                    left: -55,
                    right: 6,
                    top: 0,
                    bottom: 6,
                  }}
                  layout="vertical"
                  barGap={10}
                >
                  <Tooltip content={<CustomTooltip />} cursor={false} />
                  <CartesianGrid vertical={false} stroke="#f5f5f5" />
                  <XAxis tickMargin={12} type="number" scale="sqrt" hide />
                  <YAxis dataKey="name" type="category" tick={false} />
                  <Bar
                    dataKey="success"
                    stackId="total"
                    barSize={24}
                    isAnimationActive={false}
                  >
                    {dataGraph.data.map((entry, index) => {
                      return <Cell key={`cell-${index}`} fill={'#639B48'} />
                    })}
                  </Bar>
                  <Bar
                    dataKey="error"
                    stackId="total"
                    label={handleLabel}
                    barSize={24}
                    isAnimationActive={false}
                  >
                    {dataGraph.data.map((entry, index) => {
                      return <Cell key={`cell-${index}`} fill={'#E32424'} />
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Box
              component="section"
              sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                width: '100%',
                height: '37px',
                paddingRight: '14px',
                overflow: 'hidden hidden',
              }}
            >
              <ResponsiveContainer>
                <BarChart
                  data={dataGraph.data}
                  margin={{
                    left: -55,
                    right: 6,
                    top: 0,
                    bottom: 6,
                  }}
                  layout="vertical"
                  barGap={0}
                >
                  <CartesianGrid vertical={false} stroke="#f5f5f5" />
                  <XAxis
                    tickMargin={12}
                    type="number"
                    scale="sqrt"
                    tickFormatter={(value) =>
                      `${formatNumberToScale(value, 1, 1000)}`
                    }
                  />
                  <YAxis dataKey="name" type="category" tick={false} />
                  <Bar
                    dataKey="success"
                    stackId="total"
                    barSize={0}
                    isAnimationActive={false}
                  />
                  <Bar
                    dataKey="error"
                    stackId="total"
                    barSize={0}
                    isAnimationActive={false}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
