import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)`
  border: 2px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 20px;
  margin: auto;
  padding: 1rem;
  width: 90%;

  & > header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  & > header > a {
    @media (max-width: 425px) {
      margin-left: auto;
      margin-top: 1rem;
    }
  }

  & > footer {
    width: 100%;
    text-align: center;
  }
`;

export const GraphTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  font-weight: bold;
  text-align: left;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const GraphSubtitle = styled(Box)`
  display: block;
  margin: 0 0 1rem 0;
  width: 100%;
  text-align: left;

  @media (max-width: 425px) {
    text-align: center;
    margin: 0;
  }
`;

export const GraphContainer = styled(Box)`
  display: flex;
  align-items: start;
  gap: 1rem;

  @media (max-width: 1070px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const OrderSelectContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.light};
  border-radius: 5px;
  padding: 0.3rem;
  text-align: center;
  min-width: 100px;
  max-width: 400px;

  display: flex;
  align-items: center;
  gap: 1rem;

  & > div {
    flex: 1;
  }

  @media (max-width: 320px) {
    flex-direction: column;
    align-items: stretch;
  }
`;
