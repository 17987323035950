import {
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  AverageResponseTimePageFive,
  AverageResponseTimePageFour,
  AverageResponseTimePageOne,
  AverageResponseTimePageSix,
  AverageResponseTimePageThree,
  AverageResponseTimePageTwo,
} from './graphs'
import {
  graphListHelper,
  makeOptions,
  orgsOrderDefault,
  percentageDataToOrderBy,
  percentageOrderDefault,
} from './helpers'
import { GraphContainer } from './styles'
import { AverageResponseTimesProps } from './types'
import {
  CardBox,
  CardBoxSubTitle,
  CardBoxTitle,
  IconCsvDownloader,
  SingleAutocomplete,
} from '../../../components'
import {
  getMirrorReport,
  MirrorReportByOrgProps,
  SubmitParams,
} from '../../../services'
import { RangeOptions, SelectDefaultValue } from '../../../domain'
import { parseNumberToFixedTwo } from '../../../utils'

interface pageOneDataProps extends RangeOptions {}

interface pageTwoDataProps extends MirrorReportByOrgProps {}

interface pageThreeDataProps extends RangeOptions {}

interface pageFourDataProps extends MirrorReportByOrgProps {}

interface pageFiveDataProps extends RangeOptions {}

interface pageSixDataProps extends MirrorReportByOrgProps {}

export const AggregateAverageResponseTimeGraph = ({
  filter,
}: AverageResponseTimesProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [currentKey, setCurrentKey] = useState(0)
  const [isError, setIsError] = useState<Boolean>(false)

  const [pageOneData, setPageOneData] = useState<pageOneDataProps[]>(
    percentageDataToOrderBy,
  )
  const [pageTwoData, setPageTwoData] = useState<pageTwoDataProps[]>()
  const [pageThreeData, setPageThreeData] = useState<pageThreeDataProps[]>()
  const [pageFourData, setPageFourData] = useState<pageFourDataProps[]>()
  const [pageFiveData, setPageFiveData] = useState<pageFiveDataProps[]>()
  const [pageSixData, setPageSixData] = useState<pageSixDataProps[]>()

  const [averageTotalResponse, setAverageTotalResponse] = useState(0)
  const [averageTotalHighPriority, setAverageTotalHighPriority] = useState(0)
  const [averageTotalMediumPriority, setAverageTotalMediumPriority] =
    useState(0)

  const [firstChartOrder, setFirstChartOrder] = useState<SelectDefaultValue>(
    percentageOrderDefault(),
  )
  const [secondChartOrder, setSecondChartOrder] = useState<SelectDefaultValue>(
    orgsOrderDefault(),
  )
  const [thirdChartOrder, setThirdChartOrder] = useState<SelectDefaultValue>(
    percentageOrderDefault(),
  )
  const [fourthChartOrder, setFourthChartOrder] = useState<SelectDefaultValue>(
    orgsOrderDefault(),
  )
  const [fifthChartOrder, setFifthChartOrder] = useState<SelectDefaultValue>(
    percentageOrderDefault(),
  )
  const [sixthChartOrder, setSixthChartOrder] = useState<SelectDefaultValue>(
    orgsOrderDefault(),
  )

  const [loading, setLoading] = useState(false)
  const [selectOptions, setSelectOptions] = useState<SelectDefaultValue[]>([])

  const handleTabs = (e: ChangeEvent<{}>, newKey: number): void => {
    setCurrentKey(newKey)
  }

  const getDataToExportCSV = (): any => {
    if (pageOneData && pageOneData.length > 0 && currentKey === 0) {
      return pageOneData.map(({ range, value }: any): any => ({
        intervalo: range,
        valor: value,
      }))
    }
    if (pageTwoData && pageTwoData.length > 0 && currentKey === 1) {
      return pageTwoData.map(({ serverName, response }: any): any => ({
        instituicao: serverName,
        valor: response,
      }))
    }
    if (pageThreeData && pageThreeData.length > 0 && currentKey === 2) {
      return pageThreeData.map(({ range, value }: any): any => ({
        intervalo: range,
        valor: value,
      }))
    }
    if (pageFourData && pageFourData.length > 0 && currentKey === 3) {
      return pageFourData.map(({ serverName, response }: any): any => ({
        instituicao: serverName,
        valor: response,
      }))
    }
    if (pageFiveData && pageFiveData.length > 0 && currentKey === 4) {
      return pageFiveData.map(({ range, value }: any): any => ({
        intervalo: range,
        valor: value,
      }))
    }
    if (pageSixData && pageSixData.length > 0 && currentKey === 5) {
      return pageSixData.map(({ serverName, response }: any): any => ({
        instituicao: serverName,
        valor: response,
      }))
    }
    return []
  }

  const makeChartData: { [index: number]: () => void } = {
    0: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'averageResponse',
          },
          (data) => {
            if (data) {
              setPageOneData(
                percentageDataToOrderBy.map((item) => ({
                  ...item,
                  value: data[item.key],
                })),
              )

              setAverageTotalResponse(data.averageTotalResponse)

              if (
                parseFloat(parseNumberToFixedTwo(data.averageTotalResponse)) ===
                0
              ) {
                setIsError(true)
              } else {
                setIsError(false)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
    1: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'averageResponseByServer',
          },
          (data) => {
            if (data) {
              setPageTwoData(data)

              if (data.filter((item: any) => item.response > 0).length > 0) {
                setIsError(false)
              } else {
                setIsError(true)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
    2: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'highPriority',
          },
          (data) => {
            if (!data) {
              setPageThreeData(
                percentageDataToOrderBy.map((item) => ({
                  ...item,
                  value: 0,
                })),
              )
              setAverageTotalHighPriority(0)
              setIsError(true)
            } else {
              setPageThreeData(
                percentageDataToOrderBy.map((item) => ({
                  ...item,
                  value: data[item.key],
                })),
              )
              setAverageTotalHighPriority(data.averageTotalHighPriority)

              if (
                data.averageTotalHighPriority === 0 ||
                data.averageTotalHighPriority === undefined
              ) {
                setIsError(true)
              } else {
                setIsError(false)
              }
            }
          },
        )
      }
    },
    3: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'highPriorityByServer',
          },
          (data) => {
            if (data) {
              setPageFourData(data)

              if (data.filter((item: any) => item.response > 0).length > 0) {
                setIsError(false)
              } else {
                setIsError(true)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
    4: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'mediumPriority',
          },
          (data) => {
            if (!data) {
              setPageFiveData(
                percentageDataToOrderBy.map((item) => ({
                  ...item,
                  value: 0,
                })),
              )
              setAverageTotalMediumPriority(0)
              setIsError(true)
            } else {
              setPageFiveData(
                percentageDataToOrderBy.map((item) => ({
                  ...item,
                  value: data[item.key],
                })),
              )
              setAverageTotalMediumPriority(data.averageTotalMediumPriority)

              if (
                data.averageTotalMediumPriority === 0 ||
                data.averageTotalMediumPriority === undefined
              ) {
                setIsError(true)
              } else {
                setIsError(false)
              }
            }
          },
        )
      }
    },
    5: () => {
      if (filter && filter.interval.length > 0) {
        const { interval, parentTransmitters, transmitters } = filter

        getChartData(
          {
            firstDate: interval[0],
            lastDate: interval[1],
            parentTransmitters,
            transmitters,
            type: 'mediumPriorityByServer',
          },
          (data) => {
            if (data) {
              setPageSixData(data)

              if (data.filter((item: any) => item.response > 0).length > 0) {
                setIsError(false)
              } else {
                setIsError(true)
              }
            } else {
              setIsError(true)
            }
          },
        )
      }
    },
  }

  const getChartData = useCallback(
    (dateData: SubmitParams, setChart: (data: any) => void): void => {
      setLoading(true)
      getMirrorReport({ ...dateData })
        .then(
          (data: any) => {
            setChart(data)
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        )
        .catch(() => {
          setLoading(false)
        })
    },
    [],
  )

  useEffect(() => {
    makeChartData[currentKey]()
    setSelectOptions(makeOptions(currentKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKey, filter])

  const headerCSV: any = {
    0: [
      {
        label: 'Intervalo',
        key: 'intervalo',
      },
      {
        label: 'Quantidade de chamadas',
        key: 'valor',
      },
    ],
    1: [
      {
        label: 'Sociedade',
        key: 'instituicao',
      },
      {
        label: 'Quantidade de chamadas',
        key: 'valor',
      },
    ],
    2: [
      {
        label: 'Intervalo',
        key: 'intervalo',
      },
      {
        label: 'Quantidade de chamadas',
        key: 'valor',
      },
    ],
    3: [
      {
        label: 'Sociedade',
        key: 'instituicao',
      },
      {
        label: 'Quantidade de chamadas',
        key: 'valor',
      },
    ],
    4: [
      {
        label: 'Intervalo',
        key: 'intervalo',
      },
      {
        label: 'Quantidade de chamadas',
        key: 'valor',
      },
    ],
    5: [
      {
        label: 'Sociedade',
        key: 'instituicao',
      },
      {
        label: 'Quantidade de chamadas',
        key: 'valor',
      },
    ],
  }

  const handleSelectChange = (value: SelectDefaultValue) => {
    switch (currentKey) {
      case 0:
        setFirstChartOrder(value)
        break
      case 1:
        setSecondChartOrder(value)
        break
      case 2:
        setThirdChartOrder(value)
        break
      case 3:
        setFourthChartOrder(value)
        break
      case 4:
        setFifthChartOrder(value)
        break
      case 5:
        setSixthChartOrder(value)
        break
    }
  }

  return (
    <Grid item xs={12}>
      <CardBox sx={{ minHeight: 500 }}>
        <CardBoxTitle
          title="Tempo Médio de Resposta das APIs"
          action={
            <>
              {!isError && !loading ? (
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="tempo-medio-resposta-api"
                    headers={headerCSV[currentKey]}
                    data={getDataToExportCSV()}
                  />
                </IconButton>
              ) : (
                ''
              )}
            </>
          }
        />
        <CardBoxSubTitle
          title="Demonstração do número de chamadas bem-sucedidas das APIs de
          sociedades participantes"
        />

        <Grid
          container
          sx={{
            padding: 2,
            paddingTop: 0,
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <SingleAutocomplete
              id="orderBy"
              label="Classifique por"
              options={selectOptions}
              value={
                currentKey === 0
                  ? firstChartOrder
                  : currentKey === 1
                  ? secondChartOrder
                  : currentKey === 2
                  ? thirdChartOrder
                  : currentKey === 3
                  ? fourthChartOrder
                  : currentKey === 4
                  ? fifthChartOrder
                  : sixthChartOrder
              }
              loading={loading}
              onSelect={handleSelectChange}
            />
          </Grid>
        </Grid>

        <CardContent sx={{ position: 'relative' }}>
          <GraphContainer>
            <Tabs
              orientation="vertical"
              variant="fullWidth"
              value={currentKey}
              onChange={handleTabs}
              TabIndicatorProps={{ style: { display: 'none' } }}
              sx={{ width: 300, minWidth: 300 }}
            >
              {graphListHelper.map((item) => (
                <Tab
                  key={`${item.title}-${item.key}`}
                  label={item.title}
                  wrapped
                  value={item.key}
                  sx={{
                    fontSize: 12,
                    '&.Mui-selected': {
                      fontWeight: 'bold',
                    },
                  }}
                />
              ))}
            </Tabs>
            {currentKey === 0 && (
              <AverageResponseTimePageOne
                data={pageOneData}
                chartOrder={firstChartOrder}
                averageTotalResponse={averageTotalResponse}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 1 && (
              <AverageResponseTimePageTwo
                data={pageTwoData}
                chartOrder={secondChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 2 && (
              <AverageResponseTimePageThree
                data={pageThreeData}
                chartOrder={thirdChartOrder}
                averageTotalHighPriority={averageTotalHighPriority}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 3 && (
              <AverageResponseTimePageFour
                data={pageFourData}
                chartOrder={fourthChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 4 && (
              <AverageResponseTimePageFive
                data={pageFiveData}
                chartOrder={fifthChartOrder}
                averageTotalMediumPriority={averageTotalMediumPriority}
                mobile={isMobile}
                loading={loading}
              />
            )}
            {currentKey === 5 && (
              <AverageResponseTimePageSix
                data={pageSixData}
                chartOrder={sixthChartOrder}
                mobile={isMobile}
                loading={loading}
              />
            )}
          </GraphContainer>
        </CardContent>

        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {currentKey === 0 || currentKey === 2 || currentKey === 4 ? (
            <Typography fontSize={12}>
              Quantidade de Sociedades participantes
            </Typography>
          ) : (
            <Typography fontSize={12}>
              Apenas sociedades que reportaram Tempo Médio de Resposta das APIs
              no período selecionado são exibidas.
            </Typography>
          )}
        </CardActions>
      </CardBox>
    </Grid>
  )
}
