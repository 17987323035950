import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const TooltipInfo = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 10px 6px #00000029',
    background: '#6E6E6E',
    letterSpacing: '0px',
    borderRadius: '1px',
    color: '#FFFFFF',
    maxWidth: 200,
    textAlign: 'center',
    fontSize: '1rem',
    padding: 10,
  },
})

export const TooltipInfoWide = styled(TooltipInfo)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: '0.875rem',
  },
})
