import { useContext, useEffect, useRef, useState } from 'react'
import {
  Grid,
  IconButton,
  Theme,
  Typography,
  BoxProps,
  Container,
  useMediaQuery,
} from '@mui/material'
import { Tune } from '@mui/icons-material'
import {
  BoxAccordion,
  BoxAccordionDetails,
  BoxAccordionSummary,
  BoxFilter,
  BoxTitle,
} from './style'
import { UserContext } from '../../../context/user'
import { theme } from '../../../core/themes/default'

interface IAccordionProps extends BoxProps {
  children?: JSX.Element
  hasChanged?: boolean
  onChangeHeight?: (value: number) => void
}

export const ToolFilterAccordion = ({
  children,
  hasChanged = false,
  onChangeHeight,
  ...props
}: IAccordionProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { chartLabel, chartTitle } = useContext(UserContext)
  const [accordionOpened, setAccordionOpened] = useState(true)
  const [manualAccordion, setManualAccordion] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  const ref = useRef<HTMLElement>()
  const [, setHeight] = useState(0)

  const handleResizeAccordion = () => {
    setTimeout(() => {
      if (ref.current && onChangeHeight) {
        setHeight(ref.current?.clientHeight as number)
        onChangeHeight(ref.current.clientHeight)
      }
    }, 300)
  }

  const handleAccordion = () => {
    setAccordionOpened((prev) => !prev)
    setManualAccordion(true)

    if (scrollTop < 200 && !isMobile) {
      handleResizeAccordion()
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY)
    }

    if (scrollTop === 0 && !isMobile) {
      setAccordionOpened(true)
      setManualAccordion(false)
      handleResizeAccordion()
    }

    if (scrollTop > 200 && !isMobile && !manualAccordion) {
      setAccordionOpened(false)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop])

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current?.clientHeight as number)
      if (onChangeHeight) {
        onChangeHeight(ref.current.clientHeight)
      }
    }

    return () => {}
  }, [onChangeHeight, ref])

  useEffect(() => {
    handleResizeAccordion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged])

  return (
    <BoxFilter ref={ref} {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BoxAccordion
            disableGutters={true}
            expanded={accordionOpened}
            onChange={handleAccordion}
            sx={{
              boxShadow: {
                xs: 'none',
                sm: 'none',
                md: scrollTop > 240 ? '4px 5px 3px #00000029' : 'inherit',
              },
            }}
          >
            <Container maxWidth="xl" sx={{ padding: 0, paddingInline: 2 }}>
              <BoxAccordionSummary
                sx={{ paddingY: 1, paddingX: 0 }}
                expandIcon={
                  children && (
                    <IconButton aria-label="info">
                      <Tune />
                    </IconButton>
                  )
                }
              >
                <Typography
                  color="primary"
                  sx={{
                    fontSize: (theme: Theme) => theme.typography.pxToRem(20),
                    lineHeight: (theme: Theme) => theme.typography.pxToRem(26),
                    letterSpacing: 0,
                  }}
                >
                  <strong>{chartLabel}: </strong>
                  {chartTitle}
                </Typography>
                {children && (
                  <BoxTitle>
                    {!accordionOpened
                      ? 'VER OS FILTROS'
                      : 'RECOLHER OS FILTROS'}
                  </BoxTitle>
                )}
              </BoxAccordionSummary>
            </Container>
            {children && <BoxAccordionDetails>{children}</BoxAccordionDetails>}
          </BoxAccordion>
        </Grid>
      </Grid>
    </BoxFilter>
  )
}
