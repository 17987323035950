import { useEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  useLocation,
  Navigate,
  Route,
} from 'react-router-dom'
import { GridLayout } from './layouts'
import { Aggregate, Dynamic, Infrastructure } from '../pages'

const NavigationHelper = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [pathname])

  return null
}

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <NavigationHelper />
      <Routes>
        <Route path="/" element={<GridLayout />}>
          <Route path="" element={<Navigate to="/aggregate" replace />} />
          <Route path="aggregate" element={<Aggregate />} />
          <Route path="metrics" element={<Dynamic />} />
          <Route path="infrastructure" element={<Infrastructure />} />
          <Route path="*" element={<Navigate to="/aggregate" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
