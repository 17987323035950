import { SelectDefaultValue } from '../../../../domain'
import { errorsPercentageOrderOptions, errosOrgsOrderOptions } from './errorGraphOrder'
import { orgsOrderOptions, percentageOrderOptions } from './graphOrder'

export const makeOptions = (key: number): SelectDefaultValue[] => {
  if (key === 0) {
    return percentageOrderOptions.map(option => ({
      label: option.title,
      value: option.order
    }))
  }

  if (key === 1) {
    return orgsOrderOptions.map(option => ({
      label: option.title,
      value: option.order
    }))
  }

  if (key === 2) {
    return errorsPercentageOrderOptions.map((option: { title: any; order: any }) => ({
      label: option.title,
      value: option.order
    }))
  }

  return errosOrgsOrderOptions.map((option: { title: any; order: any }) => ({
    label: option.title,
    value: option.order
  }))
}
