import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { ViewDataLoading } from '../../../components'

interface TableProps {
  header: Array<string>
  data: any
  isLoading: boolean
}

export const TableResponsive = ({ header, data, isLoading }: TableProps) => {
  return (
    <>
      {isLoading ? (
        <ViewDataLoading />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ height: '100%', borderRadius: '0' }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ background: '#fafafa' }}
          >
            <TableHead>
              <TableRow>
                {header.map((column: string) => (
                  <TableCell
                    key={column}
                    align="left"
                    sx={{
                      fontWeight: 'bold',
                      paddingTop: '6px',
                      paddingBottom: '6px',
                      background: '#d4d4d4',
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row: string[], rowIndex: number) => (
                  <TableRow
                    key={rowIndex}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {row.map((column: string, columnIndex) => (
                      <TableCell
                        key={`${rowIndex}-${columnIndex}`}
                        align={'left'}
                        sx={{ maxWidth: '170px', overflowWrap: 'break-word' }}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow
                  key={'no-item'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell key={'no-item-1'} align={'left'}>
                    Nenhum dado a ser exibido!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
