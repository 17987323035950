import { Box, CardContent, IconButton, Typography } from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useEffect, useState } from 'react'
import { ICallsServiceDeskGraph } from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
  legendStatus,
} from '../../../components'
import { formatToLocaleString } from '../../../utils'

interface InfrastructureReasonCallsGraphProps {
  dataGraph: ICallsServiceDeskGraph
  isLoading?: boolean
  isError?: boolean
}

interface IInfrastructureReasonCallsGraphShow {
  key: string
  name: string
  total: number
  color: string
}

const headerCSV = [
  { label: 'Motivo', key: 'name' },
  { label: 'Quantidade', key: 'total' },
]

const series = [
  {
    key: 'access',
    problem_type: 'Solicitação de acesso',
    name: 'Acesso',
    color: '#2a732a',
  },
  {
    key: 'incident',
    problem_type: 'Incidentes',
    name: 'Incidente',
    color: '#808080',
  },
  {
    key: 'improvement',
    problem_type: 'Solicitação de Melhorias',
    name: 'Melhoria',
    color: '#0ca789',
  },
  {
    key: 'information',
    problem_type: 'Solicitação de Informações',
    name: 'Informação',
    color: '#2d9bf0',
  },
  {
    key: 'certification',
    problem_type: 'Solicitação de certificados',
    name: 'Certificado',
    color: '#8e44ad',
  },
  {
    key: 'canceled',
    problem_type: 'Cancelado',
    name: 'Cancelado',
    color: '#ea2027',
  },
]

export const InfrastructureReasonCallsGraph = ({
  dataGraph,
  isLoading,
  isError,
}: InfrastructureReasonCallsGraphProps) => {
  const [dataShow, setDataShow] = useState<
    IInfrastructureReasonCallsGraphShow[]
  >([])
  const [dataCSV, setDataCSV] = useState<IInfrastructureReasonCallsGraphShow[]>(
    [],
  )

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>Motivo: {payload[0].payload.name}</Typography>
        <Typography>Quantidade: {payload[0].payload.total}</Typography>
      </TooltipGraph>
    )
  }

  const handleDataShow = (data: ICallsServiceDeskGraph) => {
    if (!data || !data.undefined) {
      setDataShow([])
      return
    }

    const tempData: IInfrastructureReasonCallsGraphShow[] = []

    series.forEach((serie) => {
      const element = data.undefined.find(
        (el) => el.problem_type === serie.problem_type,
      )

      tempData.push({
        key: serie.key,
        name: serie.name,
        total: element ? parseInt(element.total as string) : 0,
        color: serie.color,
      })
    })

    setDataShow(tempData)
  }

  const handleDataDownload = (data: ICallsServiceDeskGraph) => {
    if (!data || !data.undefined) {
      setDataCSV([])
      return
    }

    const tempData: IInfrastructureReasonCallsGraphShow[] = []

    series.forEach((serie) => {
      const element = data.undefined.find(
        (el) => el.problem_type === serie.problem_type,
      )

      tempData.push({
        key: serie.key,
        name: serie.name,
        total: element ? parseInt(element.total as string) : 0,
        color: serie.color,
      })
    })

    setDataCSV(tempData)
  }

  useEffect(() => {
    handleDataShow(dataGraph)
    handleDataDownload(dataGraph)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <CardBox>
      <CardBoxTitle
        title="Motivos dos Chamados Abertos no Service Desk no período filtrado"
        action={
          <>
            {!isLoading && !isError && dataCSV.length > 0 ? (
              <TooltipInfo
                title="Fazer download do arquivo CSV"
                arrow
                placement="left-start"
                enterTouchDelay={0}
              >
                <IconButton aria-label="download">
                  <IconCsvDownloader
                    filename="motivos-chamados-service-desk"
                    headers={headerCSV}
                    data={dataCSV}
                  />
                </IconButton>
              </TooltipInfo>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow || dataShow.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              height: { xs: 370, md: 350 },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={dataShow}
                margin={{
                  left: -55,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
                layout="vertical"
                barGap={0}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#f5f5f5" />
                <XAxis
                  tickMargin={12}
                  type="number"
                  padding={{ left: 0, right: 50 }}
                  scale="sqrt"
                  tickFormatter={(value) =>
                    formatToLocaleString(value, 1).replace(',0', '')
                  }
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  tickLine={false}
                  width={145}
                />
                <Bar dataKey="total" isAnimationActive={false}>
                  {dataShow.map((entry, index) => {
                    return <Cell key={`cell-${index}`} fill={entry.color} />
                  })}
                  <LabelList
                    dataKey="total"
                    fill="#000000de"
                    position="right"
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            <Box sx={{ mt: 4 }}>
              {series.map((data) => {
                if (!legendStatus[data.key]) return null

                return (
                  <Box key={legendStatus[data.key]} lineHeight={1}>
                    <Typography
                      fontSize={12}
                      fontWeight="bold"
                      component="span"
                    >
                      {data.name}:{' '}
                    </Typography>
                    <Typography fontSize={12} component="span">
                      {legendStatus[data.key]}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
